import React, { useState, useCallback, useEffect } from "react";



// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, Grid, createStyles, Theme, DialogProps, Backdrop, CircularProgress, Button, FormControl, InputLabel, Select, Typography, MenuItem, Divider, Chip, DialogActions, IconButton } from "@material-ui/core";
import { useToast } from "../../hooks/toast";
import { useAuth } from '../../hooks/auth';
import api from "../../services/api";
import { IBranchModel, Tenant } from "../../models/Tenant/TenantDTO";
import { IPlanItem } from "../../models/Plans/IPlanDTO";
import NumberFormat from "react-number-format";
import { CheckCircleOutline, Close, AddShoppingCart } from "@material-ui/icons";
import ReactHtmlParser from 'react-html-parser';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '12px',
    },
    divCenter: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    GridList: {
      margin: '20px 0',
    },
    formControl: {
      margin: '48px 0',
      minWidth: 266,
    },
    buttonClick: {
      color: theme.palette.primary.main,
      fontSize: '1.2rem',
      padding: '0',
      textTransform: 'none',
    },
    valuePlan: {
      marginTop: '2px !important',
    },
    textObs: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
    },
    divider: {
      margin: '12px 0',
    },
    itemObs: {
      margin: theme.spacing(0.5),
    },
    chips: {
      margin: '10px 0 20px 0'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    }
  })

);

type PlanProps = DialogProps & {
  plan: IPlanItem | undefined;
  buyClick?: any;
  buyButton: boolean;
}


const DialogPlanDetails: React.FC<PlanProps> = ({ plan,buyButton, buyClick, ...rest }) => {
  const { onClose } = rest;
  const classes = useStyles();


  const handleClickPay = useCallback(() => {

    if (onClose) {
      onClose({}, 'backdropClick');
    }
    buyClick(plan);
  }, [plan, onClose])
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  }, [onClose])
  return (


    <Dialog maxWidth="sm" fullWidth {...rest}  >
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <Close />
      </IconButton>

      <DialogContent dividers>

        <div className={classes.root}>
          <Grid direction="column"
            justify="center"
            alignItems="center" >

            <Grid item xs={12} >
              {plan &&
                <div >

                  <Typography align="center" gutterBottom variant="h5" color="primary">{plan?.nomePlano}</Typography>
                  {plan.parcelas > 1 && !plan.dcc && (
                    <>
                      <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={(plan.valorTotal-plan.adesaoValor)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                      <Typography align="center">Em até {plan.parcelas}x</Typography>
                    </>
                  )}
                  {plan.parcelas == 1 && !plan.dcc && (
                    <>
                      <Typography align="center">à vista</Typography>
                      <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorTotal / plan.parcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                      <br />
                    </>
                  )}
                  {plan.dcc && (
                    <>
                      <Typography align="center">Mensalidade</Typography>
                      <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorDcc} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                      <Typography variant="body2" className={classes.textObs} align="center">Cobrado mensalmente no cartão de crédito</Typography>
                    </>
                  )}
                  {(plan.duracao || plan.bloqueio || plan.validadeCredito) &&
                    <>
                      <Divider variant="fullWidth" className={classes.divider} />
                      {plan.duracao && !plan.dcc &&
                        <Typography variant="subtitle1" >Duração: {plan.duracao}</Typography>
                      }
                      {plan.bloqueio && !plan.dcc &&
                        <Typography variant="subtitle1" >Bloqueio permitido: {plan.bloqueio}</Typography>
                      }
                      {plan.validadeCredito &&
                        <Typography variant="subtitle1" >Validade: {plan.validadeCredito}</Typography>
                      }
                    </>
                  }
                  <div className={classes.chips}>
                    {plan.caracteristicas?.map(item => (
                      <Chip color="primary" icon={<CheckCircleOutline />} className={classes.itemObs} key={item?.descricao} variant="outlined" label={item.descricao} />
                    ))}
                  </div>
                  <Typography variant="body2" >{ReactHtmlParser(plan.descricaoPlano)}</Typography>



                </div>
              }


            </Grid>
          </Grid>
        </div>
      </DialogContent>
      {buyButton &&
      <DialogActions style={{ justifyContent: "center" }}>
        <Button variant="contained" onClick={handleClickPay} color="primary" startIcon={<AddShoppingCart />}>
          Comprar agora
          </Button>
      </DialogActions>
}
    </Dialog>

  );
}



export default DialogPlanDetails;
