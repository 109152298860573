import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as jquery from "jquery";



Object.assign(global, {
    $: jquery,
    jQuery: jquery,
});

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

