import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { format, isToday, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import {
  Schedule, NextAppointment, Calendar, Section, Appointment, ContentModal
} from './styles';

import ReactHtmlParser from 'react-html-parser';
import { makeStyles, createStyles, Theme, Grid, CircularProgress, Paper, Typography, Button, Container, Box, Chip, withStyles, createMuiTheme } from '@material-ui/core';
import Content from '../../../components/Content';

import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/success.json';
import animationDataError from '../../../assets/animations/error.json';
import { Helmet } from 'react-helmet';
import InnerHTML from 'dangerously-set-html-content';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { IAppointmentDays, IAulaEscolhida, IAulaExperimental } from '../../../models/Appointment/IAppointmentDTO';
import { borderRadius } from 'polished';


const StyledChip = withStyles({

  label: {
    fontWeight: 500,
  },
})(Chip);

const useStyles = makeStyles((theme: Theme) =>



  createStyles({
    feedback: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
    },
    GridList: {
      margin: '28px 0 !important',
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",
    },
    Paper: {
      padding: '20px',
      marginBottom: '26px',
      marginTop: '32px',
    },
    textMessage: {
      textAlign: 'center',
    },
    textMessageSub: {
      textAlign: 'center',
      color: theme.palette.text.disabled,
      marginBottom: '20px',
    },
    textMessageCustom: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    titleHours: {
      margin: '18px 6px',

    },
    chipHour: {
      fontSize: '1rem',
    },
    listHours: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    dayPicker: {

      // backgroundColor: theme.palette.background.paper,
      // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      // borderRadius: '4px',
      borderRadius: '50%',

      '& .DayPicker-Day--available:not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.light,

        color: '#fff',
      },
      '& .DayPicker-Day--available:not(.DayPicker-Day--disabled):hover:not(.DayPicker-Day--outside):hover': {
        background: theme.palette.primary.main + ' !important',

      },
      '& .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.light + ' !important',

        color: theme.palette.primary.contrastText,
      },
      '& .DayPicker-Day--today.DayPicker-Day--disabled': {
        background: '#ccc !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--disabled': {
        background: '#f3f3f3'
      },
      '& .DayPicker-Day--available.DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.dark + ' !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--selected:hover:not(.DayPicker-Day--outside):hover': {
        background: theme.palette.primary.main + ' !important',
      },
    },

    ChipService: {
      margin: '10px 5px',
    },
    itemService: {
      flex: 1,
    },
    itemServiceName: {
      color: theme.palette.text.disabled,
    },
    itemServiceHour: {
      fontSize: '17px',
    },
    itemServiceButton: {
      textTransform: 'none',
    },
    containerFixo: {
      maxHeight: '400px',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        width: 12,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '8px',
        backgroundColor: "#e7e7e7",
        border: "1px solid #cacaca",
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, .3)',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        backgroundColor: "#363636"
      }
    },

    logoCenter: {
      maxHeight: '47px',
    },
    headerColor: {
      background: 'linear-gradient(to right, ' + theme.palette.primary.main + ' 0%, ' + theme.palette.primary.dark + ' 100%)',
    },
    headerColorBlank: {
      background: "#ffffff",
      color: theme.palette.text.primary,
    },
    divDescricao: {
      marginTop: '20px',
    }
  })
);


interface IServices {
  id: number;
  name: string;
}

interface IHoursService {
  DataInicio: string;
  DataFim: string;
  Servico: string;
  Id: number | undefined;
  Desabilitado: boolean;
}
type RouteParams = {
  id: string
}
type BookDetailProps = RouteComponentProps<RouteParams>;



const LandingPageCalendario: React.FC<BookDetailProps> = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loadingData, setLoadingData] = useState(false);
  const [lpData, setLpData] = useState<any>();
  const [enviadoForm, setEnviadoForm] = useState<boolean>(false);
  const [agradecimento, setAgradecimento] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [enviadoFormOk, setEnviadoFormOk] = useState<boolean>(false);
  const [isStopped, setIsStopped] = useState<boolean>(true);
  const [loadingServices, setLoadingServices] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedNewDate, setSelectedNewDate] = useState<Date>();
  const [hoursAvailability, setHoursAvailability] = useState<IHoursService[] | undefined>(undefined)
  const [serviceSelected, setServiceSelected] = useState<IAulaExperimental | undefined>();
  const [currentNewMonth, setCurrentNewMonth] = useState<Date>(new Date());
  const [services, setServices] = useState<IAulaExperimental[]>([]);
  const [appointment_available, setAppointment_available] = useState<IAppointmentDays[]>([]);
  const [appointment_days, setAppointment_days] = useState<IAppointmentDays[]>([]);
  const [selectedHour, setSelectedHour] = useState();
  const [idLp, setIdLp] = useState("");
  const { tenant, updateTenant } = useAuth();
  const { addToast } = useToast();
  const [theme, setTheme] = useState<Theme>(createMuiTheme());

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
  };
  const defaultOptionsError = {
    loop: false,
    autoplay: false,
    animationData: animationDataError,
  };

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
    console.log(format(month, 'dd/MM/yyyy'))
  }, []);
  const handleMonthNewChange = useCallback((month: Date) => {
    setCurrentNewMonth(month);
  }, []);
  const handleDateNewChange = useCallback(async (day: Date, modifier: DayModifiers) => {
    if (modifier.available && !modifier.disabled && serviceSelected) {
      setSelectedNewDate(day);
    }
  }, [serviceSelected])


  const disabledDays = useMemo(() => {
    const dates = appointment_days.filter(monthDay =>
      monthDay.available == false).map(monthDay => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();
        return new Date(year, month, monthDay.day);
      })
    return dates;
  }, [currentMonth, appointment_days]);

  useEffect(() => {
    const idLp = match.params.id;
    if (idLp && tenant) {
      setIdLp(idLp);
      setLoadingData(true);
      api.get('/landingpage', {
        params: {
          name: idLp,
          idt: tenant?.idTenant,
        }
      }
      ).then(retorno => {
        setIsStopped(false);
        setLoadingData(false);
        setLpData(retorno.data);
        tenant.idBranch = retorno.data.idUnidade;



        tenant.primaryColorTheme = retorno.data.primaryColorTheme;
        tenant.secundaryColorTheme = retorno.data.secondaryColorTheme;

        localStorage.setItem('@HubfitPortal:lp', JSON.stringify(retorno.data));
        updateTenant(tenant);
        console.log(retorno.data)



      }).catch(err => {

        addToast({
          type: "error",
          title: 'Erro',
          description: 'Não foi possível se conectar ao servidor',
        });
      })
    }
  }, [tenant?.idTenant]);



  useEffect(() => {
    if (window.location.href.indexOf("calendario") > -1) {
      const body = document.body;
      body.setAttribute("style", "background-color:transparent;padding: 20px;");
    }

    if (lpData) {
      setLoadingServices(true);
      try {
        api.get<IAulaExperimental[]>('/lpagendamentos', {
          params: {
            idPage: lpData.id,
            idTenant: tenant.idTenant,
          }
        }).then(response => {
          console.log(response)
          setLoadingServices(false);

          if (response.data.length > 0) {
            response.data[0].selected = true;
            setServiceSelected(response.data[0]);
          }
          setServices(response.data);

        }).catch(err => {
          console.log(err)

          addToast({
            type: "error",
            title: 'Erro',
            description: 'Erro na conexão.',
          });
          setLoadingServices(false);
          addToast({
            type: "error",
            title: 'Erro',
            description: 'Erro na conexão.',
          });
        })
      } catch (err) {
        console.log(err)
        setLoadingServices(false);
        addToast({
          type: "error",
          title: 'Erro',
          description: 'Erro na conexão.',
        });
      }
    }

  }, [lpData, tenant.idTenant]);

  useEffect(() => {
    try {
      if (serviceSelected) {
        if (selectedNewDate == undefined) {
          setSelectedNewDate(currentMonth);
        }
        api.get('/lpagendamentos/month-availability', {
          params: {
            year: currentMonth.getFullYear(),
            month: currentMonth.getMonth() + 1,
            idservice: serviceSelected.id,
            type: serviceSelected.type,
            idTenant: tenant.idTenant,
          }
        }).then(response => {
          console.log(response)
          setAppointment_available(response.data);
          setAppointment_days(response.data);
        }).catch(err => {
          console.log(err)

          addToast({
            type: "error",
            title: 'Erro',
            description: 'Erro na conexão.',
          });
          setLoadingServices(false);
          addToast({
            type: "error",
            title: 'Erro',
            description: 'Erro na conexão.',
          });
        })
      }
    } catch (err) {
      console.log(err)
      setLoadingServices(false);
      addToast({
        type: "error",
        title: 'Erro',
        description: 'Erro na conexão.',
      });
    }
  }, [currentMonth, serviceSelected, selectedNewDate])

  useEffect(() => {
    console.log(selectedNewDate)
    if (serviceSelected && selectedNewDate) {
      api.get("lpagendamentos/hour-availability", {
        params: {
          date: format(selectedNewDate, 'yyyy/MM/dd'),
          idservice: serviceSelected.id,
          type: serviceSelected.type,
          idTenant: tenant.idTenant,
        }
      }).then((response) => {
        setHoursAvailability(response.data);
      }).catch(err => {
        if (err.response.status == 401) {
          addToast({
            type: "error",
            title: 'Sessão encerrada',
            description: 'Sua sessão expirou',
          });
        } else {
          addToast({
            type: "error",
            title: 'Erro',
            description: 'Erro na conexão.',
          });
        }
      })

    }
  }, [serviceSelected, selectedNewDate]);

  const handleHourClick = useCallback((item) => {
    setSelectedHour(item);
    const aulaEscolhida: IAulaEscolhida = {
      idService: serviceSelected?.type == "service" ? serviceSelected?.id : item.Id,
      data: item.DataInicio,
      dataEnd: item.DataFim,
      type: serviceSelected?.type,
      service: item.Servico,
    };
    console.log(aulaEscolhida)


    localStorage.setItem('@HubfitPortal:itemAula', JSON.stringify(aulaEscolhida));
    if (window.location.href.indexOf("calendario") > -1) {
      console.log("possui Calendario")
      history.push('/agendamento/' + idLp + '/calendario/cadastro');
    } else {
      history.push('/agendamento/' + idLp + '/cadastro');
    }

  }, [idLp, serviceSelected, selectedNewDate])

  const returnLp = useCallback(() => {
    window.location.reload();
  }, [])

  const handleClickService = useCallback((serv) => {
    console.log(serv)
    let lista = services;
    lista.filter(f => f.id == serv.id)[0].selected = true;
    lista.filter(f => f.id != serv.id).map(f => {
      f.selected = false;
    })
    console.log(lista);
    setServiceSelected(serv);

    setServices(lista);

  }, [services])

  return (
    <>
      {lpData && lpData.scripts &&
        <InnerHTML html={lpData?.scripts} />
      }
      <Helmet>
        <title>Agendamento - {tenant?.name}</title>
      </Helmet>
      {loadingData &&
        <Grid container direction="column"
          justify="center"
          alignItems="center" >
          <Grid item xs={12} className={classes.GridList}>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      }
      {!loadingData && enviadoFormOk == true && enviadoForm == true &&
        <div className={classes.mainRaised}>
          <Content>
            <Grid justify="center">
              <Grid item xs={12} sm={12} >
                <div>
                  <Lottie options={defaultOptions}
                    height={150}
                    width={150}
                    speed={0.6}
                    isStopped={isStopped}
                  />
                  <Grid container direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={10} md={8} lg={6} >
                      <Paper elevation={1} className={classes.Paper} >
                        <Grid container alignItems="center" justify="center">
                          <Grid item xs={12} className={classes.textMessage}>
                            <Typography gutterBottom variant="h5" >
                              Cadastro realizado
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.textMessageSub}>
                            <Typography gutterBottom variant="subtitle1" component="p" >
                              Enviamos em seu e-mail algumas informações!
                            </Typography>
                          </Grid>
                          {agradecimento &&
                            <Grid item xs={12} className={classes.textMessageCustom}>
                              {ReactHtmlParser(agradecimento)}
                            </Grid>
                          }
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      }

      {!loadingData && !enviadoFormOk && enviadoForm == true &&
        <div className={classes.mainRaised}>
          <Content>
            <Grid justify="center">
              <Grid item xs={12} sm={12} >
                <div>
                  <Lottie options={defaultOptionsError}
                    height={150}
                    width={150}
                    speed={0.6}
                    isStopped={isStopped}
                  />
                  <Grid container direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={10} md={8} lg={6} >
                      <Paper elevation={1} className={classes.Paper} >
                        <Grid container alignItems="center" justify="center">
                          <Grid item xs={12} className={classes.textMessage}>
                            <Typography gutterBottom variant="h5" >
                              Cadastro não realizado!
                            </Typography>
                          </Grid>
                          {mensagem &&
                            <Grid item xs={12} className={classes.textMessageCustom}>
                              {ReactHtmlParser(mensagem)}
                            </Grid>
                          }
                          <Grid item xs={12} className={classes.textMessageCustom}>
                            <Button variant="contained" onClick={returnLp}>Voltar</Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      }

      {!loadingData && enviadoForm == false && lpData && lpData.html &&


        <Grid item xs={12} sm={12} md={8} >
          <Typography variant="h4" color="primary"  > Selecione a modalidade, dia e horário.</Typography>

          <Grid item xs={12} >

            {services?.map(serv => (
              <StyledChip color={serv.selected ? "primary" : "default"} key={serv.id} className={classes.ChipService}
                label={serv.name}
                onClick={() => handleClickService(serv)}
              />
            ))}

          </Grid>
          <Grid container direction="row" >
            <Grid item xs={12} sm={6} md={6} direction="column" alignItems='center' style={{ display: "flex" }} >
              <Calendar>
                <DayPicker className={classes.dayPicker}
                  weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S',]}
                  fromMonth={new Date()}
                  disabledDays={disabledDays}
                  selectedDays={selectedNewDate}
                  onMonthChange={handleMonthChange}
                  modifiers={
                    { available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] } }
                  }
                  onDayClick={handleDateNewChange}
                  months={[
                    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
                  ]}
                />
              </Calendar>
            </Grid>
            <Grid item xs={12} sm={6} md={6}  >
              <div className={classes.containerFixo}>
                {hoursAvailability && hoursAvailability.length > 0 && selectedNewDate &&
                  <>
                    <h3 className={classes.titleHours}>Horários - {format(selectedNewDate, 'dd/MMM', { locale: ptBR })}</h3>
                    <div className={classes.listHours}>

                      {hoursAvailability?.map((item) => (
                        <Grid item xs={12} key={item.DataInicio} >
                          <Button disabled={item.Desabilitado} fullWidth variant="outlined" className={classes.itemServiceButton} onClick={() => handleHourClick(item)}>
                            <Grid direction='column' className={classes.itemService} container alignItems='flex-start' item xs={12}  >
                              <p className={classes.itemServiceName}>
                                {item.Servico}
                              </p>
                              <p className={classes.itemServiceHour}>
                                {`${format(parseISO(item.DataInicio), "HH:mm")} às ${format(parseISO(item.DataFim), "HH:mm")}h`}
                              </p>
                            </Grid>
                          </Button>

                        </Grid>
                      ))}
                    </div>
                  </>
                }
                {hoursAvailability && hoursAvailability.length == 0 && selectedNewDate &&
                  <div className={classes.listHours}>
                    <h3 className={classes.titleHours}>Nenhum horário disponível para {format(selectedNewDate, 'dd/MMM', { locale: ptBR })}</h3>
                  </div>
                }
              </div>
            </Grid>
          </Grid>

        </Grid>

      }



    </>
  );

}
export default withRouter(LandingPageCalendario);
