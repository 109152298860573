import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles, Theme, useTheme, Grid, CircularProgress, Container, FormControl, InputLabel, Input, FormHelperText, TextField, FilledInput, InputAdornment, OutlinedInput, Box, Typography, Paper, Select, MenuItem, Button, Checkbox, ListItemText, Chip } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import api from '../../services/api';
import { Helmet } from 'react-helmet';
import { AnamnesePerguntaTipo, IFormCustom, IFormOptions, IFormQuestions } from '../../models/Forms/IFormsDTO';
import { useAuth } from '../../hooks/auth';
import { Alert, Autocomplete } from '@material-ui/lab';
import Header from '../../components/Header';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useToast } from '../../hooks/toast';
import Lottie from 'react-lottie';
import animationDataError from '../../assets/animations/error.json';
import animationData from '../../assets/animations/success.json';
import { Error as ErrorIcon } from '@material-ui/icons';
import { green, orange } from '@material-ui/core/colors';


const useStyles = makeStyles((theme: Theme) => ({
    GridList: {
        margin: '28px 0 !important',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,

    },
    dividerMargin: {
        margin: '20px 0',
    },
    Paper: {
        padding: '20px',
        marginBottom: '26px',
    },
    titleMessage: {
        fontSize: '15px',
        marginBottom: '10px',
        textAlign: 'center',
        marginTop: 30,
    },
    descriptionMessage: {
        display: 'flex',
        fontSize: '18px',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '19px',
    },
    descriptionHeloMessage: {
        display: 'flex',
        fontSize: '14px',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '30px',
    }
})
);

type RouteParams = {
    id: string
    idtenant: string
    idunidade: string
}
interface IDictionary<TValue> {
    [id: string]: TValue;
}
const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
};
const defaultOptionsError = {
    loop: false,
    autoplay: true,
    animationData: animationDataError,
};

type FormsDetailProps = RouteComponentProps<RouteParams>;
const Forms: React.FC<FormsDetailProps> = ({ match }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { tenant } = useAuth();
    const [perguntas, setPerguntas] = useState<IFormCustom>();
    const [loadingData, setLoadingData] = useState(false);
    const [isStopped, setIsStopped] = useState<boolean>(true);
    const { addToast } = useToast();
    const [userRoles, setUserRoles] = React.useState<IFormOptions[]>([]);
    const [erro, setErro] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    const [enviando, setEnviando] = useState(false);
    const [erroMessage, setErroMessage] = useState("");

    // let RegisterSchema = Yup.object().shape({
    //     name: Yup.string().required('Nome obrigatório'),
    //     neighborhood: Yup.string().required('Digite o bairro'),
    //     cep: Yup.string(),
    //     idCidade: Yup.string().required('Escolha a cidade'),
    //     idUnidade: Yup.string(),

    // });

    let RegisterSchema = Yup.object().shape({});
    let objUseForm = useForm<any>({
        resolver: yupResolver(RegisterSchema),
    });

    objUseForm = useForm<any>({
        resolver: yupResolver(RegisterSchema),
    });

    useEffect(() => {

        const idForm = match.params.id;
        const idUnidade = match.params.idunidade;
        console.log(idForm, idUnidade);
        if (idForm && tenant) {
            setLoadingData(true);
            api.get('/forms/' + tenant.idTenant + '/' + idForm)
                .then(retorno => {
                    setPerguntas(retorno.data)
                    console.log(retorno.data)

                    setLoadingData(false);
                    const perguntasApi = retorno.data as IFormCustom;
                    if (perguntasApi && perguntasApi.Perguntas) {
                        let shape: IDictionary<any> = {};
                        perguntasApi.Perguntas.forEach(f => {
                            shape[f.Id.toString()] = Yup.string().required();
                        })
                        console.log(shape);
                        RegisterSchema = Yup.object().shape(shape);
                    }
                }).catch(err => {
                    setIsStopped(false);
                    setLoadingData(false);
                    console.log(err.response)
                    setErro(true);
                    if (err.response) {
                        if (err.response.status == 400) {
                            setErroMessage(err.response.data.Message);
                        }
                        if (err.response.status == 404) {

                            setErroMessage("Página não encontrada");
                        }
                    } else {
                        setErroMessage("Não foi possível conectar");
                    }
                });
        } else if (idUnidade) {
            setLoadingData(true);
            api.get('/forms/' + tenant.idTenant + '/test/' + idUnidade)
                .then(retorno => {
                    setPerguntas(retorno.data)
                    console.log(retorno.data)
                    setLoadingData(false);
                    const perguntasApi = retorno.data as IFormCustom;
                    if (perguntasApi && perguntasApi.Perguntas) {
                        let shape: IDictionary<any> = {};
                        perguntasApi.Perguntas.forEach(f => {
                            shape[f.Id.toString()] = Yup.string().required();
                        })
                        console.log(shape);
                        RegisterSchema = Yup.object().shape(shape);
                    }
                }).catch(err => {
                    setIsStopped(false);
                    setLoadingData(false);
                    console.log(err.response)
                    setErro(true);
                    if (err.response) {
                        if (err.response.status == 400) {
                            setErroMessage(err.response.data.Message);
                        }
                        if (err.response.status == 404) {

                            setErroMessage("Página não encontrada");
                        }
                    } else {
                        setErroMessage("Não foi possível conectar");
                    }
                });
        }


    }, [tenant?.idTenant]);

    const onSubmit = (data: IFormCustom) => {
        console.log(perguntas)
        setEnviando(true);
        if (perguntas?.Teste) {
            setTimeout(() => {
                setEnviando(false);
                setSucesso(true);
                setIsStopped(false);
            }, 1000);
        } else {
            api.post('/forms/' + tenant.idTenant, perguntas).then(response => {
                console.log(response.data);
                setEnviando(false);
                setSucesso(true);
                setIsStopped(false);
            }).catch(err => {
                setEnviando(false);
                setSucesso(false);
                addToast({
                    type: "error",
                    title: 'Erro',
                    description: 'Não foi possível se conectar ao servidor',
                });
            })
        }

    }

    const handleChange = useCallback((index, event) => {
        if (perguntas) {

            const values = [...perguntas?.Perguntas];
            values[index].Resposta = event.target.value;
            perguntas.Perguntas = values;
            setPerguntas(perguntas);
        }

    }, [perguntas])
    const handleChangeMulti = useCallback((index, event, value: IFormOptions[]) => {
        if (perguntas) {
            const values = [...perguntas?.Perguntas];
            values[index].Opcoes.forEach((item) => {
                item.Selecionado = false;
            });
            value.forEach((item) => {

                const itemValor = values[index].Opcoes.find(d => d.Id == item.Id);
                if (itemValor) {
                    values[index].Opcoes[values[index].Opcoes.indexOf(itemValor)].Selecionado = true;
                }
            })
            perguntas.Perguntas = values;
            setPerguntas(perguntas);
        }
    }, [perguntas, perguntas])


    const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
        setUserRoles(event.target.value as IFormOptions[]);
    };

    return (
        <>
            <Helmet>
                <title>Formulário</title>
            </Helmet>
            <Header avatar={false} />
            {loadingData &&
                <Grid container direction="column"
                    justify="center"
                    alignItems="center" >
                    <Grid item xs={12} className={classes.GridList}>
                        <CircularProgress size={40} />
                    </Grid>
                </Grid>
            }
            {!loadingData && erro == false && sucesso == true &&
                <Grid item xs={12} sm={12} >
                    <div >
                        <div>
                            <Lottie options={defaultOptions}
                                height={115}
                                width={115}
                                speed={0.6}
                                isStopped={isStopped}
                            />
                            <Grid container direction="row" className={classes.dividerMargin}
                                justify="center"
                                alignItems="center">
                                <Grid item xs={12} sm={10} md={6} lg={4} >
                                    <Paper elevation={1} className={classes.Paper} >
                                        {perguntas?.TextoFinal ?
                                            <Typography gutterBottom color="textSecondary" className={classes.titleMessage} >
                                                {perguntas.TextoFinal}
                                            </Typography>
                                            :
                                            <Typography gutterBottom color="textSecondary" className={classes.titleMessage} >
                                                Questionário salvo com sucesso. Obrigado por responder.
                                            </Typography>
                                        }


                                    </Paper>

                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            }
            {!loadingData && erro == true && sucesso == false &&
                <Grid item xs={12} sm={12} >
                    <div >
                        <div>

                            <Grid container direction="row" className={classes.dividerMargin}
                                justify="center"
                                alignItems="center">
                                <Grid item xs={12} sm={10} md={6} lg={4}  >

                                    <Paper elevation={1} className={classes.Paper}  >
                                        <Lottie options={defaultOptionsError}
                                            height={115}
                                            width={115}
                                            speed={0.6}
                                            isStopped={isStopped}
                                        />
                                        <Typography gutterBottom color="textSecondary" className={classes.titleMessage} >
                                            {erroMessage}
                                        </Typography>
                                    </Paper>

                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            }
            {!loadingData && erro == false && sucesso == false &&
                <Container maxWidth="sm" style={{ marginTop: 30 }}>
                    {perguntas?.Teste &&
                        <Alert variant="filled" severity="warning" style={{ marginBottom: 30 }}>
                            Este formulário está em modo <strong>teste</strong>. Preencha os campos obrigatórios e clique em enviar. <strong>Nenhuma informação será salva.</strong>
                        </Alert>
                    }
                    <Typography variant="h5" gutterBottom>
                        {perguntas?.Titulo}
                    </Typography>
                    <Typography variant="subtitle2" style={{ marginBottom: 20, whiteSpace: 'break-spaces'}}>
                        {perguntas?.TextoInicial}
                    </Typography>
                    <form onSubmit={objUseForm.handleSubmit(onSubmit)} >
                        <Grid
                            spacing={2}
                            container
                            direction="row"
                            alignItems="center"
                        >

                            {perguntas?.Perguntas.map((item, index) => (

                                <Grid item xs={12}  >
                                    <Paper style={{ padding: 22, paddingBottom: 38 }}>
                                        {item.Tipo == AnamnesePerguntaTipo.Texto &&
                                            <FormControl fullWidth error={!!objUseForm.errors[item.Id.toString()]?.message}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    {item.Pergunta}
                                                </Typography>
                                                <Input
                                                    required={item.Obrigatoria}
                                                    name={item.Id.toString()}
                                                    placeholder="Sua resposta"
                                                    id="filled-adornment-amount"
                                                    value={item.Resposta}
                                                    multiline
                                                    rowsMax={4}
                                                    onChange={event => handleChange(index, event)}

                                                />
                                            </FormControl>
                                        }
                                        {item.Tipo == AnamnesePerguntaTipo.UnicaEscolha &&
                                            <FormControl fullWidth >
                                                <Typography variant="subtitle1" gutterBottom>
                                                    {item.Pergunta}
                                                </Typography>

                                                <Select
                                                    name={item.Id.toString()}
                                                    required={item.Obrigatoria}

                                                    placeholder="Escolha uma opção"
                                                    onChange={event => handleChange(index, event)}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Escolha uma opção
                                                    </MenuItem>
                                                    {item.Opcoes.map(p => (
                                                        <MenuItem key={p.Id} value={p.Id}>
                                                            {p.Descricao}
                                                        </MenuItem>

                                                    ))}

                                                </Select>
                                            </FormControl>

                                        }
                                        {item.Tipo == AnamnesePerguntaTipo.MultiplaEscolha &&
                                            <FormControl fullWidth >
                                                <Typography variant="subtitle1" gutterBottom>
                                                    {item.Pergunta}
                                                </Typography>

                                                <Autocomplete
                                                    multiple
                                                    aria-required={item.Obrigatoria}
                                                    componentName={item.Id.toString()}
                                                    id={item.Id.toString()}
                                                    options={item.Opcoes}
                                                    getOptionLabel={(option) => option.Descricao}
                                                    onChange={(event, value) => handleChangeMulti(index, event, value)}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    noOptionsText="Nenhuma opção"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required={item.Opcoes.filter(d => d.Selecionado == true).length == 0 ? item.Obrigatoria : false}
                                                            name={item.Id.toString()}
                                                            {...params}
                                                            variant="standard"
                                                            placeholder="Escolha algumas opções"
                                                        />
                                                    )}
                                                />


                                                {/* <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                multiple
                                                value={userRoles}
                                                onChange={handleChangeMultiple}
                                                input={<Input id="select-multiple-chip" />}
                                                renderValue={(selected) => (
                                                    <div className={classes.chips}>
                                                        {(selected as IFormOptions[]).map((value) => (
                                                            <Chip key={value.Id} label={value.Descricao} className={classes.chip} />
                                                        ))}
                                                    </div>
                                                )}
                                                name={item.Id.toString()}
                                            >
                                                {item.Opcoes.map((opcao) => (
                                                    <MenuItem key={opcao.Id} value={opcao.Id}>
                                                        <Checkbox checked={userRoles.indexOf(opcao.Id.toString()) > -1} />
                                                        <ListItemText primary={opcao.Descricao} />
                                                    </MenuItem>
                                                ))}
                                            </Select> */}

                                            </FormControl>

                                        }
                                    </Paper>

                                </Grid>
                            )
                            )}


                        </Grid>
                        <Button disabled={enviando} style={{ marginTop: 20, marginBottom: 30 }} type="submit"

                            variant="contained" color="primary">
                            Enviar
                            {enviando && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                    </form>

                </Container>
            }
        </>
    )
};
export default withRouter(Forms);
