import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { format, isDate, isToday, parse, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import ptLocale from "date-fns/locale/pt-BR";
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';
import { List as ListComponent, makeStyles, createStyles, Theme, Grid, CircularProgress, Paper, Typography, Button, Container, Box, Chip, withStyles, ListItem, ListItemIcon, ListItemText, Dialog, Avatar, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, Input, InputLabel, List, ListItemAvatar, ListItemSecondaryAction, Radio, RadioGroup, TextField } from '@material-ui/core';
import Content from '../../../components/Content';

import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/success.json';
import animationDataError from '../../../assets/animations/error.json';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { ArrowBackIos, ArrowForwardIos, ArrowLeft, Check, MenuBook, QueryBuilder, Today, BlurCircular, Lens } from '@material-ui/icons';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { AnimationContainer, Background } from '../../Login/SignIn/styles';
import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import InputMask from "react-input-mask";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Recaptcha from 'react-recaptcha';
import { IUserLogin } from '../../../models/Perfil';
import { green } from '@material-ui/core/colors';
import { IAulaEscolhida, IAulaExperimental } from '../../../models/Appointment/IAppointmentDTO';


const StyledChip = withStyles({

  label: {
    fontWeight: 500,
  },
})(Chip);

const useStyles = makeStyles((theme: Theme) =>



  createStyles({
    feedback: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
    },
    GridList: {
      margin: '28px 0 !important',
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",
    },
    Paper: {
      padding: '20px',
      marginBottom: '26px',
      marginTop: '32px',
    },
    textMessage: {
      textAlign: 'center',
    },
    textMessageSub: {
      textAlign: 'center',
      color: theme.palette.text.disabled,
      marginBottom: '20px',
    },
    textMessageCustom: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    titleHours: {
      margin: '18px 6px',

    },
    chipHour: {
      fontSize: '1rem',
    },
    divBack: {
      marginTop: '10px',
    },
    listHours: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    dayPicker: {

      // backgroundColor: theme.palette.background.paper,
      // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      // borderRadius: '4px',
      borderRadius: '50%',

      '& .DayPicker-Day--available:not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.main,

        color: '#fff',
      },
      '& .DayPicker-Day--available:not(.DayPicker-Day--disabled):hover:not(.DayPicker-Day--outside):hover': {
        background: theme.palette.primary.dark + ' !important',

      },
      '& .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.dark + ' !important',

        color: theme.palette.primary.contrastText,
      },
      '& .DayPicker-Day--today.DayPicker-Day--disabled': {
        background: '#ccc !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--disabled': {
        background: '#f3f3f3'
      },

    },
    dayPickerModal: {

      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',

      '& .DayPicker-Day--available:not(.DayPicker-Day--outside)': {
        background: 'rgb(130 130 130)',
        borderRadius: '10px',
        color: '#fff',
      },
      '& .DayPicker-Day--available:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover': {
        background: theme.palette.primary.main + ' !important',

      },
      '& .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.main + ' !important',
        borderRadius: '10px',
        color: theme.palette.primary.contrastText,
      },
      '& .DayPicker-Day--today.DayPicker-Day--disabled': {
        background: '#ccc !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--disabled': {
        background: 'transparent',
      },
      '& .DayPicker-Day--available.DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
        background: theme.palette.secondary.main + ' !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--selected:hover:not(.DayPicker-Day--outside):hover': {
        background: theme.palette.secondary.dark + ' !important',
      },

    },
    ChipService: {
      margin: '10px 5px',
    },
    itemService: {
      flex: 1,
    },
    itemServiceName: {
      color: theme.palette.text.disabled,
    },
    itemServiceHour: {
      fontSize: '17px',
    },
    itemServiceButton: {
      textTransform: 'none',
    },
    animationTab: {
      height: '100%',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    marginContent: {
      margin: '0px 25px',
    },
    titleAddress: {
      marginTop: '20px',
    }

  })
);


interface ILandingPageData {
  name: string,
  email: string,
  birth: string,
  cpf: string,
  gender: string,
  celular: string,
  idTenant: string,
  identificador:string,
  idServicoAgendamento: number | undefined,
  tipoServico: string | undefined,
  dataAgendamento: string| undefined,
  recaptcha: string | null,
  idUserLocalized: number | null,
  typeUserLocalized: number | null,
  origin: string | null,
}
interface ICity {
  name: string;
  id: string;
}
var dataForms: ILandingPageData;
interface SignUpLoginFormData {
  password: string;
  password_confirmation: string;

}
interface UserLocalized {
  id: number;
  type: number;
}
interface IHoursService {
  DataInicio: string;
  DataFim: string;
  Servico: string;
}
type RouteParams = {
  id: string;
}
type BookDetailProps = RouteComponentProps<RouteParams>;



const LandingPageCalendarioCadastro: React.FC<BookDetailProps> = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const cpfRef = useRef<HTMLInputElement | null>(null);
  const { tenant, updateUser } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [htmlData, setHtmlData] = useState<any>();
  const [enviadoForm, setEnviadoForm] = useState<boolean>(false);
  const [agradecimento, setAgradecimento] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [enviadoFormOk, setEnviadoFormOk] = useState<boolean>(false);
  const [isStopped, setIsStopped] = useState<boolean>(true);
  const [tab, setTab] = React.useState(0);
  const [selectedGender, setSelectedGender] = React.useState('Feminino')
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [cidade, setCidade] = React.useState<ICity | null>(null);
  const [optionsCity, setOptionsCity] = React.useState<ICity[]>([]);
  const [inputCity, setInputCity] = React.useState('');
  const [openEmail, setOpenEmail] = React.useState(false);
  const [userTemp, setUserTemp] = React.useState<IUserLogin>();
  const [cpfInput, setCpfInput] = React.useState<string | undefined>();
  const [openModalLogin, setOpenModalLogin] = React.useState(false);
  const [userLocalized, setUserLocalized] = React.useState<UserLocalized>();
  const [serviceSelected, setServiceSelected] = useState<IAulaEscolhida | undefined>();
  const [lp, setLp] = useState<any>();
  const [nameUser, setNameUser] = React.useState('');
  const [openCity, setOpenCity] = React.useState(false)
  const nameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const refRecaptcha = useRef<Recaptcha | null>(null);
  const formPasswordRef = useRef<HTMLFormElement | null>(null);

  

  const [idLp, setIdLp] = useState("");

  const { addToast } = useToast();


  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
  };
  const defaultOptionsError = {
    loop: false,
    autoplay: false,
    animationData: animationDataError,
  };
  useEffect(() => {
    const idLanding = match.params.id;

    const landingPage = localStorage.getItem('@HubfitPortal:lp');
    if(landingPage){
      setLp(JSON.parse(landingPage));
      console.log(JSON.parse(landingPage));

      const lpParsed = JSON.parse(landingPage);

   
    }


    if (window.location.href.indexOf("calendario") > -1) {
      const body = document.body;
      body.setAttribute("style", "background-color:transparent;padding: 20px;");
      }
    setTimeout(() => {
      cpfRef.current?.focus();
    }, 1000)
    if (idLanding && tenant) {
      setIdLp(idLanding);
      const aula = localStorage.getItem('@HubfitPortal:itemAula');
      if(aula){
      setServiceSelected(JSON.parse(aula) as IAulaEscolhida);
      }else{
        if (window.location.href.indexOf("calendario") > -1) {
          history.push("/agendamento/" + idLanding+"/calendario");
        }else{
          history.push("/agendamento/" + idLanding);
        }
        
      }
    
    }
  }, [match.params.id])

  // useEffect(() => {
  //   const idLanding = match.params.id;
  //   if (idLanding && tenant) {
  //     setIdLp(idLanding);
  //     const item = localStorage.getItem('@HubfitPortal:itemAula');
  //     if (item) {
  //       console.log(item);
  //       setSelectedHour(JSON.parse(item) as IHoursService);
  //     } else {
  //       history.push("/agendamento/" + idLanding);
  //     }
  //   }
  // }, [tenant?.idTenant,match.params.id])

  const returnLp = useCallback(() => {
    window.location.reload();
  }, [])


  function validadeCPF<bollean>(cpf: string | undefined | null) {
    if (cpf) {
    cpf = cpf.replace('.', '',).replace('.', '',).replace('-', '');
    //console.log(cpf)
    if (cpf == null) {
      return false;
    }
    if (cpf.length != 11) {
      return false;
    }
    if ((cpf == '00000000000') || (cpf == '11111111111') || (cpf == '22222222222') || (cpf == '33333333333') || (cpf == '44444444444') || (cpf == '55555555555') || (cpf == '66666666666') || (cpf == '77777777777') || (cpf == '88888888888') || (cpf == '99999999999')) {
      return false;
    }
    let numero: number = 0;
    let caracter: string = '';
    let numeros: string = '0123456789';
    let j: number = 10;
    let somatorio: number = 0;
    let resto: number = 0;
    let digito1: number = 0;
    let digito2: number = 0;
    let cpfAux: string = '';
    cpfAux = cpf.substring(0, 9);
    for (let i: number = 0; i < 9; i++) {
      caracter = cpfAux.charAt(i);
      if (numeros.search(caracter) == -1) {
        return false;
      }
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
      digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i: number = 0; i < 10; i++) {
      caracter = cpfAux.charAt(i);
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
      digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf != cpfAux) {
      return false;
    }
    else {
      return true;
    }
  }else {
    return false;
  }

  }

  const handleCloseEmail = useCallback(() => {
    setOpenEmail(false);
  }, []);

  // let RegisterPasswordSchema = Yup.object().shape({
  //   password: Yup.string().required('Senha obrigatória'),
  //   password_confirmation: Yup.string().oneOf(
  //     [Yup.ref('password'), undefined], 'Senha diferente a primeira',
  //   )
  // });
  function parseDateString(value: any, originalValue: string) {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "dd/MM/yyyy", new Date());

    return parsedDate;
  }
  
  

  var RegisterSchema =  Yup.object().shape({
    name: Yup.string().required('Nome obrigatório'),
    email: Yup.string().required('E-mail obrigatório').email("E-mail inválido"),
    birth: Yup.date().when([], {
      is: () => lp && lp.nascimentoObrigatorio,
      then: Yup.date().transform(parseDateString).required('Data de nascimento obrigatória').typeError('Data inválida')
  }),
    // .transform(parseDateString).max(today)
    gender:  Yup.boolean().when([], {
      is: () => lp && lp.sexoObrigatorio,
      then: Yup.boolean().oneOf([true], "Escolha o gênero")
  }),
    celular: Yup.string().when([], {
      is: () => lp && lp.celularObrigatorio,
      then: Yup.string().required('Celular obrigatório')
  }),
    cpf:  Yup.string().when([], {
      is: () => lp && lp.cpfObrigatorio,
      then: Yup.string().required('CPF obrigatório').test('cpf', 'CPF incorreto', (value) => (
        validadeCPF(value)
      )),
  }),
    idUnidade: Yup.string(),
    // password: Yup.string().required('Senha obrigatória'),
    // password_confirmation: Yup.string().oneOf(
    //   [Yup.ref('password'), undefined],'Senha diferente a primeira',
    //)
  });

  const { register, handleSubmit, errors, setValue, getValues } = useForm<ILandingPageData>({
    resolver: yupResolver(RegisterSchema),
  });

  // const formPassword = useForm<SignUpLoginFormData>({
  //   resolver: yupResolver(RegisterPasswordSchema)
  // });
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleTabReturn = useCallback(() => {
    if (window.location.href.indexOf("calendario") > -1) {
   
    history.push("/agendamento/" + idLp+"/calendario");
    }else{
      history.push("/agendamento/" + idLp);
    }
  }, [idLp]);
  const handleChangeGender = ((event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log((event.target as HTMLInputElement).value);
    setSelectedGender((event.target as HTMLInputElement).value);
  });
  const onSubmit = useCallback(async (data: ILandingPageData) => {

    dataForms = data;
    try {
      dataForms.idTenant = tenant.idTenant;
      dataForms.gender = selectedGender;
      dataForms.birth = dataForms.birth;
      dataForms.identificador = idLp;
      dataForms.idServicoAgendamento = serviceSelected?.idService;
      dataForms.dataAgendamento = serviceSelected?.data;
      dataForms.tipoServico = serviceSelected?.type;
      dataForms.origin = "landingpage";
      setNameUser(data.name);

      dataForms.recaptcha = sessionStorage.getItem('@HubfitPortal:recap');
      if (userLocalized) {
        dataForms.idUserLocalized = userLocalized?.id;
        dataForms.typeUserLocalized = userLocalized?.type;
      }
      setLoading(true);
      const response = await api.post('/landingpage/conversions', dataForms);
      const usuario = response.data;
      if(usuario.jaCadastrado){
        
        refRecaptcha.current?.reset();
        if (usuario.erro) {
        addToast({
          type: "error",
          title: 'Erro no cadastro',
          time: 20000,
          description: usuario.mensagem,
        });
          
        } else {
          addToast({
            type: "error",
            title: 'Erro no cadastro',
            time: 20000,
            description:  "Encontramos outro cadastro com as mesmas informações, não é possível agendar.",
          });
        }
      }else{
      const token = response.data.token;
      delete usuario.token;
      await updateUser(usuario, token);
       localStorage.removeItem('@HubfitPortal:itemAula');
      setEnviadoForm(true);
      setEnviadoFormOk(true);
      setLoading(false);

      setTimeout(() => {
        setIsStopped(false);
      }, 1000);
    }

    } catch (err) {
      refRecaptcha.current?.reset();
      console.log(err.response);

      addToast({
        type: "error",
        title: 'Erro no cadastro',
        time: 20000,
        description: err.response ? err.response.data.emailUsado == true ? 'O e-mail informado já está sendo utilizado em outro cadastro.' : err.response.data.cpfInvalido == true ? 'O CPF informado é inválido' : err.response.data.cpfUsado == true ? 'O CPF informado já está sendo utilizado em um cadastro.' : 'Ocorreu um erro ao fazer cadastro, tente novamente.' : 'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    } finally {
      setLoading(false);
      sessionStorage.removeItem('@HubfitPortal:recap');
    }
  },[tenant, selectedGender, userLocalized, serviceSelected,idLp]);


  const recaptchaLoaded = useCallback(() => {
    console.log('recaptcha load')
  }, [])

  const recaptchaCallback = useCallback((response) => {
    sessionStorage.setItem('@HubfitPortal:recap', response);
    console.log(response)
  }, [])
  
  const handleListItemUserLocalizedClick = useCallback((user: IUserLogin | undefined) => {
    if (user) {
      setUserLocalized(
        {
          id: user.id,
          type: user.tipoPessoa
        }
      );
      setOpenEmail(false);
    }
  }, [])
  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handleChangeEmail = useCallback(async (e) => {
    const val = e.target.value;
    console.log(val)
    if(lp.aceitaCadastrados == false){
    if (val && validateEmail(val)) {
      const { data } = await api.get("/users/email", {
        params: {
          email: val,
          idTenant: tenant.idTenant,
        }
      });
      console.log(data)
      switch (data.cadastro) {
        case 1:
        case 2:
          //Já possui cadastro com login e senha, direcionar para tela de login
          addToast({
            type: "error",
            title: 'Cadastro encontrado',
            description: 'Encontramos um cadastro com o mesmo e-mail. Não será possível continuar.',
            time: 10000
          });
          break;
      }

    } else {
      addToast({
        type: "error",
        title: 'E-mail inválido',
        description: 'Digite um e-mail válido',
        time: 5000
      });
    }
  }
  }, [tenant.idTenant, lp])


  const handleChangeCpf = useCallback(async (e) => {
    try {
     // setLoading(true);
      const val = e.target.value;
      //Buscar por CPF na API
      const { data } = await api.get("/users/cpf", {
        params: {
          cpf: val,
          idTenant: tenant.idTenant,
        }
      });
      switch (data.cadastro) {

        case 1:
        case 2:
          //Possui cadastro, pedir para fazer login.
          addToast({
            type: "error",
            title: 'Cadastro encontrado',
            description: 'Encontramos um cadastro com o mesmo CPF. Não será possível continuar.',
            time: 10000
          });
          break;



      }
      console.log(data);

    } catch (err) {
      console.log(err)
      return;
    } finally {
      setLoading(false);
    }
  }, [tenant])




  return (
    <>
      <Helmet>
        <title>Agendamento - {tenant?.name}</title>
      </Helmet>
      {loadingData &&
        <Grid container direction="column"
          justify="center"
          alignItems="center" >
          <Grid item xs={12} className={classes.GridList}>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      }
      {!loadingData && enviadoFormOk == true && enviadoForm == true &&
        <div className={classes.mainRaised}>
          <Content>
            <Grid justify="center">
              <Grid item xs={12} sm={12} >
                <div>
                  <Lottie options={defaultOptions}
                    height={150}
                    width={150}
                    speed={0.6}
                    isStopped={isStopped}
                  />
                  <Grid container direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={10} md={8} lg={8} >
                      <Paper elevation={1} className={classes.Paper} >
                        <Grid container alignItems="center" justify="center">
                          <Grid item xs={12} className={classes.textMessage}>
                            <Typography gutterBottom variant="h5" >
                              {nameUser
                              &&
                              <span>{nameUser}, </span>
                              }
                              Seu agendamento de 
                              {serviceSelected && 
                              <span> {serviceSelected?.service} </span>
                            } foi realizado com sucesso!
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.textMessageSub}>
                            <Typography gutterBottom variant="subtitle1" component="p" >
                              Anote em sua agenda e caso não possa comparecer, nos avise com antecedência.
                            </Typography>
                          </Grid>
                          {agradecimento &&
                            <Grid item xs={12} className={classes.textMessageCustom}>
                              {ReactHtmlParser(agradecimento)}
                            </Grid>
                          }
                      
                        <Grid item xs={12} sm={12} md={6} style={{marginTop:'10px'}} >
                    <ListComponent component="nav" aria-label="main mailbox folders">
                    <ListItem >
                        <ListItemIcon>
                        <Lens style={{color:'#77cb3c'}}/>
                        </ListItemIcon>
                        <ListItemText primary={serviceSelected?.service} />
                      </ListItem>
                      <ListItem >
                        <ListItemIcon>
                          <Today color="primary" />
                        </ListItemIcon>
                        {serviceSelected && 
                          <ListItemText primary={format(parseISO(serviceSelected?.data), "eeee, dd 'de' MMMM 'de' yyyy", { locale: ptBR })} />
                        }

                      </ListItem>
                      <ListItem >
                        <ListItemIcon>
                          <QueryBuilder color="primary" />
                        </ListItemIcon>
                        {serviceSelected &&
                          <ListItemText primary={`${format(parseISO(serviceSelected.data), "HH:mm")}h às ${format(parseISO(serviceSelected.dataEnd), "HH:mm")}h`} />
                        }
                      </ListItem>
                    </ListComponent>
                  </Grid>
                  </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      }
{/* 
      {!loadingData && !enviadoFormOk && enviadoForm == true &&
        <div className={classes.mainRaised}>
          <Content>
            <Grid justify="center">
              <Grid item xs={12} sm={12} >
                <div>
                  <Lottie options={defaultOptionsError}
                    height={150}
                    width={150}
                    speed={0.6}
                    isStopped={isStopped}
                  />
            
                </div>
                <Grid container direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={10} md={8} lg={6} >
                      <Paper elevation={1} className={classes.Paper} >
                        <Grid container alignItems="center" justify="center">
                          <Grid item xs={12} className={classes.textMessage}>
                            <Typography gutterBottom variant="h5" >
                              Não foi possível agendar!
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.textMessageSub}>
                            <Typography gutterBottom variant="subtitle1" component="p" >
                              Enviamos em seu e-mail algumas informações!
                            </Typography>
                          </Grid>
                         
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </Content>
        </div>
      } */}

      <div>
      {!loadingData && enviadoForm == false &&
         
              <Content>
                <div className={classes.divBack}>
                  <Button onClick={handleTabReturn}>
                    <ArrowLeft />
                    Voltar
                  </Button>

                </div>
                <Grid container spacing={3} direction="row"
                  justify="center"
                  alignItems="flex-start" >

                  <Grid item xs={12} sm={12} md={4} style={{marginTop:'10px'}} >
                    <ListComponent component="nav" aria-label="main mailbox folders">
                      <ListItem >
                        <ListItemIcon>
                        <Lens style={{color:'#77cb3c'}}/>
                        </ListItemIcon>
                        <ListItemText primary={serviceSelected?.service} />
                      </ListItem>
                      <ListItem >
                        <ListItemIcon>
                          <Today color="primary" />
                        </ListItemIcon>
                        {serviceSelected && 
                          <ListItemText primary={format(parseISO(serviceSelected?.data), "eeee, dd 'de' MMMM 'de' yyyy", { locale: ptBR })} />
                        }

                      </ListItem>
                      <ListItem >
                        <ListItemIcon>
                          <QueryBuilder color="primary" />
                        </ListItemIcon>
                        {serviceSelected &&
                          <ListItemText primary={`${format(parseISO(serviceSelected.data), "HH:mm")}h às ${format(parseISO(serviceSelected.dataEnd), "HH:mm")}h`} />
                        }
                      </ListItem>
                    </ListComponent>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} >
                    <AnimationContainer className={classes.animationTab}>

                      <CarouselProvider
                        disableKeyboard
                        currentSlide={tab}
                        touchEnabled={false}
                        dragEnabled={false}
                        isIntrinsicHeight={true}
                        naturalSlideWidth={300}
                        naturalSlideHeight={200}
                        totalSlides={2}
                      >
                        <Slider>
                          <Slide index={0} >
                            <form onSubmit={handleSubmit(onSubmit)} >
                              <Content>
                                <Grid container direction="row"
                                  justify="center"
                                  alignItems="center">
                                  <Grid item xs={12} md={12} lg={10} >
                                    <Typography variant="h5" gutterBottom >
                                      Dados pessoais
                                    </Typography>
                                    <Grid container spacing={3}>
                                      {lp && lp.cpf &&
                                      <Grid item xs={12} sm={6} md={6} >
                                        <FormControl fullWidth error={!!errors.cpf?.message}
                                          disabled={!!cpfInput}
                                        >
                                          <InputLabel htmlFor="cpf">CPF</InputLabel>
                                          <InputMask
                                            mask="999.999.999-99"
                                            disabled={false}
                                            maskChar=""
                                            value={cpfInput}
                                            onBlur={handleChangeCpf}
                                          >
                                            {<Input  name="cpf" id="cpf"
                                            inputRef={(e) => {
                                              register(e); cpfRef.current = e
                                            }}
                                              autoComplete="off"
                                              
                                            />}
                                          </InputMask>
                                          <FormHelperText>{errors.cpf?.message}</FormHelperText>
                                        </FormControl>
                                      </Grid>
                                    }
                                      <Grid item xs={12} sm={12} md={6}>
                                        <FormControl fullWidth error={!!errors.name?.message}>
                                          <InputLabel htmlFor="name">Nome</InputLabel>
                                          <Input name="name" id="name" inputRef={(e) => {
                                            register(e); nameRef.current = e
                                          }} autoComplete="off"
                                          />
                                          <FormHelperText>{errors.name?.message}</FormHelperText>

                                        </FormControl>

                                      </Grid>

                                      {lp && lp.nascimento &&
                                      <Grid item xs={12} sm={6} md={6}>
                                        <FormControl fullWidth error={!!errors.birth?.message}>
                                          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                            <KeyboardDatePicker error={!!errors.birth?.message}
                                              disableFuture
                                              openTo="year"
                                              views={["year", "month", "date"]}
                                              name='birth'
                                              id="birth"
                                              autoComplete="off"
                                              label="Nascimento"
                                              format="dd/MM/yyyy"
                                              inputRef={register}
                                              value={selectedDate}
                                              onChange={handleDateChange}
                                              maxDateMessage="Data deve ser menor que hoje"
                                              cancelLabel="Cancelar"
                                              clearLabel="Limpar"
                                              invalidDateMessage=""
                                              minDateMessage="Verifique o ano"
                                            />
                                            <FormHelperText>{errors.birth?.message}</FormHelperText>

                                          </MuiPickersUtilsProvider>

                                        </FormControl>

                                      </Grid>
}
{lp && lp.sexo &&
                                      <Grid item xs={12} sm={6} md={6}>
                                        <FormControl component="fieldset" error={!!errors.gender?.message}>
                                          <FormLabel component="legend">Gênero</FormLabel>
                                          <RadioGroup row aria-label="gender" name="gender" ref={register} value={selectedGender} onChange={handleChangeGender}>
                                            <FormControlLabel value="Feminino" control={<Radio color="primary" />} label="Feminino" />
                                            <FormControlLabel value="Masculino" control={<Radio color="primary" />} label="Masculino" />
                                          </RadioGroup>
                                          <FormHelperText>{errors.gender?.message}</FormHelperText>
                                        </FormControl>
                                      </Grid>
}
                                      <Grid item xs={12} sm={6} md={6}>
                                        <FormControl fullWidth error={!!errors.email?.message}>
                                          <InputLabel htmlFor="email">E-mail</InputLabel>
                                          <Input name="email" id="email" type="email" inputRef={register} autoComplete="off" onBlur={handleChangeEmail} />
                                          <FormHelperText>{errors.email?.message}</FormHelperText>
                                        </FormControl>
                                      </Grid>
                                      {lp && lp.celular &&
                                      <Grid item xs={12} sm={6} md={6}>
                                        <FormControl fullWidth error={!!errors.celular?.message}>
                                          <InputLabel htmlFor="celular">Celular</InputLabel>
                                          <InputMask
                                            mask="(99) 99999-9999"
                                            maskChar=""
                                          >
                                            { <Input name="celular" id="celular" type="tel" inputRef={register} autoComplete="off" />}
                                          </InputMask>

                                          <FormHelperText>{errors.celular?.message}</FormHelperText>
                                        </FormControl>
                                      </Grid>
}
                                    </Grid>
                                    
                                   
                                  </Grid>
                                </Grid>
                              </Content>
                              <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            <Recaptcha
                              ref={refRecaptcha}
                              hl='pt-BR'
                              size='normal'
                              sitekey="6LfHOKIUAAAAAPwaZ0c_LxLmhZV4wckS4xGKr9Nr"
                              render="explicit"
                              onloadCallback={recaptchaLoaded}
                              verifyCallback={recaptchaCallback}
                            />
                          </Grid>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="flex-start"
                              >
                                <Button style={{ marginBottom: '15px' }} disabled={loading} type="submit" variant="contained" color="primary" size="large" endIcon={<ArrowForwardIos />}>
                                  Continuar
                                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>



                              </Grid>
                            </form>
                          </Slide>
                     
                        </Slider>
                      </CarouselProvider>






                    </AnimationContainer>
                  </Grid>


                </Grid>
              </Content>




        }
      </div>



    </>
  );

}
export default withRouter(LandingPageCalendarioCadastro);
