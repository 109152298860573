import React from "react";
import{CardHeaderDivBody} from './styles';
import { useTheme, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAuth } from "../../hooks/auth";
import classNames from "classnames";




const CardHeaderWhite= ({children,  ...rest }) => {
  const theme = useTheme();
  const {  tenant } = useAuth();
  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    HeaderCard:{

      borderTop: '2px solid '+theme.palette.primary.main,
      boxShadow: '0px 5px 8px 1px #0000003d',
    },
    headerColor:{
      background: 'linear-gradient(to right, '+theme.palette.primary.main+' 0%, '+theme.palette.primary.dark+' 100%)',
    },
    headerColorBlank:{
      background:"#ffffff",
    },
  }),
  );
   const classes = useStyles();




  return (
    <CardHeaderDivBody className={classNames(classes.HeaderCard,(tenant && tenant?.appBarPrimaryColor?classes.headerColor:classes.headerColorBlank))}  {...rest}  >
      {children}
    </CardHeaderDivBody>
  );
}

export default CardHeaderWhite;

