import React, { useCallback, useEffect, useState, useMemo } from 'react';
import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';
import classNames from "classnames";
import { useAuth } from '../../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Button, Grid, List, ListItem, ListItemIcon, Checkbox, ListItemText, ListItemSecondaryAction, TableHead, TableRow, TableCell, TableSortLabel, lighten, Tooltip, Paper, TableContainer, Table, TableBody, TablePagination, FormControlLabel, Switch, CircularProgress, CardContent, Card, Tab, Tabs, useTheme, Box, Breadcrumbs, Link, Chip } from "@material-ui/core";
import { Assignment, PersonPinCircle, MonetizationOn, AccountBox, PowerSettingsNew, Event, Delete, FilterList, ArrowForwardIos, MoneyOff, History as HistoryIcon, SentimentVerySatisfied, ArrowLeft } from '@material-ui/icons';
import GridContainer from '../../../components/Grid/GridContainer'
import GridItem from '../../../components/Grid/GridItem'
import MenuIcon from "@material-ui/icons/Menu";
import profile from "../../assets/img/christian.jpg";
import Header from '../../../components/Header';
import api from '../../../services/api';
import { format, parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';
import { StringDecoder } from 'string_decoder';
import { Pagination } from '@material-ui/lab';
import { useToast } from '../../../hooks/toast';
import { green, orange, blue } from '@material-ui/core/colors';
import Content from '../../../components/Content';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',

      backgroundColor: theme.palette.background.paper,
    },

    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: '26px',
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    Pagination: {
      margin: theme.spacing(3),
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    GridList: {
      margin: '20px 0',
    },
    iconSmile: {
      fontSize: '5.1875rem',
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",
    },
    statusVencido: {
      backgroundColor: orange[500],
      color: '#fff',
      '&:hover': {
        backgroundColor: orange[800],
      }
    },
    statusAberto: {
      backgroundColor: blue[500],
      color: '#fff',
      '&:hover': {
        backgroundColor: blue[800],
      }
    }
  },

  ),
);

interface IPaymentsPending {
  Id: string;
  Data: Date;
  Vencimento: Date;
  VencimentoSerialized: string;
  Valor: number;
  JurosMulta: number;
  ValorTotal: number;
  Descricao: string;
  Status: string;
  FormaPagamento: string;
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof IPaymentsPending;
  label: string;
  numeric: boolean;
}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
type Order = 'asc' | 'desc';
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: HeadCell[] = [
  // { id: 'Id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'Valor', numeric: true, disablePadding: false, label: 'Valor' },
  { id: 'JurosMulta', numeric: true, disablePadding: false, label: 'Juros/Multa' },
  { id: 'Vencimento', numeric: false, disablePadding: false, label: 'Vencimento' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'Descricao', numeric: false, disablePadding: false, label: 'Descrição' },
  { id: 'ValorTotal', numeric: true, disablePadding: false, label: 'Valor Total' },

];

interface EnhancedTableToolbarProps {
  numSelected: number;
}


interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IPaymentsPending) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof IPaymentsPending) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    textButton: {
      marginRight: '7px',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    titleDiv: {
      textAlign: 'center',
    },

  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  valorTotal: number;
  selectedList: IRowSelected[];
  qtdTotal: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { tenant } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const { numSelected, valorTotal, selectedList, qtdTotal } = props;

  useEffect(() => {
    if (tenant?.permissions.payments == false) {
      history.replace("/");
    }
  }, [])

  const handleClickPay = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.post('/payments/pending/list', selectedList.map((val) => val.id));
      if (response.data) {
        sessionStorage.setItem('@HubfitPortal:invoice', JSON.stringify(response.data));
        sessionStorage.setItem('@HubfitPortal:action', "invoice_payment");
        history.push('/payments/checkout');

      } else {
        addToast({
          type: "error",
          title: 'Houve um erro ao buscas as informações',
        });
      }
    } catch (err) {
      addToast({
        type: "error",
        title: 'Houve um erro ao buscas as informações',
      });
    } finally {
      setLoading(false);
    }
  }, [selectedList])


  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Grid container spacing={1}
        direction="row"
        justify="space-between"
        alignItems="center" >


        {numSelected > 0 &&
          <Grid item xs={12} sm={12} md={9}  >
            <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
              {numSelected} selecionado
            </Typography>
          </Grid>
        }


        {numSelected == 0 &&
          <Grid item xs={12}  >
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              {qtdTotal > 1 && `Você tem ${qtdTotal} pendências`}
              {qtdTotal == 1 && `Você tem ${qtdTotal} pendência`}
              {qtdTotal == 0 &&
                <Typography align="center" variant="h6">Parabéns, nenhuma pendência financeira.</Typography>
              }
            </Typography>
          </Grid>
        }


        {numSelected > 0 && (
          <Grid item xs={12} sm={12} md={3} alignItems="flex-end" >
            <Button type="button" disabled={loading} onClick={handleClickPay} variant="contained" color="primary" endIcon={<ArrowForwardIos />}>
              <span className={classes.textButton}>Pagar</span> <NumberFormat value={valorTotal} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />
              {loading &&
                <CircularProgress size={24} className={classes.buttonProgress} />
              }
            </Button>
          </Grid>
        )}

      </Grid>
    </Toolbar>
  );
};

interface IRowSelected {
  id: string;
  valor: number;
}


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const PaymentsPending: React.FC = () => {
  const classes = useStyles();
  const { tenant, user } = useAuth();
  const history = useHistory();
  const [payments, setPayments] = useState<IPaymentsPending[]>([]);
  const theme = useTheme();

  const [order, setOrder] = React.useState<Order>('asc');
  const [loadingData, setLoadingData] = React.useState(true);
  const [orderBy, setOrderBy] = React.useState<keyof IPaymentsPending>('Vencimento');
  const [selected, setSelected] = React.useState<IRowSelected[]>([]);
  const [page, setPage] = React.useState(0);
  const [valueTab, setValueTab] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { addToast } = useToast();
  const paymentsSerialized = useMemo(() => {

    return payments?.map(pay => {
      const paySer: IPaymentsPending = {
        Data: pay.Data,
        ValorTotal: pay.ValorTotal,
        JurosMulta: pay.JurosMulta,
        Descricao: pay.Descricao,
        Id: pay.Id,
        Valor: pay.Valor,
        Vencimento: pay.Vencimento,
        Status: pay.Status,
        VencimentoSerialized: format(parseISO(pay.Vencimento.toLocaleString()), 'dd/MM/yyyy'),
        FormaPagamento: pay.FormaPagamento
      }
      return paySer;
    })
  }, [payments]);

  useEffect(() => {
    setLoadingData(true);
    sessionStorage.removeItem('@HubfitPortal:plan');
    sessionStorage.removeItem('@HubfitPortal:action');
    sessionStorage.removeItem('@HubfitPortal:payment');
    api.get('/payments/pending', {
      params: {
        idbranch: user.idBranch
      }
    }).then((response) => {
      console.log(response.data)
      setLoadingData(false);
      setPayments(response.data);
    }).catch((err) => {
      setLoadingData(false);
      console.log(err)
      addToast({
        type: "error",
        title: 'Houve um erro ao buscas as informações',
      });

    })
  }, [tenant]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IPaymentsPending) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (payments) {
        const newSelecteds = payments.filter(d => d.FormaPagamento != "Boleto").map((n) => {
          const rowS: IRowSelected = {
            id: n.Id,
            valor: n.ValorTotal
          };
          return rowS;
        });
        setSelected(newSelecteds);
      }
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: IRowSelected) => {
    const selectedIndex = selected.map((v) => v.id).indexOf(name.id);
    let newSelected: IRowSelected[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValueTab(index);
  };
  const isSelected = (name: IRowSelected) => selected.map((val) => val.id).indexOf(name.id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, (payments ? payments.length : 0) - page * rowsPerPage);

  const handleClickReturn = useCallback(() => {
    history.push("/");
  }, [history]);





  return (
    <>
      <Header removeBack avatar tabIndex={1} />
      <div className={classNames(classes.mainRaised)}>
        <div>
          <Content>
            <Grid container spacing={3}
              direction="row"
              justify="center"
              alignItems="center" >

              <Grid item xs={12} sm={12} md={10}  >
                <Breadcrumbs id="titleBreadcrums" aria-label="breadcrumb">
                  <Link color="inherit" href={`/${tenant.domain}`}>
                    Portal
                  </Link>

                  <Typography variant="h6" color="primary"  >Financeiro</Typography>
                </Breadcrumbs>
                <Paper square className={classes.root}>
                  <Tabs
                    value={valueTab}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"

                  >
                    <Tab icon={<MoneyOff />} label="PENDÊNCIAS" />
                    {/* <Tab icon={<HistoryIcon />} label="HISTÓRICO" /> */}
                  </Tabs>
                </Paper>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.GridList}
              justify="center"
              alignItems="center" >

              <Grid item xs={12} md={10} lg={8} >
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    {!loadingData &&
                      <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={valueTab}
                        onChangeIndex={handleChangeIndex}
                      >

                        <>
                          <TabPanel value={valueTab} index={0} dir={theme.direction}>
                            <EnhancedTableToolbar qtdTotal={payments ? payments.length : 0} selectedList={selected} numSelected={selected.length} valorTotal={selected && selected.length > 0 ? selected.map((val) => val.valor).reduce((sum, current) => sum + current) : 0} />
                            {payments?.length > 0 &&
                              <>

                                <TableContainer>
                                  <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                    aria-label="enhanced table"
                                  >
                                    <EnhancedTableHead
                                      classes={classes}
                                      numSelected={selected.length}
                                      order={order}
                                      orderBy={orderBy}
                                      onSelectAllClick={handleSelectAllClick}
                                      onRequestSort={handleRequestSort}
                                      rowCount={payments.length}
                                    />
                                    <TableBody>
                                      {stableSort(paymentsSerialized, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                          const isItemSelected = isSelected({ id: row.Id, valor: row.ValorTotal });
                                          const labelId = `enhanced-table-checkbox-${index}`;

                                          return (
                                            <TableRow
                                              hover
                                              onClick={(event) => row.FormaPagamento != "Boleto"? handleClick(event, { id: row.Id, valor: row.ValorTotal }):undefined }
                                              role="checkbox"
                                              aria-checked={isItemSelected}
                                              tabIndex={-1}
                                              key={row.Id}
                                              selected={isItemSelected}
                                              
                                            >
                                              <TableCell padding="checkbox">
                                                
                                                  <Checkbox
                                                  disabled={row.FormaPagamento == "Boleto"}
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                  />
                                                
                                              </TableCell>
                                              <TableCell align="right">
                                                <NumberFormat value={row.Valor} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />
                                              </TableCell>
                                              <TableCell align="right">
                                                {row.JurosMulta > 0 &&
                                                  <NumberFormat value={row.JurosMulta} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />
                                                }
                                              </TableCell>
                                              <TableCell >{row.VencimentoSerialized}</TableCell>
                                              <TableCell >
                                                {(row.Status == "Vencido" || row.Status == "Vence hoje") &&
                                                  <Chip size="small" label={row.Status} className={classes.statusVencido} />
                                                }
                                                {row.Status == "Em aberto" &&
                                                  <Chip size="small" label={row.Status} className={classes.statusAberto} />
                                                }
                                              </TableCell>
                                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.Descricao}
                                              </TableCell>



                                              <TableCell align="right">
                                                <b><NumberFormat value={row.ValorTotal} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b>
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                          <TableCell colSpan={6} />
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <Pagination className={classes.Pagination} count={payments ? payments.length / rowsPerPage : 0} color="primary" onChange={handleChangePage} />
                              </>
                            }
                            {payments?.length == 0 &&
                              <Grid container direction="column" className={classes.GridList}
                                justify="center"
                                alignItems="center" >
                                <Grid item xs={12}>
                                  <SentimentVerySatisfied className={classes.iconSmile} />
                                </Grid>
                                <Button onClick={handleClickReturn}>
                                  <ArrowLeft />
                                  Voltar
                                </Button>
                              </Grid>
                            }
                          </TabPanel>
                          <TabPanel value={valueTab} index={1} dir={theme.direction}>
                            <h2>Segunda pagina</h2>
                          </TabPanel>
                        </>
                      </SwipeableViews>
                    }
                    {loadingData &&
                      <Grid container direction="column" className={classes.GridList}
                        justify="center"
                        alignItems="center" >
                        <Grid item xs={12} >
                          <CircularProgress size={40} />
                        </Grid>
                      </Grid>
                    }
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Content>
        </div>
      </div>
    </>
  )

}



export default PaymentsPending;
