import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { format, isToday } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css'
import classNames from "classnames";
import { useAuth } from '../../hooks/auth';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import {
  Schedule, NextAppointment, Calendar, Section, Appointment, ContentModal, CalendarModal
} from './styles';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import {
  Typography, Button, Grid, Divider, List as ListComponent,
  DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions, Dialog, InputAdornment, CircularProgress, Input, FormControl, InputLabel, FormHelperText, CardMedia, CardContent, Card, Chip, Paper, Fab, IconButton, Select, MenuItem, ListItem, ListItemIcon, ListItemText, Breadcrumbs, Link, Tooltip
} from "@material-ui/core";
import api from '../../services/api';

import { useToast } from '../../hooks/toast';
import Header from '../../components/Header';
import Timer from '../../components/TimerCountdown';
import { parseISO } from 'date-fns';
import { QueryBuilder, Today, Add as AddIcon, Close as CloseIcon, ArrowLeft, ArrowRight, MenuBook, Check, HourglassEmpty } from '@material-ui/icons';
import { IAppointmentsMonth, IAppointment, IAppointmentDays } from '../../models/Appointment/IAppointmentDTO';
import { inherits } from 'util';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { CardTextHeader } from '../Login/SignIn/styles';
import CardFooter from '../../components/Card/CardFooter';
import './carrousel.css';
import { green } from '@material-ui/core/colors';
import { userInfo } from 'os';
import Content from '../../components/Content';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });


export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    vagas: {
      borderRadius: '23px',
      height: '48px',
      fontSize: '32px',

    },
    divVagas: {
      display: 'flex',
      alignItems: 'center',
      width: '100px',
      flexDirection: 'column',
      alignSelf: 'center',
      marginTop: '-16px',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 200,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    titleClass: {
      margin: '2px 5px 8px 14px !important',
    },
    titleClassCanceled: {
      margin: '2px 5px 8px 14px !important',
      color: '#b5b5b5',
      textDecoration: 'line-through',
    },
    timeClass: {
      margin: '0 !important',
      color: theme.palette.text.secondary,
    },
    timeRest: {
      color: theme.palette.text.disabled,
      marginLeft: '10px',
      fontSize: '14px',
    },
    divFlexTime: {
      display: 'inline-flex',
      alignItems: 'flex-end'

    },
    textVagas: {
      color: theme.palette.text.secondary,
    },
    hourTimeAppointments: {
      flexDirection: 'column',
      color: theme.palette.text.primary,
    },
    dayPicker: {

      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',

      '& .DayPicker-Day--available:not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.main,
        borderRadius: '10px',
        color: '#fff',
      },
      '& .DayPicker-Day--available:not(.DayPicker-Day--disabled):hover:not(.DayPicker-Day--outside):hover': {
        background: theme.palette.primary.dark + ' !important',

      },
      '& .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.dark + ' !important',
        borderRadius: '10px',
        color: theme.palette.primary.contrastText,
      },
      '& .DayPicker-Day--today.DayPicker-Day--disabled': {
        background: '#ccc !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--disabled': {
        background: 'transparent',
      },

    },
    dayPickerModal: {

      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',

      '& .DayPicker-Day--available:not(.DayPicker-Day--outside)': {
        background: 'rgb(130 130 130)',
        borderRadius: '10px',
        color: '#fff',
      },
      '& .DayPicker-Day--available:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover': {
        background: theme.palette.primary.main + ' !important',

      },
      '& .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.main + ' !important',
        borderRadius: '10px',
        color: theme.palette.primary.contrastText,
      },
      '& .DayPicker-Day--today.DayPicker-Day--disabled': {
        background: '#ccc !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--disabled': {
        background: 'transparent',
      },
      '& .DayPicker-Day--available.DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
        background: theme.palette.secondary.main + ' !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--selected:hover:not(.DayPicker-Day--outside):hover': {
        background: theme.palette.secondary.dark + ' !important',
      },

    },
    gridItem: {
      padding: '16px',
    },
    gridItemModal: {
      padding: '28px',
    },
    paperAppointments: {
      padding: '23px',
    },
    serviceItem: {
      backgroundColor: theme.palette.primary.main,
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    margin: {

    },
    RingOff: {
      'outline-style': 'inherit',
    },
    SpanCard: {
      color: 'rgba(0,0,0,0.5)',
      display: 'flex',
      alignItems: 'center',

    },
    wrapper: {
      margin: '60px 0 0',
      width: '100%',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    formControl: {

      minWidth: 266,
    },
    divBack: {
      marginTop: '10px',
    },
    divFoward: {
      marginTop: '10px',
      flexFlow: 'row-reverse',
      flex: '1',
      display: 'flex',
    },
    listHours: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    titleHours: {
      margin: '18px 6px',
      textAlign: 'center',
    },
    chipHour: {
      fontSize: '1rem',
    },
    divCenter: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    GridList: {
      margin: '20px 0',
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",
    },
    cardAppointment: {
      borderLeft: "3px solid" + theme.palette.primary.main,
    }
  }),
);
interface PresencaItem {
  Id: number;
  Nome: string;
  Tipo: number;
  Identificador: string;
}

interface IServices {
  id: number;
  name: string;
}
interface IAppointmentRouteData extends RouteComponentProps<
  { myParamProp?: string }, // this.props.match.params.myParamProp
  any, // history
  { myStateProp?: string } // this.props.location.state.myStateProp
> {
  agendar?: string,
}


const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);



const Appointments: React.FC = () => {
  const classes = useStyles();
  const { tenant, user, signOut } = useAuth();
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingAppointments, setLoadingAppointments] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedNewDate, setSelectedNewDate] = useState<Date>();
  const [currentNewMonth, setCurrentNewMonth] = useState<Date>(new Date());
  const [selectedHour, setSelectedHour] = useState();
  const [hoursAvailability, setHoursAvailability] = useState<string[] | undefined>(undefined)
  const [appointments, setAppointments] = useState<IAppointment[]>();
  const [services, setServices] = useState<IServices[]>();
  const [serviceSelected, setServiceSelected] = useState<IServices | undefined>();
  const [appointment_days, setAppointment_days] = useState<IAppointmentDays[]>([]);
  const [appointment_available, setAppointment_available] = useState<IAppointmentDays[]>([]);
  const [tab, setTab] = React.useState(0);
  const [openService, setOpenService] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const { addToast } = useToast();
  const history = useHistory<IAppointmentRouteData>();

  useEffect(() => {
    if (tenant?.permissions.appointments == false) {
      history.replace("/");
    }
    if (history.location.state?.agendar) {
      handleOpenModal();
    }
  }, []);

  useEffect(() => {

    if (openModal == false) {
      setLoadingAppointments(true);
      try {
        api.get<IAppointmentsMonth>('/appointments', {
          params: {
            year: currentMonth.getFullYear(),
            month: currentMonth.getMonth() + 1,
          }
        }).then(response => {
          console.log(response)
          setLoadingAppointments(false);
          const appointmentsFormatted = response.data.appointments.map(appointment => {
            return {
              ...appointment,
              hourFormatted: format(parseISO(appointment.date_time), 'HH:mm'),
            }
          })
          setAppointments(appointmentsFormatted);
          setAppointment_days(response.data.appointment_days);
        }).catch(err => {
          console.log(err)
          if (err.response.status == 401) {
            addToast({
              type: "error",
              title: 'Sessão encerrada',
              description: 'Sua sessão expirou',
            });
            signOut();

          } else {
            addToast({
              type: "error",
              title: 'Erro',
              description: 'Erro na conexão.',
            });
          }
          setLoadingAppointments(false);
          addToast({
            type: "error",
            title: 'Erro',
            description: 'Erro na conexão.',
          });
        })
      } catch (err) {
        console.log(err)
        setLoadingAppointments(false);
        addToast({
          type: "error",
          title: 'Erro',
          description: 'Erro na conexão.',
        });
      }
    }
  }, [currentMonth, openModal]);

  useEffect(() => {
    if (currentNewMonth && serviceSelected) {
      api.get("services/month-availability", {
        params: {
          year: currentNewMonth.getFullYear(),
          month: currentNewMonth.getMonth() + 1,
          idservice: serviceSelected.id
        }
      }).then((response) => {
        setAppointment_available(response.data);
      }).catch(err => {
        if (err.response.status == 401) {
          addToast({
            type: "error",
            title: 'Sessão encerrada',
            description: 'Sua sessão expirou',
          });
          signOut();

        } else {
          addToast({
            type: "error",
            title: 'Erro',
            description: 'Erro na conexão.',
          });
        }
      })
    }
  }, [currentNewMonth, serviceSelected]);



  const disabledNewDays = useMemo(() => {
    if (currentNewMonth) {
      console.log(appointment_available)
      const dates = appointment_available.filter(monthDay =>
        monthDay.available == false).map(monthDay => {
          const year = currentNewMonth.getFullYear();
          const month = currentNewMonth.getMonth();
          return new Date(year, month, monthDay.day);
        })
      return dates;
    }
  }, [currentNewMonth, appointment_available]);

  const disabledDays = useMemo(() => {
    const dates = appointment_days.filter(monthDay =>
      monthDay.available == false).map(monthDay => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();
        return new Date(year, month, monthDay.day);
      })
    return dates;
  }, [currentMonth, appointment_days]);

  // const handleDateChange = useCallback((day: Date, modifier: DayModifiers) => {
  //   if (modifier.available && !modifier.disabled) {
  //     setSelectedDate(day);
  //   }
  // }, [])

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
    console.log(format(month, 'dd/MM/yyyy'))
  }, []);
  const handleMonthNewChange = useCallback((month: Date) => {
    setCurrentNewMonth(month);
  }, []);
  useEffect(() => {
    if (serviceSelected && selectedNewDate) {
      api.get("services/hour-availability", {
        params: {
          date: format(selectedNewDate, 'yyyy/MM/dd'),
          idservice: serviceSelected.id
        }
      }).then((response) => {
        setHoursAvailability(response.data);
      }).catch(err => {
        if (err.response.status == 401) {
          addToast({
            type: "error",
            title: 'Sessão encerrada',
            description: 'Sua sessão expirou',
          });
          signOut();

        } else {
          addToast({
            type: "error",
            title: 'Erro',
            description: 'Erro na conexão.',
          });
        }
      })

    }
  }, [serviceSelected, selectedNewDate]);
  
  const handleDateNewChange = useCallback(async (day: Date, modifier: DayModifiers) => {
    if (modifier.available && !modifier.disabled && serviceSelected) {
      setSelectedNewDate(day);
    }
  }, [serviceSelected])
  const handleOpenModal = useCallback(async () => {
    setOpenModal(true);
    try {
      setLoadingServices(true);
      var response = await api.get('services', {
        params: {
          idbranch: user.idBranch
        }
      });
      setServices(response.data);
      setOpenService(true);

    } catch (err) {
      addToast({
        type: "error",
        title: 'Erro',
        description: 'Erro na conexão.',
      });
    } finally {
      setLoadingServices(false);
    }
  }, [tenant]);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setTimeout(() => {
      setTab(0);
    }, 800)

  }, []);
  const handleChangeService = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    console.log(services?.filter((item) => item.id == event.target.value)[0])
    setServiceSelected(services?.filter((item) => item.id == event.target.value)[0]);
    setTab(1);
  }, [services])
  const handleServiceClose = useCallback(() => {
    setOpenService(false);

  }, [])
  const handleServiceOpen = useCallback(() => {
    setOpenService(true);
  }, []);
  const handleTabReturn = useCallback(() => {
    setTab(0);
  }, []);
  const handleTabReturnConfirm = useCallback(() => {
    setTab(1);
  }, []);
  const handleTabNext = useCallback(() => {
    setTab(1);
  }, []);
  const handleHourClick = useCallback((item) => {
    setSelectedHour(item);
    setTab(2);
  }, [])
  const handleClickSaveAppointment = useCallback(async () => {
    setLoadingSave(true);
    if (serviceSelected && selectedNewDate && selectedHour) {
      try {
        const response = await api.post('appointments', {
          idservice: serviceSelected.id,
          date: format(selectedNewDate, 'yyyy/MM/dd'),
          hour: selectedHour,
        });
        if (response.data.ok) {
          addToast({
            type: "success",
            title: 'Agendamento Salvo',
            description: 'Seu agendamento foi realizado com sucesso!',
          });
          setOpenModal(false);
        }
      } catch (err) {

        addToast({
          type: "error",
          title: 'Erro',
          description: 'Erro na conexão. Não foi possível agendar',
        });
      } finally {
        setLoadingSave(false);
      }
    }
  }, [serviceSelected, selectedNewDate, selectedHour])
  return (
    <>
      <Header removeBack avatar={true} tabIndex={4} />
      <div className={classNames(classes.mainRaised)}>
        <div>
          <Content>
            <Grid container spacing={3} direction="row"
              justify="center"
              alignItems="center" >
              <Grid item xs={12} sm={12} md={10}  >

                <Breadcrumbs id="titleBreadcrums" aria-label="breadcrumb">
                  <Link color="inherit" href={`/${tenant.domain}`}>
                    Portal
                  </Link>

                  <Typography variant="h6" color="primary"  >Agendamentos</Typography>
                </Breadcrumbs>
                <Grid container spacing={3} direction="row"
                  justify="center"
                >
                  <Grid item xs={12} sm={12} md={8}  >





                    <Grid container direction="row"
                      justify="center"
                      alignItems="flex-start" >
                      <Grid item sm={12} md={9} >
                        <h2>{format(currentMonth, 'MMMM - yyyy', { locale: ptBR })}</h2>
                      </Grid>
                     
                        <Grid item sm={12} md={3} >
                        {tenant.domain != "perforce" &&
                          <Fab size="medium" variant="extended" color="secondary" aria-label="add" onClick={handleOpenModal}>
                            <AddIcon className={classes.extendedIcon} />
                            Agendar
                          </Fab>
                              }
                        </Grid>
                  

                    </Grid>

                    {loadingAppointments &&

                      <Grid container direction="column" className={classes.GridList}
                        justify="center"
                        alignItems="center" >
                        <Grid item xs={12} >
                          <CircularProgress size={40} />
                        </Grid>
                      </Grid>

                    }
                    {!loadingAppointments &&
                      <Section>

                        {appointments?.length == 0 && (
                          <p>Nenhum agendamento neste mês</p>
                        )}
                        {appointments && appointments?.length > 0 && (
                          <Grid container spacing={1} direction="row"
                            justify="flex-start"
                            alignItems="stretch" >
                            {appointments?.map(appointment => (
                              <Grid item xs={12} key={appointment.id} >
                                <Card key={appointment.id} className={classes.cardAppointment}  >
                                  <CardContent className={classes.content}>
                                    <Grid container spacing={1} direction="row"
                                      justify="flex-start"
                                      alignItems="center"
                                      className={appointment.status == 5?"cancelado":""}
                                      >
                                      <Grid container xs={12} direction="row"  justify="flex-start">
                                        <Typography color="primary" variant="h6" className={appointment.status == 5?classes.titleClassCanceled:classes.titleClass}>{appointment.service}</Typography>
                                        {appointment.status == 5 &&
                                        <Typography color="error" variant="h6" className={classes.titleClass}>Cancelado</Typography>
}
                                      </Grid>
                                      <Grid item xs={12} >
                                        <Grid container spacing={1} direction="row"
                                          justify="flex-start"
                                          alignItems="center" >
                                          <Tooltip title="Data do agendamento">
                                            <Grid item  >
                                              <Chip color="primary" label={format(parseISO(appointment.date_time), 'dd/MMM', { locale: ptBR })} icon={<Today />} />
                                            </Grid>
                                          </Tooltip>
                                          <Tooltip title="Horário de início">
                                            <Grid item  >
                                              <Chip color="primary" label={`${appointment.hour_formated}h`} icon={<QueryBuilder />} />
                                            </Grid>
                                          </Tooltip>
                                          <Tooltip title="Duração">
                                            <Grid item  >
                                              <Chip variant="outlined" label={`${appointment.duration}`} icon={<HourglassEmpty />} />
                                            </Grid>
                                          </Tooltip>
                                        </Grid>
                                      </Grid>


                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                              //   <Appointment key={appointment.id}>
                              //     <span className={classes.hourTimeAppointments}>
                              //       <span>
                              //         <Today />
                              //         {format(parseISO(appointment.date_time), 'dd/MMM', { locale: ptBR })}
                              //       </span>
                              //       <span>
                              //         <QueryBuilder /> {appointment.hour_formated}h
                              // </span>
                              //     </span>
                              //     <div className={classes.serviceItem}>
                              //       <strong >{appointment.service}</strong>
                              //     </div>
                              //   </Appointment>
                            ))}

                          </Grid>
                        )}
                      </Section>

                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}  >
                    <Calendar>
                      <DayPicker className={classes.dayPicker}
                        weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S',]}
                        fromMonth={new Date()}
                        disabledDays={disabledDays}
                        // selectedDays={selectedDate}
                        onMonthChange={handleMonthChange}
                        modifiers={
                          { available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] } }
                        }
                        // onDayClick={handleDateChange}
                        months={[
                          'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
                        ]}
                      />
                    </Calendar>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Content>
        </div>
      </div>
      <Dialog maxWidth="sm" fullWidth onClose={handleCloseModal} aria-describedby="form-dialog-title" open={openModal}>

        <DialogTitle id="form-dialog-title" onClose={handleCloseModal}>
          <Typography variant="h6">Novo agendamento
            {serviceSelected &&
              <span>{` de ${serviceSelected.name}`}</span>
            }
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ContentModal>

          <CarouselProvider
            disableKeyboard
            currentSlide={tab}
            touchEnabled={false}
            dragEnabled={false}
            isIntrinsicHeight={true}
            naturalSlideWidth={300}
            naturalSlideHeight={400}

            totalSlides={3}
          >
            <Slider >
              <Slide index={0} >
                <div className={classes.divFoward}>

                  <Button disabled={!serviceSelected} onClick={handleTabNext} >
                    Próximo
                    <ArrowRight />
                  </Button>
                </div>
                <div className={classes.divCenter}>

                  <CardTextHeader>
                    <Typography variant="h4" color="primary">Selecione o serviço</Typography>
                  </CardTextHeader>

                  {loadingServices &&

                    <Grid container direction="column" className={classes.GridList}
                      justify="center"
                      alignItems="center" >
                      <Grid item xs={12} >
                        <CircularProgress size={26} />
                      </Grid>
                      Buscando serviços disponíveis
                    </Grid>
                  }
                  {!loadingServices &&
                    <FormControl className={classes.formControl}>
                      <InputLabel id="servico">Serviço</InputLabel>


                      <Select
                        labelId="servico"
                        id="servico"
                        open={openService}
                        onClose={handleServiceClose}
                        onOpen={handleServiceOpen}
                        value={serviceSelected}
                        onChange={handleChangeService}
                      >
                        {services?.map((item) => (
                          <MenuItem value={item.id} >{item.name}</MenuItem>
                        ))}

                      </Select>
                    </FormControl>
                  }

                </div>

              </Slide>
              <Slide innerClassName={classes.RingOff} index={1} >
                <div className={classes.divBack}>
                  <Button onClick={handleTabReturn}>
                    <ArrowLeft />
                    Voltar
                  </Button>

                </div>
                <div className={classes.divCenter}>
                  <CardTextHeader>
                    <Typography variant="h4" color="primary">Selecione o dia e horário disponível</Typography>

                  </CardTextHeader>
                  {tab == 1 &&
                    <>
                      <CalendarModal>
                        <DayPicker className={classes.dayPickerModal}
                          weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S',]}
                          fromMonth={new Date()}
                          disabledDays={disabledNewDays}
                          selectedDays={selectedNewDate}
                          onMonthChange={handleMonthNewChange}
                          modifiers={
                            { available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] } }
                          }
                          onDayClick={handleDateNewChange}
                          months={[
                            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
                          ]}
                        />
                      </CalendarModal>
                      {hoursAvailability && hoursAvailability.length > 0 && selectedNewDate &&
                        <>
                          <h3 className={classes.titleHours}>Horários disponíveis para {format(selectedNewDate, 'dd/MMM', { locale: ptBR })}</h3>
                          <div className={classes.listHours}>

                            {hoursAvailability?.map((item) => (
                              <Chip className={classes.chipHour} clickable onClick={() => handleHourClick(item)} key={item} color="primary" label={`${item}h`} />
                            ))}
                          </div>
                        </>
                      }
                      {hoursAvailability && hoursAvailability.length == 0 && selectedNewDate &&
                        <div className={classes.listHours}>
                          <h3 className={classes.titleHours}>Nenhum horário disponível para {format(selectedNewDate, 'dd/MMM', { locale: ptBR })}</h3>
                        </div>
                      }
                    </>

                  }
                </div>


              </Slide>
              <Slide innerClassName={classes.RingOff} index={2} >
                <div className={classes.divBack}>
                  <Button onClick={handleTabReturnConfirm}>
                    <ArrowLeft />
                    Voltar
                  </Button>

                </div>
                <div className={classes.divCenter}>

                  <ListComponent component="nav" aria-label="main mailbox folders">
                    <ListItem button>
                      <ListItemIcon>
                        <MenuBook color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={serviceSelected?.name} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <Today color="primary" />
                      </ListItemIcon>
                      {selectedNewDate &&
                        <ListItemText primary={format(selectedNewDate, "dd 'de' MMMM 'de' yyyy", { locale: ptBR })} />
                      }
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <QueryBuilder color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={`${selectedHour}h`} />
                    </ListItem>
                  </ListComponent>
                  <Button disabled={loadingSave} type="button" onClick={handleClickSaveAppointment} variant="contained" color="primary" size="large" endIcon={<Check />}>
                    Confirmar
                    {loadingSave && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>





                </div>

              </Slide>
            </Slider>

          </CarouselProvider>

        </ContentModal>
      </Dialog>
    </>
  )

}



export default Appointments;
