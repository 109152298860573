import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import queryString from 'query-string';
import Cards from 'react-credit-cards';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import 'react-credit-cards/es/styles-compiled.css';
import Recaptcha from 'react-recaptcha';

import ReactHtmlParser from 'react-html-parser';

import { Container, Content, AnimationContainer, Background } from './styles';
import { Input, Button, makeStyles, Theme, CircularProgress, Grid, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Typography, Paper, InputLabel, FormHelperText, Checkbox, Divider, TextField, Dialog, AppBar, Toolbar, IconButton, DialogContent, DialogContentText, MenuItem, Select, Tab, Tabs, useTheme, Box, BottomNavigation, BottomNavigationAction, NativeSelect, colors, DialogTitle, DialogActions } from '@material-ui/core';
import InputMask from "react-input-mask";
import { green, blue, grey } from '@material-ui/core/colors';
import { useAuth } from '../../../hooks/auth';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import Card from '../../../components/Card/Card';
import NumberFormat from 'react-number-format';
import { GridFooterCard } from '../../Plans/List/styles';
import { IPaymentSaleDTO, FormPaymentEnum, CreditCardDTO } from '../../../models/Payments/IPaymentSaleDTO';
import { CheckCircle, Close as CloseIcon, CreditCard, StayPrimaryLandscapeSharp } from '@material-ui/icons';
import Header from '../../../components/Header';
import { IPlanItem } from '../../../models/Plans/IPlanDTO';
import { User, TypeUser } from '../../../models/Tenant/UserDTO';
import { IPaymentPending } from '../../../models/Payments/IPaymentPendingDTO';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Helmet } from 'react-helmet';
import Logo from '../../../assets/img/pix-banco-central-logo.png'
import animationWaiting from '../../../assets/animations/waitinggreen.json';

import SwipeableViews from 'react-swipeable-views';
import DialogPlanDetails from '../../../components/DialogPlanDetails/Index';
import { grayscale } from 'polished';
import Lottie from 'react-lottie';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';

interface IParcelaItem {
  value: number;
  text: number
}

function BarCodeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2 6h2v12H2V6m3 0h1v12H5V6m2 0h3v12H7V6m4 0h1v12h-1V6m3 0h2v12h-2V6m3 0h3v12h-3V6m4 0h1v12h-1V6z" fill="#626262" />
    </SvgIcon>
  );
}


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



const useStyles = makeStyles((theme: Theme) => ({

  rootTab: {
    flexGrow: 1,
  },
  cardPadding: {
    padding: '10px',
  },
  titleCard: {
    marginTop: '20px',
  },
  wrapper: {

    margin: '60px 0 0',
    width: '100%',
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  totalValue: {
    fontSize: 17,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  SpanCard: {
    color: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',

  },
  IconInfo: {
    color: blue[500],
  },
  animationTab: {
    height: '100%',
  },
  Paper: {
    padding: '22px',
    marginBottom: '26px',
  },

  valuePlan: {
    marginTop: '2px !important',

  },
  IconCheck: {
    fontSize: '3.5875rem',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  buttonClose: {
    marginRight: 0,
  },
  ControlTerms: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

  },
  labelControlTerms: {
    marginRight: '6px',
  },
  textObs: {
    color: theme.palette.text.secondary,
    fontSize: '13px',
  },
  buttonContract: {
    padding: '3px 1px',
    marginTop: '2px',
    color: theme.palette.primary.main,
  },
  paperContract: {
    padding: '40px',
  },
  backgroundContract: {
    backgroundColor: theme.palette.grey[200],
  },
  GridList: {
    margin: '20px 0',
  }


}));




interface IPaymentFormData {
  name: string,
  number: string,
  expiry: string,
  cvc: string,
  acceptsTerms: boolean,
  parcelas: number,
}
export interface IPaymentVoucher {
  code: string,
  id: number,
  isConvenio: boolean,
  valorDescontoProduto: number,
  valorDescontoContrato: number,
  valorDescontoAdesao: number,
  valorDescontoCredito: number,
  valorDescontoContratoDCC: number;
  valorDescontoServico: number,
  qtdParcelasDesconto?: number,

}
interface IPayment {
  bandeira: string;
  numero: string;
  formaPagamento: FormPaymentEnum;
  parcelas: number;
  habilitaAgendamento: boolean;
  linkBoleto: string;
  qrcodePix: string;
  chavePix: string;
}


const Checkout: React.FC = () => {

  let timerId = 0;
  const classes = useStyles();
  const theme = useTheme();
  const { updateUser, tenant, user, token } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [formPayment, setFormPayment] = React.useState<FormPaymentEnum>(FormPaymentEnum.CartaoCredito);
  const [aguardandoPagamento, setAguardandoPagamento] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const [contract, setContract] = React.useState();
  const [tab, setTab] = React.useState(0);
  const [openModal, setOpenMoldal] = React.useState(false);
  const [openModalEmail, setOpenMoldalEmail] = React.useState(false);
  const [voucher, setVoucher] = React.useState<IPaymentVoucher>();
  const [parcelas, setParcelas] = React.useState<IParcelaItem[]>([]);
  const [defaultParcela, setDefaultParcela] = React.useState<number>(0);
  const [plan, setPlan] = React.useState<IPlanItem | undefined>();
  const [invoice, setInvoice] = React.useState<IPaymentPending | undefined>();
  const [payment_details, setPayment_Details] = React.useState<IPayment | undefined>();
  const [email, setEmail] = React.useState("");
  const refRecaptcha = useRef<Recaptcha | null>(null);
  const [isStopped, setIsStopped] = React.useState(true);
  const { addToast } = useToast();
  const history = useHistory();
  let location = useLocation();

  const defaultOptionsWaiting = {
    loop: true,
    autoplay: false,
    animationData: animationWaiting,
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,

  });

  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState();
  const [typeCard, setTypeCard] = useState<string>();
  const [openDetails, setOpenDetails] = React.useState(false);

  const numberRef = useRef<HTMLInputElement | null>(null);

  const recaptchaLoaded = useCallback(() => {
    console.log('recaptcha load')
  }, [])

  const recaptchaCallback = useCallback((response) => {
    sessionStorage.setItem('@HubfitPortal:recap', response);
    setDisabled(false);
    console.log(response)
  }, [])

  useEffect(() => {



    sessionStorage.removeItem('@HubfitPortal:errorPayment');
    sessionStorage.removeItem('@HubfitPortal:payment_details');
    sessionStorage.removeItem('@HubfitPortal:voucher');
    sessionStorage.removeItem('@HubfitPortal:responsePayment');
    const planString = sessionStorage.getItem('@HubfitPortal:plan');
    console.log("planString", planString)
    const invoiceString = sessionStorage.getItem('@HubfitPortal:invoice');
    const actionString = sessionStorage.getItem('@HubfitPortal:action');



    const domain = location.pathname.split('/')[1];
    console.log(location)
    if (domain != "a") {
      console.log("Teste",invoiceString , planString)
      if (!invoiceString && !planString) {
        history.push('/'); 
      }
      
      if (actionString == 'invoice_payment' && invoiceString) {
        const invoiceParsed = JSON.parse(invoiceString);
        console.log(invoiceParsed)
        setInvoice(invoiceParsed);
        let parcelasEdit: IParcelaItem[] = [];

        for (let i = 1; i <= invoiceParsed.MaxParcelas; i++) {
          parcelasEdit.push({ value: i, text: invoiceParsed.ValorTotal / i })
        }
        setParcelas(parcelasEdit);

        setDefaultParcela(invoiceParsed.MaxParcelas);
      } else if (actionString == 'plan_payment' && planString) {

        const planParsed = JSON.parse(planString) as IPlanItem;
        if (planParsed) {
          setPlan(planParsed);
          console.log(planParsed)
        }
        if (planParsed.payConfig.creditCard == false && planParsed.payConfig.pix == false && planParsed.payConfig.boleto == true) {
          setFormPayment(FormPaymentEnum.Boleto);
        }
        let voucher: IPaymentVoucher = {isConvenio: false, valorDescontoCredito: 0, valorDescontoProduto: 0, valorDescontoServico: 0, id: 0, code: "", valorDescontoContrato: 0, valorDescontoContratoDCC: 0, valorDescontoAdesao: 0 };

        api.get('/vouchers/plan/' + planParsed.id).then((response) => {
          voucher = response.data;
          sessionStorage.setItem('@HubfitPortal:voucher', JSON.stringify(response.data));
          setVoucher(response.data);
          let parcelasEdit: IParcelaItem[] = [];
          for (let i = 1; i <= planParsed.parcelas; i++) {
            parcelasEdit.push({
              value: i, text: (planParsed.dcc ? planParsed.valorDcc - (voucher && voucher.valorDescontoContrato > 0 ?
                voucher.qtdParcelasDesconto && voucher.qtdParcelasDesconto > 0 ? voucher.valorDescontoContratoDCC : voucher.valorDescontoContratoDCC
                : 0)
                : ((planParsed.valorContrato - (voucher ? voucher.valorDescontoContrato : 0)) +
                  (planParsed?.adesaoParcelar ? planParsed.adesaoValor - (voucher ? voucher.valorDescontoAdesao : 0) : 0) +
                  (planParsed?.servicoParcelar ? planParsed.servicoExtraValor - (voucher ? voucher.valorDescontoServico : 0) : 0) +
                  (planParsed.produtoValor - (voucher ? voucher.valorDescontoProduto : 0)) +
                  (planParsed.creditoValor - (voucher ? voucher.valorDescontoCredito : 0))
                ) / i)
            })
          }
          console.log("parcelas", parcelasEdit);
          setParcelas(parcelasEdit);
          setDefaultParcela(planParsed.parcelas);
        })
        console.log(voucher);



        api.get('/plans/limit', {
          params: {
            id: planParsed.id,
          }
        }).then((response) => {

          if (response.data.blocked === true) {
            addToast({
              type: "error",
              title: 'Plano limitado',
              description: "Você atingiu o limite máximo de compras do plano escolhido."
            });
            history.push("/plans/list")
          }

        }).catch((err) => {

        })

      }
    } else {


      if (domain == "a") {
        const parms = queryString.parse(location.search);

        if (parms.s && tenant) {
          //Pagamento de saldo devedor
          setLoadingData(true);
          api.get('/invoice', {
            params: {
              idPayment: parms.s,
              idTenant: tenant.idTenant
            }
          }).then((response) => {
            setInvoice(response.data);
            setLoadingData(false);
            sessionStorage.setItem('@HubfitPortal:action', "invoice_payment");
            sessionStorage.setItem('@HubfitPortal:invoice', JSON.stringify(response.data));

            if (response.data.Pago == true) {
              history.push("/payments/checkout/success")
            }

            let parcelasEdit: IParcelaItem[] = [];
            for (let i = 1; i <= response.data.MaxParcelas; i++) {
              parcelasEdit.push({ value: i, text: response.data.ValorTotal / i })
            }

            setParcelas(parcelasEdit);
            setDefaultParcela(response.data.MaxParcelas);
            const saldoParsed = response.data;
            console.log(response.data);
            if (saldoParsed.payConfig.creditCard == false && saldoParsed.payConfig.pix == false && saldoParsed.payConfig.boleto == true) {
              setFormPayment(FormPaymentEnum.Boleto);
            }
            if (saldoParsed.payConfig.creditCard == false && saldoParsed.payConfig.pix == true && saldoParsed.payConfig.boleto == false) {
              setFormPayment(FormPaymentEnum.Pix);
              if(saldoParsed?.ExigeEmailPix && !email){
                setOpenMoldalEmail(true);
                return;
              }
            }

          }).catch(err => {
            console.log(err.response.data.Message)
            history.push("/login")
            addToast({
              type: "error",
              title: 'Link indisponível',
              description: err.response.data.Message,
              time: 15000
            });
          })
        } else if (parms.p) {
          //Pagamento de link de plano
          setLoadingData(true);
          api.get('/plans', {
            params: {
              id: parms.p,
              idTenant: tenant.idTenant
            }
          }).then((response) => {
            setPlan(response.data);
            const planParsed = response.data;
            console.log(planParsed)
            if (planParsed.payConfig.creditCard == false && planParsed.payConfig.pix == false && planParsed.payConfig.boleto == true) {
              setFormPayment(FormPaymentEnum.Boleto);
            }
            setLoadingData(false);
            sessionStorage.setItem('@HubfitPortal:plan', JSON.stringify(response.data));
            sessionStorage.setItem('@HubfitPortal:action', "plan_payment");
            if (user) {
               api.defaults.headers.authorization = `Bearer ${token}`
                let voucher: IPaymentVoucher = {isConvenio: false,  valorDescontoCredito: 0, valorDescontoProduto: 0, valorDescontoServico: 0, id: 0, code: "", valorDescontoContrato: 0, valorDescontoContratoDCC: 0, valorDescontoAdesao: 0 };
              api.get('/vouchers/plan/' + planParsed.id).then((response) => {
                voucher = response.data;
                setVoucher(response.data);
                sessionStorage.setItem('@HubfitPortal:voucher', JSON.stringify(response.data));

                let parcelasEdit: IParcelaItem[] = [];
                for (let i = 1; i <= planParsed.parcelas; i++) {
                  parcelasEdit.push({
                    value: i, text: (planParsed.dcc ? planParsed.valorDcc - (voucher && voucher.valorDescontoContrato > 0 ?
                      voucher.qtdParcelasDesconto && voucher.qtdParcelasDesconto > 0 ? voucher.valorDescontoContratoDCC : voucher.valorDescontoContratoDCC
                      : 0)
                      : ((planParsed.valorContrato - (voucher ? voucher.valorDescontoContrato : 0)) +
                        (planParsed?.adesaoParcelar ? planParsed.adesaoValor - (voucher ? voucher.valorDescontoAdesao : 0) : 0) +
                        (planParsed?.servicoParcelar ? planParsed.servicoExtraValor - (voucher ? voucher.valorDescontoServico : 0) : 0) +
                        (planParsed.produtoValor - (voucher ? voucher.valorDescontoProduto : 0)) +
                        (planParsed.creditoValor - (voucher ? voucher.valorDescontoCredito : 0))
                      ) / i)
                  })
                }
                console.log("parcelas", parcelasEdit);
                setParcelas(parcelasEdit);
                setDefaultParcela(planParsed.parcelas);


             
                // let parcelasEdit: IParcelaItem[] = [];
                // for (let i = 1; i <= planParsed.parcelas; i++) {
                //   parcelasEdit.push({
                //     value: i, text: (planParsed.dcc ? planParsed.valorDcc - (voucher && voucher.valorDescontoContrato > 0 ?
                //       voucher.qtdParcelasDesconto && voucher.qtdParcelasDesconto > 0 ? voucher.valorDescontoContratoDCC : voucher.valorDescontoContratoDCC
                //       : 0)
                //       : ((planParsed.valorContrato - (voucher ? voucher.valorDescontoContrato : 0)) +
                //         (planParsed?.adesaoParcelar ? planParsed.adesaoValor - (voucher ? voucher.valorDescontoAdesao : 0) : 0) +
                //         (planParsed?.servicoParcelar ? planParsed.servicoExtraValor - (voucher ? voucher.valorDescontoServico : 0) : 0) +
                //         (planParsed?.produtoParcelar ? planParsed.produtoValor - (voucher ? voucher.valorDescontoProduto : 0) : 0) +
                //         (planParsed.creditoValor - (voucher ? voucher.valorDescontoCredito : 0))
                //       ) / i)
                //   })
                // }
                // console.log("parcelas", parcelasEdit);
                // setParcelas(parcelasEdit);
                // setDefaultParcela(planParsed.parcelas);
                

                
              //setPlan(response.data);
              // let parcelasEdit: IParcelaItem[] = [];
              // for (let i = 1; i <= response.data.parcelas; i++) {
              //   parcelasEdit.push({
              //     value: i, text: (planParsed.dcc ? planParsed.valorDcc - (voucher ? (voucher.valorDescontoContrato > 0 ?
              //       voucher.qtdParcelasDesconto && voucher.qtdParcelasDesconto > 0 ? voucher.valorDescontoContratoDCC : voucher.valorDescontoContratoDCC : 0) : 0)
              //       : ((planParsed.valorContrato- (voucher ? voucher.valorDescontoContrato : 0)) +
              //         (planParsed?.adesaoParcelar ? planParsed.adesaoValor - (voucher ? voucher.valorDescontoAdesao : 0) : 0) +
              //         (planParsed?.servicoParcelar ? planParsed.servicoExtraValor - (voucher ? voucher.valorDescontoServico : 0) : 0) +
              //         (planParsed?.produtoParcelar ? planParsed.produtoValor - (voucher ? voucher.valorDescontoProduto : 0) : 0) +
              //         (planParsed.creditoValor - (voucher ? voucher.valorDescontoCredito : 0))
              //       ) / i)
              //   })
              //   // parcelasEdit.push({
              //   //   value: i,
              //   //   text: (response.data.dcc ? response.data.valorDcc - (voucher ? voucher.valorDescontoContratoDCC : 0) : response.data.valorTotal - (voucher ? voucher.valorDescontoContrato : 0)) / i
              //   // })
              // }
              // // console.log("parcelas", response.data.parcelas, parcelasEdit);
              // setParcelas(parcelasEdit);
              // setDefaultParcela(response.data.parcelas);

              api.get('/plans/limit', {
                params: {
                  id: planParsed.id,
                }
              }).then((response) => {

                if (response.data.blocked === true) {
                  addToast({
                    type: "error",
                    title: 'Plano limitado',
                    description: "Você atingiu o limite máximo de compras do plano escolhido."
                  });
                  history.push("/plans/list")
                }

              }).catch(err => {
                addToast({
                  type: "error",
                  title: 'Erro',
                  description: 'Não foi possível se conectar ao servidor',
                });
              })

              })



            } else {
              addToast({
                type: "info",
                title: 'Login',
                description: "Faça login ou crie uma nova conta para acessar a tela de compras",
                time: 12000
              });
              history.push('/login')
            }
          }).catch(err => {

          })
        } else if (parms.r) {
          //Pagamento de renovação de plano
          if (user) {
            setLoadingData(true);
            api.get('/plans/renew', {
              params: {
                id: parms.r,
              }
            }).then((response) => {
              setPlan(response.data);
              setLoadingData(false);
              setPlan(response.data);
              let parcelasEdit: IParcelaItem[] = [];
              for (let i = 1; i <= response.data.parcelas; i++) {
                parcelasEdit.push({ value: i, text: response.data.valorTotal / i })
              }
              setParcelas(parcelasEdit);
              setDefaultParcela(response.data.parcelas);
            }).catch(err => {
              console.log(err.response.data.Message)
              addToast({
                type: "error",
                title: 'Não foi possível localizar o plano para renovação',
              });
              history.push('/');
            }).finally(() => {
              setLoadingData(false);
            })
          } else {
            addToast({
              type: "info",
              title: 'Login',
              description: "Faça login ou crie uma nova conta para acessar a tela de compras",
              time: 12000
            });
            history.push('/login?returnUrl=' + location.pathname + location.search)
          }
        }

      } else {
        sessionStorage.removeItem('@HubfitPortal:plan');
        sessionStorage.removeItem('@HubfitPortal:action');
        sessionStorage.removeItem('@HubfitPortal:invoice');
        sessionStorage.removeItem('@HubfitPortal:responsePayment');
        history.push('/');
      }


    }
    setTimeout(() => {
      numberRef.current?.focus();
    }, 1000)

    return () => {
      if (timerId && timerId > 0) {
        clearTimeout(timerId);
      }

    };

  }, [tenant, user, token])



  const handleChangeCard = useCallback((e) => {
    console.log(e.issuer)
    setTypeCard(e.issuer);
  }, [setTypeCard]);

  const handleChangeParcela = useCallback((e) => {
    setDefaultParcela(e.target.value);
  }, []);



  let RegisterPasswordSchema = Yup.object().shape({
    name: Yup.string().required('Insira o nome impresso no cartão'),
    number: Yup.string().required('Insira o número do cartão de crédito'),
    expiry: Yup.string().required('Insira a validade do cartão'),
    cvc: Yup.string().required('Insira o código de segurança'),
    acceptsTerms: Yup.boolean().oneOf([true], "Você deve aceitar os termos do contrato"),

  });


  //START PIX

  let RegisterPixSchema = Yup.object().shape({
    acceptsTerms: Yup.boolean().oneOf([true], "Você deve aceitar os termos do contrato"),
  });

  const { register: registerPix, handleSubmit: handleSubmitPix, control: controlPix, errors: errorsPix, setValue: setValuePix } = useForm<IPaymentFormData>({
    resolver: yupResolver(RegisterPixSchema),
    defaultValues: {
      parcelas: 1
    }
  });

  useEffect(() => {
    registerPix();
  }, [registerPix, defaultParcela])



  const onSubmitPix = useCallback(async () => {
    console.log(plan, invoice)
    if ((plan || invoice) && !loading) {
      try {
        setLoading(true);
        setDisabled(true);
        setSuccess(false);

        if(invoice?.ExigeEmailPix && !email){
          setOpenMoldalEmail(true);
          return;
        }


        const saleForm: IPaymentSaleDTO = {
          idPacote: plan ? plan.id : invoice ? invoice.Identificador : "",
          formaPagamento: formPayment,
          parcelas: defaultParcela,
          idTenant: tenant.idTenant,
          recaptcha: sessionStorage.getItem('@HubfitPortal:recap'),
          codeVoucher: voucher ? voucher.code : null,
          idVoucher: voucher?voucher.id: null,
          email: email
        };

      
        if (plan) {

          const response = plan.isRenovacao ? await api.post('/payments/renew/checkout', saleForm) : await api.post('/payments/sales/checkout', saleForm);
          console.log(response.data);
          setPayment_Details(response.data);
          if (response.data.ok == true) {

            if (response.data.idPessoa) {
              //Atualizar ID e Token do usuário que foi convertido
              const userLocal = localStorage.getItem('@HubfitPortal:user');
              if (userLocal) {
                const userObj: User = JSON.parse(userLocal);
                userObj.type = TypeUser.Aluno;
                userObj.id = response.data.idPessoa;
                updateUser(userObj, response.data.token);
              }

            }
            sessionStorage.setItem('@HubfitPortal:payment_details', JSON.stringify({
              formaPagamento: formPayment,
              parcelas: defaultParcela,
              qrcodePix: response.data.qrcodePix,
              chavePix: response.data.chavePix,
              idOrder: response.data.idOrder,
            }));
            if (response.data.habilitaAgendamento) {
              sessionStorage.setItem('@HubfitPortal:habilitaAgendamento', 'true');
            }

            sessionStorage.setItem('@HubfitPortal:responsePayment', JSON.stringify(response.data));
            setSuccess(true);
            setTimeout(() => {
              history.push('/payments/checkout/success');
            }, 1000);

          } else {
            setLoading(true);
            setDisabled(false);
            addToast({
              type: "error",
              title: 'Ops...',
              description: response.data.msg,
              time: 20000
            });
          }
        } else if (invoice) {
          const response = await api.post('/payments/pending/checkout', saleForm);
          console.log(response.data);
          setPayment_Details(response.data);
          sessionStorage.setItem('@HubfitPortal:payment_details', JSON.stringify({
            formaPagamento: formPayment,
            parcelas: defaultParcela,
            qrcodePix: response.data.qrcodePix,
            chavePix: response.data.chavePix,
            idOrder: response.data.idOrder,
          }));
          if (response.data.ok == true) {

            setSuccess(true);
            setTimeout(() => {
              history.push('/payments/checkout/success');
            }, 1000);
            // setAguardandoPagamento(true);
            // setIsStopped(false);
            // LoopStatus(response.data.idOrder);
        

          } else {
            setDisabled(false);
            sessionStorage.setItem('@HubfitPortal:errorPayment', response.data.msg);
            history.push('/payments/checkout/success');
          }
        }

      } catch (err) {
        setDisabled(false);
        addToast({
          type: "error",
          title: 'Erro no pagamento',
          description: "Não foi possível efetuar o pagamento",
          time: 20000
        });
      } finally {
        setLoading(false);
        refRecaptcha.current?.reset();
        setDisabled(false);
      }
    }
  }, [plan, history, invoice, formPayment, typeCard, defaultParcela, email]);

  //END PIX


  let RegisterBoletoSchema = Yup.object().shape({
    acceptsTerms: Yup.boolean().oneOf([true], "Você deve aceitar os termos do contrato"),
  });

  const { register: registerBoleto, handleSubmit: handleSubmitBoleto, control: controlBoleto, errors: errorsBoleto, setValue: setValueBoleto } = useForm<IPaymentFormData>({
    resolver: yupResolver(RegisterBoletoSchema),
    defaultValues: {
      parcelas: 1
    }
  });

  useEffect(() => {
    registerBoleto({ name: 'parcelas' }, { required: true });
  }, [registerBoleto, defaultParcela])



  const onSubmitBoleto = useCallback(async (data: IPaymentFormData) => {
    console.log(plan, invoice)
    if ((plan || invoice) && !loading) {
      try {
        setLoading(true);
        setDisabled(true);
        setSuccess(false);

        const saleForm: IPaymentSaleDTO = {
          idPacote: plan ? plan.id : invoice ? invoice.Identificador : "",
          formaPagamento: formPayment,
          parcelas: defaultParcela,
          idTenant: tenant.idTenant,
          recaptcha: sessionStorage.getItem('@HubfitPortal:recap'),
          codeVoucher: voucher ? voucher.code : null,
        };


        if (plan) {

          const response = plan.isRenovacao ? await api.post('/payments/renew/checkout', saleForm) : await api.post('/payments/sales/checkout', saleForm);
          console.log(response.data);
          if (response.data.ok == true) {

            if (response.data.idPessoa) {
              //Atualizar ID e Token do usuário que foi convertido
              const userLocal = localStorage.getItem('@HubfitPortal:user');
              if (userLocal) {
                const userObj: User = JSON.parse(userLocal);
                userObj.type = TypeUser.Aluno;
                userObj.id = response.data.idPessoa;
                updateUser(userObj, response.data.token);
              }

            }
            sessionStorage.setItem('@HubfitPortal:payment_details', JSON.stringify({
              formaPagamento: formPayment,
              parcelas: defaultParcela,
              linkBoleto: response.data.linkBoleto,
            }));
            if (response.data.habilitaAgendamento) {
              sessionStorage.setItem('@HubfitPortal:habilitaAgendamento', 'true');
            }

            sessionStorage.setItem('@HubfitPortal:responsePayment', JSON.stringify(response.data));
            setSuccess(true);
            setTimeout(() => {
              history.push('/payments/checkout/success');
            }, 1000);

          } else {
            setLoading(true);
            setDisabled(false);
            addToast({
              type: "error",
              title: 'Ops...',
              description: response.data.msg,
              time: 20000
            });
          }
        } else if (invoice) {
          const response = await api.post('/payments/pending/checkout', saleForm);
          console.log(response.data);
          sessionStorage.setItem('@HubfitPortal:payment_details', JSON.stringify({
            formaPagamento: formPayment,
            parcelas: defaultParcela,
            linkBoleto: response.data.linkBoleto
          }));
          if (response.data.ok == true) {

            setSuccess(true);
            setTimeout(() => {
              history.push('/payments/checkout/success');
            }, 1000);

          } else {
            setDisabled(false);
            sessionStorage.setItem('@HubfitPortal:errorPayment', response.data.msg);
            history.push('/payments/checkout/success');
          }
        }

      } catch (err) {
        setDisabled(false);
        addToast({
          type: "error",
          title: 'Erro no pagamento',
          description: "Não foi possível efetuar o pagamento",
          time: 20000
        });
      } finally {
        setLoading(false);
        refRecaptcha.current?.reset();
        setDisabled(false);
      }
    }
  }, [plan, history, invoice, formPayment, typeCard, defaultParcela]);


  const { register, handleSubmit, control, errors, setValue } = useForm<IPaymentFormData>({
    resolver: yupResolver(RegisterPasswordSchema),
    defaultValues: {
      parcelas: 1
    }
  });

  useEffect(() => {
    register({ name: 'parcelas' }, { required: true });
  }, [register, defaultParcela])



  const onSubmit = useCallback(async (data: IPaymentFormData) => {
    console.log(plan, invoice)
    if ((plan || invoice) && !loading) {
      try {
        setLoading(true);
        setDisabled(true);
        setSuccess(false);

        
        const cardForm: CreditCardDTO = {
          numero: data.number.replace("_",""),
          ano: Number.parseInt(data.expiry.split('/')[1]),
          mes: Number.parseInt(data.expiry.split('/')[0]),
          nomeCompleto: data.name,
          cvc: data.cvc.replace("_",""),
          bandeira: typeCard ? typeCard : 'visa',
        };

        const saleForm: IPaymentSaleDTO = {
          idPacote: plan ? plan.id : invoice ? invoice.Identificador : "",
          formaPagamento: formPayment,
          parcelas: defaultParcela,
          cartao: cardForm,
          idTenant: tenant.idTenant,
          recaptcha: sessionStorage.getItem('@HubfitPortal:recap'),
          codeVoucher: voucher ? voucher.code : null,
        };
        if (voucher) {
          if (voucher.code != "" && voucher.code != null) {
            if (voucher.isConvenio == true) {
              saleForm.idConvenio = voucher.id;
            } else {
              saleForm.codeVoucher = voucher.code;
              saleForm.idVoucher = voucher.id;
            }
          }
        }

        if (plan) {

          const response = plan.isRenovacao ? await api.post('/payments/renew/checkout', saleForm) : await api.post('/payments/sales/checkout', saleForm);
          console.log(response.data);
          if (response.data.ok == true) {

            if (response.data.idPessoa) {
              //Atualizar ID e Token do usuário que foi convertido
              const userLocal = localStorage.getItem('@HubfitPortal:user');
              if (userLocal) {
                const userObj: User = JSON.parse(userLocal);
                userObj.type = TypeUser.Aluno;
                userObj.id = response.data.idPessoa;
                updateUser(userObj, response.data.token);
              }

            }
            sessionStorage.setItem('@HubfitPortal:payment_details', JSON.stringify({
              bandeira: typeCard ? typeCard : 'visa',
              numero: '********' + data.number.substr(data.number.length - 4),
              formaPagamento: formPayment,
              parcelas: defaultParcela,

            }));
            if (response.data.habilitaAgendamento) {
              sessionStorage.setItem('@HubfitPortal:habilitaAgendamento', 'true');
            }
            // alert(response.data.urlAuth)
            // alert(formPayment)
            if (response.data.urlAuth && formPayment == FormPaymentEnum.CartaoDebito) {
              sessionStorage.setItem('@HubfitPortal:urlAuth', response.data.urlAuth);
              history.push('/payments/checkout/redirectauth');
            } else {
              sessionStorage.setItem('@HubfitPortal:responsePayment', JSON.stringify(response.data));
              setSuccess(true);
              setTimeout(() => {
                history.push('/payments/checkout/success');
              }, 1000);
            }

          } else {
            setLoading(true);
            setDisabled(false);
            addToast({
              type: "error",
              title: 'Ops...',
              description: response.data.msg,
              time: 20000
            });
          }
        } else if (invoice) {
          const response = await api.post('/payments/pending/checkout', saleForm);
       
          console.log(response.data);
          sessionStorage.setItem('@HubfitPortal:payment_details', JSON.stringify({
            bandeira: typeCard ? typeCard : 'visa',
            numero: '********' + data.number.substr(data.number.length - 4),
            formaPagamento: formPayment,
            parcelas: defaultParcela,
          }));
          if (response.data.ok == true) {
            if (response.data.urlAuth && formPayment == FormPaymentEnum.CartaoDebito) {
              sessionStorage.setItem('@HubfitPortal:urlAuth', response.data.urlAuth);
              history.push('/payments/checkout/redirectauth');
            } else {
              setSuccess(true);
              setTimeout(() => {
                history.push('/payments/checkout/success');
              }, 1000);
            }
          } else {
            setDisabled(false);
            sessionStorage.setItem('@HubfitPortal:errorPayment', response.data.msg);
            history.push('/payments/checkout/success');
          }
        }

      } catch (err) {
        setDisabled(false);
        addToast({
          type: "error",
          title: 'Erro no pagamento',
          description: "Não foi possível efetuar o pagamento",
          time: 20000
        });
      } finally {
        setLoading(false);
        refRecaptcha.current?.reset();
        setDisabled(false);
      }
    }
  }, [plan, history, invoice, formPayment, typeCard, defaultParcela]);

  const handleClickOpenModalContrato = useCallback(async () => {
    try {
      const response = await api.get('/plans/' + plan?.idContrato + '/document?', {
        params: {
          value: plan?.valorTotal
        }
      });
      setContract(response.data);
    } catch (err) {
      setContract(undefined)
      addToast({
        type: "error",
        title: 'Erro no buscar o contrato',
        time: 15000
      });
    } finally {
      setOpenMoldal(true);
    }
  }, [plan]);
  const handleMoldalClose = () => {
    setOpenMoldal(false);
  };
  const handleMoldalEmailClose = () => {
    setOpenMoldalEmail(false);
  };
  const handleClickDetails = useCallback(() => {
    setOpenDetails(true);
  }, [])
  const handleCloseModalDetails = useCallback(() => {
    setOpenDetails(false);
  }, []);
  const handleClickMenu = useCallback((value) => {

    setTab(value);
    switch (value) {
      case 0:
        setFormPayment(FormPaymentEnum.CartaoCredito);
        break;
      case 1:
        setFormPayment(FormPaymentEnum.CartaoDebito);
        break;
      case 2:
        setFormPayment(FormPaymentEnum.Boleto);
        break;
      case 3:
        setFormPayment(FormPaymentEnum.Pix);
        console.log(invoice)
        if(invoice && invoice?.ExigeEmailPix && !email){
          setOpenMoldalEmail(true);
        }
        break;
    }
  }, [invoice, email]);
  const handleChangeIndex = (index: number) => {
    setTab(index);
  };

  const handleClickOpenModalEmail = useCallback(async (event) => {
    
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const email = formJson.email;
    //console.log(email);
    setEmail(email);
    handleMoldalEmailClose();
    onSubmitPix();

  },[])
  
  const getStatusTransacao = useCallback(async (idPedidoSistema: string) => {
    const response = await api.get('/payments/sales/checkoutpixstatus', {
      params: {
        idPedido: idPedidoSistema, 
        idTenant: tenant.idTenant
      }
    });
    return response;
  }, [tenant.idTenant]);

  const LoopStatus = useCallback((idOrderT: string) => {
    //  if (loadClass) {       //  create a loop function
    console.log(idOrderT)
    const timer = (idO: string) => window.setTimeout(function () {   //  call a 3s setTimeout when the loop is called
      getStatusTransacao(idO).then((response) => {
        console.log(response.data);
        const retorno = response.data;
        if (retorno.statusCode == 12) {
          //Ainda pendente
        } else if (retorno.statusCode == 2) {
          //Processado
          clearTimeout(timerId);
          if (response.data.ok == true) {
            if (retorno.retornoPaymentInfo) {
              sessionStorage.setItem('@HubfitPortal:paymentSuccess', JSON.stringify({
               // bandeira: retorno.retornoPaymentInfo.brand,
               // numero: retorno.retornoPaymentInfo.lastNumbers,
                formaPagamento: retorno.retornoPaymentInfo.formaPagamento == 11 ? FormPaymentEnum.Pix : FormPaymentEnum.CartaoCredito,
                parcelas: retorno.retornoPaymentInfo.parcelas,
                valor: retorno.retornoPaymentInfo.valor,
              }));
            }
            setSuccess(true);
            setTimeout(() => {
              history.push('/payments/checkout/success');
            }, 1000);
          } else {
            setDisabled(false);
            addToast({
              type: "error",
              title: 'Ops...',
              description: response.data.msg,
              time: 20000
            });
          }


        } else if (retorno.statusCode == 13) {
          //Operação cancelada
          setDisabled(false);
          addToast({
            type: "error",
            title: 'Operação cancelada',
            time: 7000
          });
        //  handleGoBack();
        }
      })


      LoopStatus(idOrderT);             //  ..  again which will trigger another
    }, 5000);
    timerId = timer(idOrderT);

    return () => {
      if (timerId && timerId > 0) {
        clearTimeout(timerId);
      }

    };

    // }
  }, [])

  const codigocopiado = useCallback(() => {
    addToast({
      type: "success",
      title: 'Código PIX copiado para a área de transferência.',
    });
  }, [])

  return (
    <>
      <Header  isBack avatar hideSubmenu={!user} />
      <Helmet>
        <title>Pagamento online - {tenant?.name}</title>
      </Helmet>
      <Container>
        {aguardandoPagamento == true ?
      <Grid item xs={12} sm={12} >
                      <div >
                        <div>
                          <Lottie options={defaultOptionsWaiting}
                            height={115}
                            width={115}
                            speed={0.6}
                            isStopped={isStopped}
                          />

                        </div>
                        <Grid container direction="row"
                          justify="center"
                          alignItems="center">
                          <Grid className={classes.cardPadding}>
                            <Paper elevation={1} className={classes.Paper} >
                              <Grid container alignItems="center">
                                {!loading &&
                                  <Grid item xs >
                                    {invoice && invoice.Pago ?
                                      <Typography gutterBottom variant="h5" >
                                        Esta fatura já foi paga anteriormente.
                                      </Typography>
                                      :
                                      <Typography gutterBottom variant="h5" >
                                          <span>Aguardando pagamento por PIX</span>
                                      </Typography>
                                    }


                                  </Grid>
                                }
                              </Grid>
                              <Typography gutterBottom color="textSecondary" variant="body2">
                                      <span>Realize o pagamento pelo aplicativo do seu banco copiando o código através do botão abaixo ou escaneando o QRCode.</span>
                                      
                                    
                                  </Typography>
                                  <Typography gutterBottom color="textSecondary" variant="body2">
                                      <span>O pagamento ficará disponível por 1 dia.</span>
                                  </Typography>
                              {payment_details?.chavePix &&
                                <Grid
                                  container
                                  direction="row"
                                  justify="center"
                                  alignItems="center"
                                  spacing={2}
                                  style={{ marginTop: '23px' }}
                                >
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>
                                    Chave Pix: <b>{payment_details.chavePix}</b>
                                  </Grid>
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>
                                    <QRCode size={180} value={payment_details.qrcodePix} />

                                  </Grid>
                                </Grid>
                              }
                              {payment_details?.qrcodePix &&
                                <Grid
                                  container
                                  direction="row"
                                  justify="center"
                                  alignItems="center"
                                  spacing={2}
                                  style={{ marginTop: '23px' }}
                                >
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>

                                    <CopyToClipboard text={payment_details.qrcodePix}
                                      onCopy={() => codigocopiado()}>
                                      <Button type="button" variant="contained" color="primary" >
                                        Copiar código Pix
                                      </Button>

                                    </CopyToClipboard>
                                  </Grid>
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>
                                    <QRCode size={180} value={payment_details.qrcodePix} />

                                  </Grid>
                                </Grid>
                              }
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}
                                style={{ marginTop: '23px' }}
                              >
                              </Grid>

                            </Paper>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
:
        <Grid container direction="row-reverse" className={classes.GridList} >
          <Grid item xs={12} sm={5} md={5} lg={4} >
            {invoice && (
              <Grid container direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={12} md={10} lg={8} className={classes.cardPadding}>
                  <Card>
                    <CardBody>
                      <Typography align="center">{invoice.DescricaoPlano}</Typography>
                      {invoice.MaxParcelas > 1 && (
                        <>
                          <Typography align="center">Em até {invoice.MaxParcelas}x de</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={invoice.ValorTotal / invoice.MaxParcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>

                        </>
                      )}
                      {invoice.MaxParcelas == 1 && (
                        <>
                          <Typography align="center">à vista</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={invoice.ValorTotal / invoice.MaxParcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                        </>
                      )}


                    </CardBody>
                    <Divider variant="fullWidth" />
                    <CardBody>
                      <GridFooterCard>
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><p>Valor original:</p></Grid>
                          <Grid><NumberFormat value={invoice.ValorOriginal} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                        </Grid>
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><p>Multa / Juros:</p></Grid>
                          <Grid><NumberFormat value={invoice.ValorMulta} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                        </Grid>
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><p>Total:</p></Grid>
                          <Grid><NumberFormat value={invoice.ValorTotal} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                        </Grid>
                      </GridFooterCard>
                    </CardBody>

                  </Card>
                </Grid>
              </Grid>

            )}
            {plan && (
              <Grid container direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={12} md={10} lg={8} className={classes.cardPadding}>

                  <Card>
                    <CardHeader >
                      <Typography variant="h4">{plan.nomePlano}</Typography>
                    </CardHeader>

                    <CardBody>
                      {plan.parcelas > 1 && !plan.dcc && (
                        <>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={(plan.valorTotal-plan.adesaoValor)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                          <Typography align="center">Em até {plan.parcelas}x</Typography>
                          {/* <Typography variant="body2" className={classes.textObs} align="center">ou <NumberFormat value={plan.valorTotal} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /> à vista</Typography> */}
                        </>
                      )}
                      {plan.parcelas == 1 && !plan.dcc && (
                        <>
                          <Typography align="center">à vista</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorTotal-plan.adesaoValor / plan.parcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                        </>
                      )}
                      {plan.dcc && (
                        <>
                          <Typography align="center">Mensalidade</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorDcc} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                          <Typography variant="body2" className={classes.textObs} align="center">Cobrado mensalmente no cartão de crédito</Typography>
                        </>
                      )}
                      <Grid container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center">
                        <Grid item xs={12} alignContent="center">
                          <Button fullWidth color="secondary" onClick={() => handleClickDetails()}>Mais detalhes</Button>
                        </Grid>

                      </Grid>
                    </CardBody>
                    <Divider variant="fullWidth" />
                    <CardBody>

                      {plan.nomePlano &&
                        <GridFooterCard>
                          <Grid container
                            direction="row"
                            justify="space-between"

                            alignItems="center">
                            <Typography variant="h6" gutterBottom>
                              {plan.nomePlano}
                            </Typography>
                            <Grid style={{ color: '#a0a0a0' }}>
                              {plan.parcelas > 1 ?
                                <span>
                                  Parcelado em até {plan.parcelas}x
                                </span>
                                :
                                plan.dcc ?

                                  <span>
                                    Mensalidade
                                  </span>
                                  :
                                  <span>
                                    1x à vista
                                  </span>
                              }
                            </Grid>
                          </Grid>


                          {voucher && (voucher.valorDescontoContrato > 0 || voucher.valorDescontoContratoDCC > 0) &&
                            <>
                              <Grid container
                                direction="row"
                                justify="space-between"
                                style={{ color: green[500] }}
                                alignItems="center">
                                <Grid>
                                  <p>Desconto:</p>

                                </Grid>
                                <Grid>-<NumberFormat value={voucher.valorDescontoContrato} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                              </Grid>
                              {voucher.qtdParcelasDesconto && voucher.qtdParcelasDesconto > 0 && plan.dcc && 
                                <Grid container
                                  direction="row"
                                  justify="flex-end"
                                  style={{ color: grey[500] }}
                                  alignItems="flex-end">
                                  {voucher.qtdParcelasDesconto > 1 ?
                                    <small>*Descontado nas {voucher.qtdParcelasDesconto} primeiras mensalidades</small>
                                    :
                                    <small>*Descontado na 1ª mensalidade</small>
                                  }
                                </Grid>
                              }
                            </>
                          }
                          {plan.dcc &&
                            <Grid container
                              direction="row"
                              justify="space-between"
                              alignItems="center">
                              <Grid><p>Mensalidade:</p></Grid>
                              <Grid><b><NumberFormat value={plan.valorDcc - (voucher ? voucher.valorDescontoContrato : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>

                            </Grid>
                          }
                          {!plan.dcc &&
                            <Grid container
                              direction="row"
                              justify="space-between"
                              alignItems="center">
                              <Grid><p>Total:</p></Grid>
                              <Grid><b><NumberFormat value={plan.valorContrato - (voucher ? voucher.valorDescontoContrato : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>
                            </Grid>
                          }


                          <Divider style={{ marginBottom: '10px' }} />
                        </GridFooterCard>
                      }

                      {plan.adesaoDescricao &&
                        <GridFooterCard>
                          <Grid container
                            direction="row"
                            justify="space-between"

                            alignItems="center">
                            <Typography variant="h6" gutterBottom>
                              {plan.adesaoDescricao}
                            </Typography>
                            <Grid style={{ color: '#a0a0a0' }}>
                              {plan.adesaoParcelar && plan.parcelas > 1 ?
                                <span>
                                  Parcelado em até {plan.parcelas}x
                                </span>
                                :
                                <span>
                                  <span>
                                    1x à vista
                                  </span>
                                </span>
                              }
                            </Grid>
                          </Grid>

                          {voucher && voucher.valorDescontoAdesao > 0 &&

                            <Grid container
                              direction="row"
                              justify="space-between"
                              style={{ color: green[500] }}
                              alignItems="center">
                              <Grid>
                                <p>Desconto:</p>

                              </Grid>
                              <Grid>-<NumberFormat value={voucher.valorDescontoAdesao} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>
                            </Grid>
                          }
                          <Grid container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid><p>Total:</p></Grid>
                            <Grid><b><NumberFormat value={plan.adesaoValor - (voucher ? voucher.valorDescontoAdesao : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>
                          </Grid>



                          <Divider style={{ marginBottom: '10px' }} />
                        </GridFooterCard>
                      }
                      {plan.servicoExtraDescricao &&
                        <GridFooterCard>
                          <Grid container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Typography variant="h6" gutterBottom>
                              {plan.servicoExtraDescricao}
                            </Typography>
                            <Grid style={{ color: '#a0a0a0' }}>
                              {plan.servicoParcelar && plan.parcelas > 1 ?
                                <span>
                                  Parcelado em até {plan.parcelas}x
                                </span>
                                :
                                <span>
                                  <span>
                                    1x à vista 
                                  </span>
                                </span>
                              }
                            </Grid>
                          </Grid>



                          {voucher && voucher.valorDescontoServico > 0 &&

                            <Grid container
                              direction="row"
                              justify="space-between"
                              style={{ color: green[500] }}
                              alignItems="center">
                              <Grid>
                                <p>Desconto:</p>

                              </Grid>
                              <Grid>-<NumberFormat value={voucher.valorDescontoServico} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>
                            </Grid>
                          }
                          <Grid container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid><p>Valor:</p></Grid>
                            <Grid><b><NumberFormat value={plan.servicoExtraValor - (voucher ? voucher.valorDescontoServico : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>
                          </Grid>



                          <Divider style={{ marginBottom: '10px' }} />
                        </GridFooterCard>
                      }
                      {plan.anuidadeValor > 0 &&
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><p>Manutenção anual:</p></Grid>
                          <Grid><NumberFormat value={plan.anuidadeValor} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                        </Grid>
                      }

                      {voucher &&


                        <Grid container
                          direction="row"
                          justify="space-between"
                          style={{ color: green[500] }}
                          alignItems="center">
                            {voucher.isConvenio?
                             <Grid>
                             <p>Desconto aplicado <small>({voucher.code})</small>:</p>
                           </Grid>
                            :
                            <Grid>
                            <p>Cupom aplicado <small>(Cód: {voucher.code})</small>:</p>
                          </Grid>
                            }
                         
                          <Grid>-<NumberFormat value={(plan.adesaoValor > 0 ? voucher.valorDescontoAdesao : 0) +
                            (plan.valorContrato > 0 ? voucher.valorDescontoContrato : 0) +
                            (plan.produtoValor > 0 ? voucher.valorDescontoProduto : 0) +
                            (plan.servicoExtraValor > 0 ? voucher.valorDescontoServico : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>
                        </Grid>
                      }
                      {plan.dcc &&
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><b className={classes.totalValue}>Você paga agora:</b></Grid>
                          <Grid><b className={classes.totalValue}><NumberFormat value={(plan.valorDcc - (voucher ? voucher.valorDescontoContrato : 0)) + (plan.adesaoValor - (voucher ? voucher.valorDescontoAdesao : 0))
                          } displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>
                        </Grid>
                      }
                      {!plan.dcc &&
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><b className={classes.totalValue}>Valor total:</b></Grid>
                          <Grid><b className={classes.totalValue}><NumberFormat value={plan.valorTotal - (voucher ?
                            (plan.adesaoValor > 0 ? voucher.valorDescontoAdesao : 0) +
                            (plan.valorContrato > 0 ? voucher.valorDescontoContrato : 0) +
                            (plan.produtoValor > 0 ? voucher.valorDescontoProduto : 0) +
                            (plan.servicoExtraValor > 0 ? voucher.valorDescontoServico : 0) : 0)
                          } displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>
                        </Grid>
                      }
                    </CardBody>

                  </Card>
                </Grid>
              </Grid>

            )}

          </Grid>
          {loadingData ?
            <Grid container direction="column" className={classes.GridList}
              justify="center"
              alignItems="center" >
              <Grid item xs={12} >
                <CircularProgress size={40} />
              </Grid>
            </Grid>
            :
            <>
              <Grid item xs={12} sm={7} md={7} lg={8} >
                <AnimationContainer className={classes.animationTab}>
                  <Background>
                    <Grid container direction="row"
                      justify="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={8} lg={8} className={classes.cardPadding}>

                        <BottomNavigation

                          value={tab}
                          onChange={(event, newValue) => {
                            handleClickMenu(newValue);
                          }}
                          showLabels
                        >
                          {(plan?.payConfig?.creditCard || invoice?.payConfig?.creditCard) &&
                            <BottomNavigationAction label="Cartão de crédito" icon={<CreditCard />} />
                          }
                          {(plan?.payConfig?.debitCard || invoice?.payConfig?.debitCard) &&
                            <BottomNavigationAction label="Cartão de débito" icon={<CreditCard />} />
                          }
                          {(plan?.payConfig?.boleto || invoice?.payConfig?.boleto) &&
                            <BottomNavigationAction label="Boleto" icon={<BarCodeIcon />} />
                          }
                          {(plan?.payConfig?.pix || invoice?.payConfig?.pix) &&
                            <BottomNavigationAction icon={<img src={Logo} style={{ maxHeight: '80px' }} />} />
                          }
                        </BottomNavigation>
                      </Grid>

                    </Grid>

                    {(formPayment == FormPaymentEnum.CartaoCredito || formPayment == FormPaymentEnum.CartaoDebito) &&
                      <Grid container direction="row"
                        justify="center"
                        alignItems="center">
                        <Grid item xs={12} sm={12} md={8} lg={5} className={classes.cardPadding}>

                          <Cards

                            cvc={cvc}
                            expiry={expiry}
                            focused={focus}
                            name={name}
                            number={number}
                            locale={{ valid: 'valido até' }}
                            placeholders={{ name: 'NOME NO CARTÃO' }}
                            callback={handleChangeCard}

                          />
                          <form onSubmit={handleSubmit(onSubmit)}>

                            <Typography variant="h5" gutterBottom className={classes.titleCard}>
                              {formPayment == FormPaymentEnum.CartaoCredito &&
                                <span>Dados do cartão de crédito</span>
                              }

                              {formPayment == FormPaymentEnum.CartaoDebito &&
                                <span>Dados do cartão de débito</span>
                              }
                            </Typography>
                            <Paper elevation={1} className={classes.Paper} >
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <FormControl fullWidth error={!!errors.number?.message}>


                                    <InputMask
                                      mask="9999 9999 9999 99999"
                                      disabled={false}
                                      maskChar=""
                                      maskPlaceholder={null}
                                    //  onFocus={(e) => setFocus(e.target.name)} 
                                    onChange={(e) => setNumber(e.target.value)}


                                    >

                                      {
                                        <TextField margin="normal" label="Número do cartão" name="number" id="number" autoComplete="off" inputRef={(e) => {
                                        register(e);
                                        numberRef.current = e;
                                      }}
                                        type="tel"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                      }
                                    </InputMask>


                                    {/* <NumberFormat customInput={TextField}  displayType={'text'} format="#### #### #### ####" name="number" id="number" onFocus={(e) => setFocus(e.target.name)} onChange={(e) => setNumber(e.target.value)}  autoComplete="off"
                            inputRef={(e: HTMLInputElement | null) => {
                              register(e);
                              numberRef.current = e;
                            }}/> */}
                                    <FormHelperText>{errors.number?.message}</FormHelperText>
                                  </FormControl>

                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl fullWidth error={!!errors.name?.message}>

                                    <TextField label="Nome e sobrenome" name="name" id="name" 
                                    //onFocus={(e) => setFocus(e.target.name)} 
                                    onChange={(e) => setName(e.target.value)} inputRef={register} autoComplete="off" InputLabelProps={{
                                      shrink: true,
                                    }} />
                                    <FormHelperText>{errors.name?.message}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth error={!!errors.expiry?.message}>

                                    <InputMask
                                      mask="99/99"
                                      disabled={false}
                                      maskChar=""
                                      maskPlaceholder={null}
                                      placeholder="MM/AA"
                                      //onFocus={(e) => setFocus(e.target.name)} 
                                      onChange={(e) => setExpiry(e.target.value)}
                                    >
                                      {<TextField name="expiry" id="expiry" inputRef={register} autoComplete="off" InputLabelProps={{
                                        shrink: true,
                                      }} type="tel" label="Data de validade" placeholder="MM/AA" />}
                                    </InputMask>
                                    <FormHelperText>{errors.expiry?.message}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth error={!!errors.cvc?.message}>
                                    <InputMask
                                      mask="9999"
                                      disabled={false}
                                      maskPlaceholder={null}
                                      maskChar=""
                                      //onFocus={(e) => setFocus(e.target.name)} 
                                      onChange={(e) => setCvc(e.target.value)}
                                    >
                                      {<TextField type="tel" label="Cód. de segurança" name="cvc" id="cvc" inputRef={register} autoComplete="off" InputLabelProps={{
                                        shrink: true,
                                      }} />}
                                    </InputMask>
                                    <FormHelperText>{errors.cvc?.message}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                {formPayment == FormPaymentEnum.CartaoCredito && parcelas &&
                                  <Grid item xs={12}>
                                    <FormControl fullWidth error={!!errors.parcelas?.message}>
                                      <InputLabel id="parcelasLabel">Parcelas</InputLabel>

                                      <Select
                                        inputRef={register}
                                        name="parcelas"
                                        value={defaultParcela}
                                        onChange={handleChangeParcela}
                                      >
                                        {parcelas.map(p => (
                                          <MenuItem key={p.value} value={p.value}>
                                            {`${p.value}x de `} <NumberFormat value={p.text} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />

                                          </MenuItem>

                                        ))}

                                      </Select>
                                      {plan && (plan?.adesaoValor-(voucher && voucher.valorDescontoAdesao > 0 ? voucher.valorDescontoAdesao : 0)) > 0 && plan.adesaoParcelar == false &&
                                        <small> + 1x de <NumberFormat value={plan?.adesaoValor - (voucher && voucher.valorDescontoAdesao > 0 ? voucher.valorDescontoAdesao : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /> ({plan.adesaoDescricao})</small>
                                      }
                                      {plan && (plan?.servicoExtraValor-(voucher && voucher.valorDescontoServico > 0 ? voucher.valorDescontoServico : 0)) > 0 && plan.servicoParcelar == false &&
                                        <small> + 1x de <NumberFormat value={plan?.servicoExtraValor - (voucher && voucher.valorDescontoServico > 0 ? voucher.valorDescontoServico : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /> ({plan.servicoExtraDescricao})</small>
                                      }

                                      <FormHelperText>{errors.parcelas?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                }
                                {plan && plan.idContrato &&
                                  <Grid item xs={12}>
                                    <FormControl fullWidth error={!!errors.acceptsTerms?.message} >
                                      <div className={classes.ControlTerms}>
                                        <FormControlLabel className={classes.labelControlTerms}
                                          control={
                                            <Checkbox
                                              // checked={state.checkedB}
                                              // onChange={handleChange}
                                              inputRef={register}
                                              name="acceptsTerms"
                                              color="primary"
                                            />
                                          }
                                          label="Eu aceito os termos do "
                                        />
                                        <Button variant="text" onClick={handleClickOpenModalContrato} className={classes.buttonContract} >Contrato</Button>
                                      </div>
                                      <FormHelperText>{errors.acceptsTerms?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                }
                              </Grid>

                            </Paper>
                            <Content>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                              >
                                <Recaptcha
                                  ref={refRecaptcha}
                                  hl='pt-BR'
                                  size='normal'
                                  sitekey="6LfHOKIUAAAAAPwaZ0c_LxLmhZV4wckS4xGKr9Nr"
                                  render="explicit"
                                  onloadCallback={recaptchaLoaded}
                                  verifyCallback={recaptchaCallback}
                                />
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                              >
                                {!success &&
                                  <Button className={buttonClassname} disabled={disabled} type="submit" variant="contained" color="primary"  >

                                    {loading &&
                                      <>
                                        <span>Aguarde...</span><CircularProgress size={24} className={classes.buttonProgress} />
                                      </>
                                    }
                                    {!loading &&
                                      <span>Efetuar pagamento</span>
                                    }
                                  </Button>
                                }
                                {success && !loading &&
                                  <CheckCircle fontSize="large" htmlColor="#4caf50" className={classes.IconCheck} />
                                }

                              </Grid>
                            </Content>

                          </form>

                        </Grid>
                      </Grid>
                    }

                    {formPayment == FormPaymentEnum.Boleto &&
                      <Grid container direction="row" justify="center"
                        alignItems="center">
                        <Grid item xs={12} sm={12} md={8} lg={5} className={classes.cardPadding}>

                          <form onSubmit={handleSubmitBoleto(onSubmitBoleto)}>

                            <Typography variant="h5" gutterBottom className={classes.titleCard}>
                              <span>Pagamento no boleto bancário</span>
                            </Typography>
                            <Paper elevation={1} className={classes.Paper} >
                              <Grid container spacing={1}>

                                {formPayment == FormPaymentEnum.Boleto && parcelas &&
                                  <Grid item xs={12}>
                                    <FormControl fullWidth error={!!errorsBoleto.parcelas?.message}>
                                      <InputLabel id="parcelasLabel">Parcelas</InputLabel>

                                      <Select
                                        inputRef={registerBoleto}
                                        name="parcelas"
                                        value={defaultParcela}
                                        onChange={handleChangeParcela}
                                      >
                                        {parcelas.map(p => (
                                          <MenuItem key={p.value} value={p.value}>
                                            {`${p.value}x de `} <NumberFormat value={p.text} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />
                                          </MenuItem>

                                        ))}

                                      </Select>



                                      <FormHelperText>{errorsBoleto.parcelas?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                }
                                {plan && plan.idContrato &&
                                  <Grid item xs={12}>
                                    <FormControl fullWidth error={!!errorsBoleto.acceptsTerms?.message} >
                                      <div className={classes.ControlTerms}>
                                        <FormControlLabel className={classes.labelControlTerms}
                                          control={
                                            <Checkbox
                                              // checked={state.checkedB}
                                              // onChange={handleChange}
                                              inputRef={registerBoleto}
                                              name="acceptsTerms"
                                              color="primary"
                                            />
                                          }
                                          label="Eu aceito os termos do "
                                        />
                                        <Button variant="text" onClick={handleClickOpenModalContrato} className={classes.buttonContract} >Contrato</Button>
                                      </div>
                                      <FormHelperText>{errorsBoleto.acceptsTerms?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                }
                                {invoice  &&
                                  <Grid item xs={12}>
                                    <span>Confirme abaixo para gerar o boleto bancário.</span>
                                    </Grid>
                                }
                              </Grid>

                            </Paper>
                            <Content>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                              >
                                <Recaptcha
                                  ref={refRecaptcha}
                                  hl='pt-BR'
                                  size='normal'
                                  sitekey="6LfHOKIUAAAAAPwaZ0c_LxLmhZV4wckS4xGKr9Nr"
                                  render="explicit"
                                  onloadCallback={recaptchaLoaded}
                                  verifyCallback={recaptchaCallback}
                                />
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                              >
                                {!success &&
                                  <Button className={buttonClassname} disabled={disabled} type="submit" variant="contained" color="primary"  >

                                    {loading &&
                                      <>
                                        <span>Aguarde...</span><CircularProgress size={24} className={classes.buttonProgress} />
                                      </>
                                    }
                                    {!loading &&
                                      <span>Finalizar e gerar boleto</span>
                                    }
                                  </Button>
                                }
                                {success && !loading &&
                                  <CheckCircle fontSize="large" htmlColor="#4caf50" className={classes.IconCheck} />
                                }

                              </Grid>
                            </Content>

                          </form>

                        </Grid>

                      </Grid>
                    }

                    {formPayment == FormPaymentEnum.Pix &&
                      <Grid container direction="row" justify="center"
                        alignItems="center">
                        <Grid item xs={12} sm={12} md={8} lg={5} className={classes.cardPadding}>

                          <form onSubmit={handleSubmitPix(onSubmitPix)}>

                            <Typography variant="h5" gutterBottom className={classes.titleCard}>
                              <span>Pagamento com Pix</span>
                            </Typography>
                            <Paper elevation={1} className={classes.Paper} >
                              <Grid container spacing={1}>


                                {plan && plan.idContrato &&
                                  <Grid item xs={12}>
                                    <FormControl fullWidth error={!!errorsPix.acceptsTerms?.message} >
                                      <div className={classes.ControlTerms}>
                                        <FormControlLabel className={classes.labelControlTerms}
                                          control={
                                            <Checkbox
                                              // checked={state.checkedB}
                                              // onChange={handleChange}
                                              inputRef={registerPix}
                                              name="acceptsTerms"
                                              color="primary"
                                            />
                                          }
                                          label="Eu aceito os termos do "
                                        />
                                        <Button variant="text" onClick={handleClickOpenModalContrato} className={classes.buttonContract} >Contrato</Button>
                                      </div>
                                      <FormHelperText>{errorsPix.acceptsTerms?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                }
                                 {invoice  &&
                                  <Grid item xs={12}>
                                    <Typography >Confirme abaixo para gerar o QRCode Pix.</Typography>
                                     
                                    
                                    </Grid>
                                }
                              </Grid>

                            </Paper>
                            <Content>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                              >
                                <Recaptcha
                                  ref={refRecaptcha}
                                  hl='pt-BR'
                                  size='normal'
                                  sitekey="6LfHOKIUAAAAAPwaZ0c_LxLmhZV4wckS4xGKr9Nr"
                                  render="explicit"
                                  onloadCallback={recaptchaLoaded}
                                  verifyCallback={recaptchaCallback}
                                />
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                              >
                                {!success &&
                                  <Button className={buttonClassname} disabled={disabled} type="submit" variant="contained" color="primary"  >

                                    {loading &&
                                      <>
                                        <span>Aguarde...</span><CircularProgress size={24} className={classes.buttonProgress} />
                                      </>
                                    }
                                    {!loading &&
                                      <span>Gerar QRCode</span>
                                    }
                                  </Button>
                                }
                                {success && !loading &&
                                  <CheckCircle fontSize="large" htmlColor="#4caf50" className={classes.IconCheck} />
                                }

                              </Grid>
                            </Content>

                          </form>

                        </Grid>

                      </Grid>

                    }
                  </Background>
                </AnimationContainer>
              </Grid>

            </>
          }
        </Grid>
}
      </Container>
      <Dialog
        open={openModalEmail}
        onClose={handleMoldalEmailClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleClickOpenModalEmail,
        }}
      >
        <DialogTitle>Atualização</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Precisamos que insira seu e-mail para gerarmos o QRCode PIX.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Seu e-mail"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Salvar</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen open={openModal} onClose={handleMoldalClose} >
        <AppBar className={classes.appBar}>
          <Toolbar>

            <Typography variant="h6" className={classes.title}>
              Contrato
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleMoldalClose} aria-label="close" className={classes.buttonClose} >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.backgroundContract}>
          <Grid container alignItems="center" justify="center" direction="row" >
            <Grid item xs={12} sm={12} md={10} lg={8} >
              <Paper elevation={3} className={classes.paperContract} >
                {ReactHtmlParser(contract ? contract : "Nenhum contrato foi encontrado. Verifique com a empresa.")}
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <DialogPlanDetails buyButton={false} onClose={handleCloseModalDetails} open={openDetails} plan={plan} />
    </>
  )
};
export default Checkout;
