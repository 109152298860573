import React, { useCallback, useState, useEffect } from 'react';
import { format, setDay, isBefore, addDays, parseISO } from 'date-fns';
import classNames from "classnames";

import { ContentModal } from './styles';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Typography, Button, Grid, Divider,
  DialogContent as MuiDialogContent, Dialog, CircularProgress, CardMedia, CardContent, Card, Chip, Paper, Avatar, AppBar, Tabs, Tab, Breadcrumbs, Link, Box, useTheme, Zoom, useScrollTrigger, Toolbar, Fab, colors, Badge
} from "@material-ui/core";
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import Header from '../../../components/Header';
import Timer from '../../../components/TimerCountdown';
import { red, green } from '@material-ui/core/colors';
import { useAuth } from '../../../hooks/auth';
import { IClassItem, IVerify, IReturnVerify, IClassList, MapaNode } from '../../../models/Classes/IClassesDTO';
import { ArrowLeft, KeyboardArrowUp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Content from '../../../components/Content';
import SwipeableViews from 'react-swipeable-views';
import DialogUnidade from '../../../components/DialogUnidade/Index';
import { Helmet } from 'react-helmet';
import './Classes.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',

      display: 'flex',
      padding: '5px',
      cursor: 'pointer',
    },
    rootLoading: {
      display: 'flex',
      cursor: 'default',
      opacity: '0.8',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    ModalAula: {
      maxWidth: '786px',
      width: '90%',
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    vagas: {
      borderRadius: '23px',
      fontSize: '19px',

    },
    divVagas: {
      display: 'flex',
      alignItems: 'center',
      width: '138px',
      flexDirection: 'column',
      alignSelf: 'center',
      // marginTop: '-16px',
    },
    content: {
      flex: '1 0 auto',
      padding: '3px',
    },
    cover: {
      width: 200,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    titleClass: {
      margin: '2px 5px 0px 14px !important',
    },
    timeClass: {
      margin: '0 !important',
      color: theme.palette.text.secondary,
    },
    timeRest: {
      color: theme.palette.text.disabled,
      marginLeft: '10px',
      fontSize: '14px',


    },
    divFlexTime: {
      display: 'inline-flex',
      alignItems: 'flex-end',
      marginLeft: '3px',
    },
    textVagas: {
      color: theme.palette.text.secondary,
    },
    textConfirmado: {
      background: green[500],
      height: '32px',
      fontSize: '15px',
      borderRadius: '23px',
    },
    textEncerrado: {
      background: red[500],
      height: '32px',
      fontSize: '15px',
      borderRadius: '23px',
    },
    coverModal: {
      width: 'auto',
      height: '185px',
      backgroundColor: '#e4e4e4',
    },
    titleLigh: {
      color: theme.palette.text.disabled,
    },
    titleCoverModal: {
      position: 'absolute',
      top: '12%',
      left: '50%',
      color: 'white',
      transform: 'translate(-50%, -50%)',
      textShadow: '1px 2px 4px #000000',
    },
    contentModal: {
      position: 'relative',
    },
    btnRegister: {
      position: 'absolute',
      right: '15px',
      top: '-18px',
    },
    cardText: {
      textAlign: 'center',
    },
    contentDialog: {
      marginTop: '10px',
    },
    section2: {
      margin: theme.spacing(2),
    },
    buttonRed: {
      color: theme.palette.getContrastText(red[500]),
      background: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    disableRegister: {

      color: 'rgb(0 0 0 / 55%)',
      backgroundColor: 'rgb(189 189 189 / 84%)',
      '&:hover': {
        backgroundColor: 'rgb(189 189 189 / 84%)',
      },
      cursor: 'inherit',
    },
    noClasses: {
      textAlign: 'center',
      padding: '60px',
      opacity: '0.7',
      '& h5': {
        color: theme.palette.text.secondary,
      }
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",
    },
    GridList: {
      margin: '20px 0',
    },
    backToTopAnchor: {
      height: '0px',
      minHeight: '0px',
    },
    roundButtonPrimary:{
      backgroundColor: theme.palette.primary.main +' !important',
    }
  }),
);

interface PropsZoom {
  window?: () => Window;
  children: React.ReactElement;
}

const useStylesZoom = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

function ScrollTop(props: PropsZoom) {
  const { children, window } = props;
  const classes = useStylesZoom();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}



interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: '0',
  },
}))(MuiDialogContent);



const List: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [listAulas, setListAulas] = useState<IClassList[]>();
  const [openModal, setOpenModal] = React.useState(false);
  const { tenant, user, signOut } = useAuth();
  const [tab, setTab] = useState(0);
  const [aulaModal, setAulaModal] = React.useState<IClassItem | undefined>();
  const [aulaDiaModal, setAulaDiaModal] = React.useState("");
  const [verificacao, setVerificacao] = React.useState<IReturnVerify | undefined>();
  const [verificando, setVerificando] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingClasses, setLoadingClasses] = React.useState(true);
  const [posicao, setPosicao] = React.useState<MapaNode | null>(null);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    if (tenant?.permissions.classesView == false) {
      history.replace("/");
    }
  }, [])

  const getClasses = useCallback(() => {

    const classString = sessionStorage.getItem('@HubfitPortal:class');
    const actionString = sessionStorage.getItem('@HubfitPortal:action');
    if (classString && actionString == "class_register") {
      const aulaSelected = JSON.parse(classString);
      setAulaModal(aulaSelected);
      setOpenModal(true);
      try {
        api.get('/checkin/verify', {
          params: {
            idAula: aulaSelected.IdAula,
            horaInicio: aulaSelected.HorarioInicio,
            idAulaGrade: aulaSelected.IdGrade,
            data: getDataSemana(aulaSelected.DiaSemana)
          }
        }).then((response) => {
          setVerificacao(response.data);

        }).catch(err => {
          if (err.response.status == 401) {
            addToast({
              type: "error",
              title: 'Sessão encerrada',
              description: 'Sua sessão expirou',
            });
            signOut();

          } else {
            addToast({
              type: "error",
              title: 'Erro',
              description: 'Não foi possível se conectar ao servidor',
            });
          }
        })
      } catch (err) {
        //TODO Mensagem de erro
      } finally {
      }
    }
    sessionStorage.removeItem('@HubfitPortal:action');
    sessionStorage.removeItem('@HubfitPortal:class');

    api.get('/classes/portal', {
      params: {
        idTenant: tenant.idTenant,
        idBranch: tenant.idBranch,
      }
    }).then(response => {
      console.log(response.data)
      setLoadingClasses(false);
      setListAulas(response.data);
    }).catch(err => {
      if (err.response.status == 401) {
        addToast({
          type: "error",
          title: 'Sessão encerrada',
          description: 'Sua sessão expirou',
        });
        signOut();

      } else {
        addToast({
          type: "error",
          title: 'Erro',
          description: 'Não foi possível se conectar ao servidor',
        });
      }
    })
  }, [tenant.idTenant, tenant.idBranch]);

  useEffect(() => {
    getClasses();
  }, [tenant.idTenant, tenant.idBranch]);




  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setLoading(false);
    setVerificacao(undefined);
    setPosicao(null);

  }, []);


  const handleClickAula = useCallback((id: number, dia: number) => {
    const aulaSelected = listAulas?.find((e) => e.Dia.DiaSemanaNumber == dia)?.Aulas.find((f) => f.IdGrade == id);
    const aulaDia = listAulas?.find((e) => e.Dia.DiaSemanaNumber == dia)?.Dia.DiaMes;
    console.log(aulaDia);
    setVerificando(true);
    if (aulaSelected && aulaSelected?.Encerrada == false) {
      if (!user) {
        addToast({
          type: "info",
          title: 'Faça login',
          description: 'Você não está conectado, faça login ou cadastre-se para agendar sua presença',
          time: 15000,
        });
      } else {
        setOpenModal(true);
        setAulaModal(aulaSelected);
        if (aulaDia) {
          setAulaDiaModal(aulaDia);
        }
        try {
          api.get('/checkin/verify', {
            params: {
              idAula: aulaSelected.IdAula,
              horaInicio: aulaSelected.HorarioInicio,
              idAulaGrade: aulaSelected.IdGrade,
              data: aulaDia
            }
          }).then((response) => {

            setVerificacao(response.data);
            var elements = document.querySelectorAll('.resize-drag');
            // alert(elements.length)
            for (var i = 0; i < elements.length; i++) {
              console.log('foiii', elements[i].getAttribute("data-x"));
              (elements[i] as HTMLElement).style.transform = 'translate(' + elements[i].getAttribute("data-x") + 'px,' + elements[i].getAttribute("data-y") + 'px)';
            }
            setVerificando(false);
          }).catch(err => {
            if (err.response.status == 401) {
              addToast({
                type: "error",
                title: 'Sessão encerrada',
                description: 'Sua sessão expirou',
              });
              signOut();

            } else {
              addToast({
                type: "error",
                title: 'Erro',
                description: 'Não foi possível se conectar ao servidor',
              });
            }
          })
        } catch (err) {
          //TODO Mensagem de erro
        } finally {

        }
      }
    }
  }, [listAulas]);

  function getDataSemana(diaSemana: number): string {
    const dateAula = new Date();
    var dateAulaSemana = setDay(dateAula, diaSemana - 1)

    if (isBefore(new Date, dateAulaSemana)) {
      dateAulaSemana = addDays(dateAulaSemana, 7);
    }
    return format(dateAulaSemana, 'yyyy-MM-dd');
  }

  function parseTime(t: string) {

    var d = new Date();

    var time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    if (time) {
      d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
      d.setMinutes(parseInt(time[2]) || 0);
    }
    return d;
  }
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  const handleChangeIndex = (index: number) => {
    setTab(index);
  };
  const handleAulaParticipar = useCallback(async () => {
    setLoading(true);

    try {
      if (aulaModal) {
        const register: IVerify = {
          idAula: aulaModal.IdAula,
          horaInicio: aulaModal.HorarioInicio,
          idAulaGrade: aulaModal.IdGrade,
          data: aulaDiaModal,
          idPosicao: posicao?posicao.Id:null,
        }

        const response = await api.post('/checkin/confirm', register);
        const { Retorno, Mensagem } = response.data;
        if (Retorno == 2) {
          addToast({
            type: "success",
            title: "Presença registrada com sucesso",
          });
          getClasses();
        }else if (Retorno == 19) {
          addToast({
            type: "success",
            title: "Aguardando na fila!",
            description: "Colocamos você na fila de espera. Assim que houver uma nova vaga disponível, vamos lhe enviar um aviso por aplicativo.", 
            time: 10000,
          });
          getClasses();
        } else {
          addToast({
            type: "error",
            title: Mensagem,
          });
        }

      }
    } catch (err) {

      addToast({
        type: "error",
        title: 'Erro no registro',
        description: 'Não foi possível registrar a presença',
      });
    } finally {
      setOpenModal(false);
      setLoading(false);
      setAulaModal(undefined);
      setTimeout(() => {
        setVerificacao(undefined);
      }, 1000);
    }
  }, [aulaModal, posicao]);

  const handleAulaRemover = useCallback(async () => {
    setLoading(true);

    try {
      if(verificacao?.PermiteRemoverPresenca == false){
        addToast({
          type: "error",
          title: 'Não é mais possível remover a presença',
          description: 'O tempo mínimo antes de iniciar a aula para remover a presença já passou.',
        });
        return;
      }
      if (aulaModal && verificacao) {

        const response = await api.delete('/checkin/cancel', {
          params: {
            IdHistoricoAula: verificacao.IdHistoricoAula,
            IdAulaGrade: aulaModal.IdGrade,
          }
        });
        getClasses();
        addToast({
          type: "success",
          title: "Presença cancelada com sucesso",
        });
      }
    } catch (err) {

      addToast({
        type: "error",
        title: 'Erro no registro',
        description: 'Não foi possível remover a presença',
      });
    } finally {
      setOpenModal(false);
      setLoading(false);
      setAulaModal(undefined);
      setTimeout(() => {
        setVerificacao(undefined);
      }, 1000);
    }
  }, [aulaModal, verificacao]);

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const filterMinhaPosicaoList = useCallback((idNode) => {
    if (verificacao) {
      return verificacao?.Mapa.filter(d => d.Id == idNode && d.MinhaPosicao == true).length > 0;
    } else {
      return false;
    }
  }, [verificacao]);
  const filterReservadoList = useCallback((idNode) => {
    if (verificacao) {
      return verificacao?.Mapa.filter(d => d.Id == idNode && d.MinhaPosicao == false && (d.Reservada == true || d.NaoRegistraPresenca == true)).length > 0;
    } else {
      return false;
    }
  }, [verificacao]);

  const clickVaga = useCallback((pos: MapaNode) => {
    console.log(pos);
    console.log(verificacao);
    if (verificacao && verificacao.Mapa) {
      if (verificacao.Retorno == 13 || verificacao.Retorno == 12 || verificacao.Retorno == 18) {
        if (pos.NaoRegistraPresenca == true) {
          addToast({
            type: "error",
            title: 'Posição não disponível',
            description: 'Tente selecionar outra posição.',
          });
        } else
          if (pos.Reservada == false) {
            setPosicao(pos);
          } else {
            addToast({
              type: "error",
              title: 'Posição já reservada',
              description: 'Tente selcionar outra posição.',
            });
          }
      } else if (verificacao.Retorno == 1) {
        var posicaoNova = verificacao.Mapa.find(d => d.MinhaPosicao == true)?.Label;
        addToast({
          type: "error",
          title: 'Posição já reservada',
          description: 'Você já reservou a posição ' + posicaoNova,
        });
      }
    }
  }, [verificacao])

  return (
    <>
      <Helmet>
        <title>Grade de aulas - {tenant?.name}</title>
      </Helmet>
      <Header removeBack avatar={true} tabIndex={5} />
      <Toolbar id="back-to-top-anchor" className={classes.backToTopAnchor} />
      <div className={classNames(classes.mainRaised)}>
        <div>
          <Content>
            <Grid container spacing={3} direction="row"
              justify="center"
              alignItems="center" >
              <Grid item xs={12} sm={12} md={10}  >

                <Breadcrumbs id="titleBreadcrums" aria-label="breadcrumb">
                  <Link color="inherit" href={`/${tenant.domain}`}>
                    Portal
                  </Link>

                  <Typography variant="h6" color="primary"  >Grade de horários</Typography>
                  <DialogUnidade />
                </Breadcrumbs>
                <Grid container spacing={3} direction="row"
                  justify="center"
                  alignItems="center" >


                  {(!listAulas || listAulas.length == 0) && !loadingClasses &&
                    <Grid item xs={12}  >
                      <Paper elevation={3} className={classes.noClasses}>
                        <Typography align="center" variant="h5" component="h5" >Nenhum horário disponível</Typography>

                      </Paper>
                    </Grid>
                  }
                  <Grid item xs={12}  >
                    <AppBar position="static" color="inherit" >


                      <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="auto"
                        variant="scrollable"
                      >
                        {loadingClasses &&
                          <Grid container direction="column" className={classes.GridList}
                            justify="center"
                            alignItems="center" >
                            <Grid item  >
                              <CircularProgress size={26} />
                            </Grid>
                            Buscando aulas
                          </Grid>
                        }
                        {listAulas?.map(aula => (
                          <Tab key={aula.Dia.DiaSemanaNumber} label={
                            <>
                              <Typography color="textPrimary" variant="body2">
                                {aula.Dia.DiaSemana}
                              </Typography>
                              <Typography color="textSecondary" variant="body2">
                                {format(parseISO(aula.Dia.DiaMes), 'dd/MM')}
                              </Typography>
                            </>
                          }  {...a11yProps(listAulas?.indexOf(aula))} />
                        ))}
                      </Tabs>
                    </AppBar>
                  </Grid>
                  <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={tab}
                    onChangeIndex={handleChangeIndex}
                  >

                    {listAulas?.map(diaSemana => (
                      <TabPanel value={tab} index={listAulas?.indexOf(diaSemana)} dir={theme.direction}>
                        <Grid container spacing={3} direction="row"
                          justify="center"
                          alignItems="center" >
                          {diaSemana.Aulas.map(aula => (
                            <Grid item xs={12} sm={12} md={10} lg={8} >
                              <Card className={classes.root} onClick={() => handleClickAula(aula.IdGrade, aula.DiaSemana)}>
                                <CardMedia
                                  className={classes.cover}
                                  image={aula.FotoAula}
                                  title={aula.NomeAula}
                                />
                                <div className={classes.details}>
                                  <CardContent className={classes.content}>
                                    <h3 className={classes.titleClass}>
                                      {aula.NomeAula}
                                    </h3>
                                    <div className={classes.divFlexTime}>
                                      <h1 className={classes.timeClass}>
                                        <Chip className={classes.vagas} label={aula.HorarioInicio.replace(':00', '')} />
                                      </h1>
                                    </div>

                                  </CardContent>
                                  <div className={classes.divVagas}>
                                    {aula.PresencaConfirmada && !aula.Encerrada && !aula.NaFila &&
                                      <Chip color="primary" className={classes.textConfirmado} label="Confirmado" />
                                    }
                                    {!aula.PresencaConfirmada && !aula.Encerrada && !aula.NaFila &&
                                      <>
                                        <Typography variant="body2" className={classes.textVagas}>Vagas</Typography>
                                        {aula.Presencas.length < aula.Capacidade ?
                                          <Chip color="primary" className={classes.vagas} label={`${aula.Presencas.length} / ${aula.Capacidade}`} />
                                          :
                                          <Chip color="primary" className={classes.textEncerrado} label={`Lotado / ${aula.Capacidade}`} />
                                        }
                                      </>
                                    }
                                    {!aula.Encerrada && aula.NaFila &&
                                    <Chip color='primary' className={classes.textConfirmado} label="Na fila" />
                                    }
                                    {aula.Encerrada &&
                                      <Chip color="primary" className={classes.textEncerrado} label="Encerrado" />
                                    }
                                  </div>
                                </div>

                              </Card>
                            </Grid>

                          ))}
                        </Grid>
                      </TabPanel>
                    ))}
                  </SwipeableViews>

                </Grid>
              </Grid>
            </Grid>
          </Content>
        </div>
      </div>
      <ScrollTop >
        <Fab color="primary" size="small" aria-label="Voltar ao topo">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
      <Dialog maxWidth="sm" fullWidth={true} onClose={handleCloseModal} aria-describedby="form-dialog-title" open={openModal}>

        <CardMedia
          className={classes.coverModal}
          image={aulaModal?.FotoAula}
          title={aulaModal?.NomeAula}
        />
        <Typography component="h5" variant="h5" className={classes.titleCoverModal}>{aulaModal?.NomeAula}</Typography>

        <ContentModal className={classes.contentModal}>
          {tenant.permissions.classesCheckin &&
            <>
              {verificando &&
                <Button disabled={true} variant="contained" className={classNames(classes.btnRegister, classes.buttonRed)} >
                  Verificando
                  <CircularProgress size={24} className={classes.buttonProgress} />
                </Button>
              }
              {verificacao && (verificacao?.Retorno == 13 || verificacao?.Retorno == 12) &&
                <>
                  {verificacao.PossuiMapa == false &&
                      <Button disabled={loading} variant="contained" color="primary" className={classes.btnRegister} onClick={handleAulaParticipar}>
                        Participar
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Button>
                  }
                  {verificacao.PossuiMapa == true && posicao == null &&
                      <Button  variant="contained" color="default" className={classes.btnRegister} style={{cursor:'not-allowed'}}>
                        Escolha uma posição
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Button>
                  }
                  {verificacao.PossuiMapa == true &&posicao!=null&&posicao.Id>0 &&
                      <Button disabled={loading} variant="contained" color="primary" className={classes.btnRegister} onClick={handleAulaParticipar}>
                        Participar na posição {posicao.Label}
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Button>
                  }
                </>
              }
              {verificacao && verificacao?.Retorno == 18  &&
                <Button disabled={loading} variant="contained" color="primary" className={classNames(classes.btnRegister)} onClick={handleAulaParticipar}>
                 (Lotado) Entrar na fila
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              }
              {verificacao && verificacao?.Retorno == 19  &&
                <Button disabled={loading} variant="contained"  className={classNames(classes.btnRegister, classes.buttonRed)} onClick={handleAulaRemover}>
                 Sair da fila
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              }
              {verificacao && (verificacao?.Retorno == 1 || verificacao?.Retorno == 4) &&
                <Button disabled={loading} variant="contained" className={classNames(classes.btnRegister, classes.buttonRed)} onClick={handleAulaRemover}>
                  Remover presença
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              }
              {verificacao && verificacao?.Retorno != 1 && verificacao?.Retorno != 4 && verificacao?.Retorno != 13 && verificacao?.Retorno != 12 && verificacao?.Retorno != 18 && verificacao?.Retorno != 19 &&
                <Button variant="contained" color="primary" className={classNames(classes.btnRegister, classes.buttonRed)}  >
                  {verificacao?.Mensagem}
                </Button>
              }
            </>
          }

          <DialogContent className={classes.contentDialog} >
            <Grid container  direction="row"
              justify="center"
              alignItems="center" >
              <Grid item xs={12}   >
                {verificacao && verificacao.PossuiMapa == true && verificacao.Mapa &&
                  <div className={"resize-div"}  >
                    <div className={"resize-container"} >

                      {verificacao && verificacao?.Mapa.map(node => (
                        <div  className={"resize-drag round-button " + (filterMinhaPosicaoList(node.Id) ? classes.roundButtonPrimary : null) + " " + (filterReservadoList(node.Id) ? "reservado" : "") + " " + (posicao?.Id == node.Id ? "selecionado" : "")}
                          onClick={() => clickVaga(node)} data-id={node.Id} data-x={node.PositionX} data-y={node.PositionY}>
                          <span className={"round-button-circle"}>{node.Label}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                }
              </Grid>
            
              <Grid item xs={12}  >
                <div className={classes.section2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}  >
                      <Card>
                        <CardContent className={classes.cardText}>
                          <Typography gutterBottom variant="body2" component="p">
                            Data
                          </Typography>
                          {
                            aulaDiaModal &&
                            <Typography variant="h6" color="textPrimary" component="p">{format(parseISO(aulaDiaModal), 'dd/MM')}</Typography>
                          }
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}  >
                      <Card>
                        <CardContent className={classes.cardText}>
                          <Typography gutterBottom variant="body2" component="p">
                            Início
                          </Typography>
                          <Typography variant="h6" color="textPrimary" component="p">{aulaModal?.HorarioInicio.replace(':00', '')}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}  >
                      <Card>
                        <CardContent className={classes.cardText}>
                          <Typography gutterBottom variant="body2" component="p">
                            Vagas
                          </Typography>
                          <Typography variant="h6" color="textPrimary" component="p">{aulaModal?.Presencas.length}/{aulaModal?.Capacidade}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    {/* <Grid item xs={6} sm={3}  >
                  <Card>
                    <CardContent className={classes.cardText}>
                      <Typography gutterBottom variant="body2" component="p">
                        Sala
                     </Typography>
                      <Typography variant="h6" color="textPrimary" component="p">{aulaModal?.Sala}</Typography>
                    </CardContent>
                  </Card>
                </Grid> */}
                  </Grid>
                </div>
                <Divider variant="middle" />
                <div className={classes.section2}>
                  <Grid container>
                    {tenant.permissions.imagesPersons &&
                    <>
                    {aulaModal && aulaModal.Presencas?.length > 0 ?
                      <Grid item xs={12}>
                        <Typography component="p" variant="body2" className={classes.titleLigh}>Pessoas confirmadas</Typography>
                      </Grid>
                      :
                      <Grid item xs={12}>
                        <Typography component="p" variant="body2" className={classes.titleLigh}>Nenhuma presença confirmada ainda</Typography>
                      </Grid>
                    }
                    </>
                  }
                     {aulaModal && aulaModal.Presencas?.length > 0 && tenant.permissions.imagesPersons &&
                     
                    <AvatarGroup max={10}>
                      {aulaModal?.Presencas.map(item => (
                        <Avatar alt={item.Nome} src={item.AvatarUrl} />
                      ))}
                          </AvatarGroup>
                     }


                
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </ContentModal>

      </Dialog>
    </>
  )

}



export default List;
