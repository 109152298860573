import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import axios from 'axios';
import { Tenant } from '../models/Tenant/TenantDTO';
import { User } from '../models/Tenant/UserDTO';
import apiToken from '../services/apiToken';
import { useHistory } from 'react-router-dom';




interface SignInCredentials {
  email: string;
  password: string;
}
interface SignInQrCodeCredentials {
  qrcode: string;
}
interface SignInTenantCredentials {
  domain: string;
}
interface SignInTenantIdCredentials {
  idTenant: string;
}
interface AuthState {
  token: string;
  user: User;
}
interface AuthTenantState {
  tenant: Tenant;
}
interface AuthContextData {
  user: User;
  token: string;
  tenant: Tenant;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInQrCode(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User, token: string | null): void;
  updateTenant(tenant: Tenant): void;
  signInTenant(credentials: SignInTenantCredentials): Promise<void>;
  signInTenantId(credentials: SignInTenantIdCredentials): Promise<void>;
}


const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@HubfitPortal:token');
    const user = localStorage.getItem('@HubfitPortal:user');
    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`
      return { token, user: JSON.parse(user) };
    }
    return {} as AuthState;
  });
  const [dataTenant, setDataTenant] = useState<AuthTenantState>(() => {
    const tentant = localStorage.getItem('@HubfitPortal:tenant');
    if (tentant) {
      // api.defaults.headers.authorization = `Bearer ${token}`
      return { tenant: JSON.parse(tentant) };
    }
    return {} as AuthTenantState;
  });
  const signInQrCode = useCallback(async ( qrcode) => {

    const tenantString = localStorage.getItem('@HubfitPortal:tenant');
    if (tenantString) {
      const tenant = JSON.parse(tenantString);
      const response = await api.get('login/qrcode', { params:{
        qrcode: qrcode,
        idTenant:tenant.idTenant
      }});
      const { token } = response.data;
      localStorage.setItem('@HubfitPortal:token', token);

      api.defaults.headers.authorization = `Bearer ${token}`
      const responsePessoa = await api.get("users");
      localStorage.setItem('@HubfitPortal:user', JSON.stringify(responsePessoa.data));
      console.log(responsePessoa.data);

      setData({ token: token, user: responsePessoa.data });
    }
  }, []);
  const signIn = useCallback(async ({ email, password }) => {

    const tenantString = localStorage.getItem('@HubfitPortal:tenant');
    if (tenantString) {
      const tenant = JSON.parse(tenantString);
      const response = await apiToken.post('token', "grant_type=password&username=" + email + "&password=" + password + "&idTenant=" + tenant.idTenant + "&idBranch=" + tenant.idBranch);
      console.log(response.data);
      const { access_token, expires_in } = response.data;
      localStorage.setItem('@HubfitPortal:token', access_token);

      api.defaults.headers.authorization = `Bearer ${access_token}`
      const responsePessoa = await api.get("users");
      localStorage.setItem('@HubfitPortal:user', JSON.stringify(responsePessoa.data));
      console.log(responsePessoa.data);

      setData({ token: access_token, user: responsePessoa.data });

      const strTenant = localStorage.getItem("@HubfitPortal:tenant");
      if (strTenant) {
        const ObjTenant: Tenant = JSON.parse(strTenant);
        ObjTenant.idBranch = responsePessoa.data?.idBranch;
        updateTenant(ObjTenant);
      }

    }
  }, []);
  const signInTenantId = useCallback(async ({ idTenant }) => {
    const response = await api.get('tenant/portal', {
      params: {
        idTenant,
      }
    }).catch(error => {
      if(!error.response){
        throw new Error("Não foi possível conectar ao servidor.");
      }else if(error.response.status == "404"){
        throw new Error("Informações não encontradas.");
      }
      throw new Error(error.response.data.Message);
    });

    const tenantServ: Tenant = response.data;
    const tenantStr = localStorage.getItem('@HubfitPortal:tenant');
    console.log(tenantStr)
    if(tenantStr){
      const localTenant: Tenant = JSON.parse(tenantStr);
      if(localTenant.idBranch == undefined){
        tenantServ.idBranch = tenantServ.branchs[0]?.id;
      }else{
        tenantServ.idBranch =localTenant.idBranch ;
      }
    }else{
      tenantServ.idBranch = tenantServ.branchs[0]?.id;
    }




    localStorage.setItem('@HubfitPortal:tenant', JSON.stringify(tenantServ));


    setDataTenant({ tenant: tenantServ });
    const userLocal = localStorage.getItem('@HubfitPortal:user');

    if (userLocal) {
      const userObj: User = JSON.parse(userLocal);
      if (userObj.idTenant != tenantServ.idTenant) {
        await signOut();
      }
    }


  }, []);
  const signInTenant = useCallback(async ({ domain }) => {
    console.log(domain)
    const response = await api.get('tenant/portal', {
      params: {
        domain,
      }
    }).catch(error => {
      console.log()
      if(!error.response){
        throw new Error("Não foi possível conectar ao servidor.");
      }else if(error.response.status == "404"){
        throw new Error("Informações não encontradas.");
      }
      throw new Error(error.response.data.Message);
    });


    const tenantServ: Tenant = response.data;
    const tenantStr = localStorage.getItem('@HubfitPortal:tenant');
    if(tenantStr){
      const localTenant: Tenant = JSON.parse(tenantStr);
      if(localTenant.idBranch == undefined){
        tenantServ.idBranch = tenantServ.branchs[0]?.id;
      }else{
        tenantServ.idBranch =localTenant.idBranch ;
      }
    }else{
      tenantServ.idBranch = tenantServ.branchs[0]?.id;
    }

    localStorage.setItem('@HubfitPortal:tenant', JSON.stringify(tenantServ));
    setDataTenant({ tenant: tenantServ });
    const userLocal = localStorage.getItem('@HubfitPortal:user');

    if (userLocal) {
      const userObj: User = JSON.parse(userLocal);
      if (userObj.idTenant != tenantServ.idTenant) {
        await signOut();
      }
    }


  }, []);
  // const updateInsertUserToken = useCallback((user: User, token: string) => {
  //     console.log(user,token)
  //     setData({
  //       token,
  //       user,
  //     })
  //     localStorage.setItem('@HubfitPortal:token', token);
  //     localStorage.setItem('@HubfitPortal:user', JSON.stringify(user));

  //   api.defaults.headers.authorization = `Bearer ${token}`
  // },[setData, sessionStorage,api])

  const updateUser = useCallback((user: User, token: string) => {
    localStorage.setItem('@HubfitPortal:user', JSON.stringify(user));
    console.log(user, token)
    if (token) {
      localStorage.setItem('@HubfitPortal:token', token);
      setData({
        token: token,
        user,
      })
      api.defaults.headers.authorization = `Bearer ${token}`
    } else {
      setData({
        token: data.token,
        user,
      })
      api.defaults.headers.authorization = `Bearer ${data.token}`
    }

    const strTenant = localStorage.getItem("@HubfitPortal:tenant");
    if (strTenant) {
      const ObjTenant: Tenant = JSON.parse(strTenant);
      ObjTenant.idBranch = user?.idBranch;
      updateTenant(ObjTenant);
    }

  }, [setData, data.token])

  const updateTenant = useCallback((tenant: Tenant) => {
    localStorage.setItem('@HubfitPortal:tenant', JSON.stringify(tenant));
      setDataTenant({
        tenant
      })
  }, [setDataTenant])

  const signOut = useCallback(() => {
    localStorage.removeItem('@HubfitPortal:token');
    localStorage.removeItem('@HubfitPortal:user');
    sessionStorage.clear();
    setData({} as AuthState);
  }, []);
  return (
    <AuthContext.Provider value={{token: data.token, user: data.user, tenant: dataTenant.tenant, signIn, signOut, updateUser, signInTenant, signInTenantId,updateTenant, signInQrCode }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be uset within and AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };


