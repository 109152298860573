import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';

export const Content = styled.div`
  width: 100%;

    position: relative;
`;

const appearFromLeft = keyframes`
    from{
        opacity: 0;
        transform: translateX(-50px);

    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
`;



export const AnimationContainer = styled.div`

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromLeft} 1s;

form{
        margin: 80px 0;
        width:340px;
        text-align:center;
        h1{
            margin-bottom: 24px;
        }



        a{
            color: #f4ede8;
            display: block;
            margin-top: 24px;
            text-decoration: none;
            transition: color 0.2s;
            &:hover{
                color: ${shade(0.2,'#f4ede8')};
            }
        }
    }
    > a{
        color: #ff9000;
        display: block;
        margin-top: 24px;
        text-decoration: none;
        transition: color 0.2s;
        display: flex;
        align-items: center;
        svg{
            margin-right: 16px;
        }
        &:hover{
            color: ${shade(0.2,'#ff9000')};
        }
    }
`;

