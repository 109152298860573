import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
`;
export const Content = styled.div`

   padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  text-align: center;

  h1, h2, h3, h4, h5, h6 {
      flex-grow: 1;
      color: #3C4858;
      margin: 1.75rem 0 0.875rem;
      text-decoration: none;
      font-weight: 700;
    }

  @media (min-width: 1200px){
    /* max-width: 1140px; */
  }
    z-index: 2;
    position: relative;
    padding-top: 3vh;
    padding-bottom: 200px;
`;

const appearFromRight = keyframes`
 from{
        opacity: 0;
        transform: translateX(-50px);

    }
    to{
        opacity: 1;
        transform: translateX(0);
    }

`;
export const ContentInfo = styled.div`
  display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    justify-content: start;
    padding: 48px;

    Button{
      color:#f5f5f5;
      margin: 30px 0;

    }

`;

export const AnimationContainer = styled.div`

    display:flex;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromRight} 1s;


    > a{
        color: #ff9000;
        display: block;
        margin-top: 24px;
        text-decoration: none;
        transition: color 0.2s;
        display: flex;
        align-items: center;
        svg{
            margin-right: 16px;
        }
        &:hover{
            color: ${shade(0.2,'#ff9000')};
        }
    }
`;
export const Background = styled.div`
    flex:1;
    background: #f5f5f5;
    width:100%;
`;
