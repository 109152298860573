import React, { useState, useCallback, useEffect } from "react";



// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, Grid, createStyles, Theme, DialogProps, Backdrop, CircularProgress, Button } from "@material-ui/core";
import { useToast } from "../../hooks/toast";
import { useAuth } from '../../hooks/auth';
import { HelpOutline } from "@material-ui/icons";
import { blue } from "@material-ui/core/colors";
import { IClassItem } from "../../models/Classes/IClassesDTO";
import { useHistory } from "react-router-dom";
import Webcam, { WebcamProps } from "react-webcam";
import api from "../../services/api";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },
    message: {
      margin: '15px 0',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    searching: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    IconInfo: {
      color: blue[500],
      marginRight: '7px'
    },
    divInfo: {
      display: 'flex',
      margin: '10px 0',
    },
    videoMask: {
      width: '202px',
      height: '202px',
      borderRadius: '50%',
      overflow: 'hidden',
      margin: '0 auto',
      position:'relative',
    },
    divButtonCapture: {
      marginBottom: '22px',
    },
    textCountdown: {
      color: theme.palette.primary.main,
      fontSize: '10.2rem',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 100
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type BiometricProps = DialogProps & {

}
const videoConstraints = {
  width: 286,
  height: 202,
  facingMode: "user"
};
const DialogAvatar: React.FC<BiometricProps> = ({ ...rest }) => {
  const { onClose } = rest;
  const classes = useStyles();
  const { user, updateUser } = useAuth();
  const [permitiu, setPermitiu] = useState(false);
  const [salvando, setSalvando] = useState(false);
  const [carregou, setCarregou] = useState(false);
  const [take, setTake] = useState(false);
  const [open, setOpen] = useState(true);
  const [imgScreenshot, setImgScreenshot] = useState<string | undefined>();
  const { addToast } = useToast();
  const history = useHistory();
  const webcamRef = React.useRef<Webcam & HTMLVideoElement>(null);

  const capture = React.useCallback(() => {

    setTake(false);
    setImgScreenshot(undefined);
    var timeleft = 5;
    const obj = document.getElementById("countdown");
    if (obj) {
      console.log(timeleft)
      obj.innerHTML = timeleft + "";
    }
    var downloadTimer = setInterval(function () {
      timeleft -= 1;
      if (timeleft <= 0) {
        clearInterval(downloadTimer);

        const obj = document.getElementById("countdown");
        if (obj) {
          console.log("0")
          obj.innerHTML = "";
        }
        if (webcamRef != null) {
          if (webcamRef.current != null) {


            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
              setTake(true);
              setImgScreenshot(imageSrc);
            }
            console.log(imageSrc);
          }
        }
      } else {
        const obj = document.getElementById("countdown");
        if (obj) {
          console.log(timeleft)
          obj.innerHTML = timeleft + "";
        }
      }


    }, 1000);


  },
    [webcamRef]
  );
  const saveImage = useCallback(() => {
    if (imgScreenshot) {
      setSalvando(true);
      const data = new FormData();
      data.append('avatar', imgScreenshot);

      api.post('/users/avatar', data).then(response => {
        console.log(response.data);
        user.avatarURL = response.data;
        updateUser(user, null);
        addToast({
          type: 'success',
          title: 'Foto atualizada',
        });
        setTake(false);
        setImgScreenshot(undefined);
        setSalvando(false);
        setOpen(false);
        if (onClose) {
          onClose({}, 'backdropClick');
        }
      })
    }

  }, [imgScreenshot, user, history]);
  const errorVideo = useCallback(() => {
    setCarregou(true)
    setPermitiu(false);
  }, [setPermitiu])
  const Video = useCallback(() => {
    console.log('video')
    setCarregou(true)
    setPermitiu(true);
  }, [setPermitiu])

  return (
    <Dialog maxWidth="sm" fullWidth aria-describedby="form-dialog-title"  {...rest}>

      <DialogTitle id="confirmation-dialog-title">Foto</DialogTitle>

      <DialogContent dividers>
        {!permitiu && carregou &&
          <div className={classes.divInfo}>
            <HelpOutline fontSize="small" className={classes.IconInfo} />
          Nenhuma webcam instalada ou não foi permitido a utilização neste navegador.
      </div>
        }
        <div >
          <Grid direction="column"
            justify="center"
            alignItems="center" >
            <Grid item xs={12} >
              <Grid container direction="row" spacing={2}
                justify="center"
                alignItems="center" className={classes.divButtonCapture}>

                <Grid item xs={6} >

                  <Button disabled={salvando} fullWidth color="primary" variant="contained" onClick={capture}>Tirar foto</Button>
                </Grid>
                {take &&
                  <Grid item xs={6} >
                    <Button disabled={salvando} fullWidth color="primary" variant="outlined" onClick={saveImage}>Salvar
                    {salvando && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item xs={12} >
              <div className={classes.videoMask}>
                <div className={classes.textCountdown} id="countdown"></div>
                {!take &&
                  <Webcam
                    audio={false}
                    height={202}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={286}
                    videoConstraints={videoConstraints}

                    onUserMediaError={errorVideo}
                    onUserMedia={Video}
                    mirrored={true}
                  />
                }
                {take &&
                  <img src={imgScreenshot} alt="" />
                }
              </div>

            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}



export default DialogAvatar;
