import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';


export const ContentModal = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 800px;

display: flex;
`;
export const Content = styled.div`

   padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  h1, h2, h3, h4, h5, h6 {
      flex-grow: 1;
      color: #3C4858;
      margin: 1.75rem 0 0.875rem;
      text-decoration: none;
      font-weight: 700;
    }


   /* @media (min-width: 576px){
    max-width: 800px
  }
  @media (min-width: 600px){
    max-width: 550px
  }
  @media (min-width: 802px){
    max-width: 595px
  } */
  /* @media (min-width: 576px){
    max-width: 800px
  } */
  @media (min-width: 1200px){
    /* max-width: 1140px; */
  }
    z-index: 2;
    position: relative;
    padding-top: 4vh;
    /* padding-bottom: 200px; */
`;
export const GridFooterCard = styled.div`
  p{
    margin: 4px 0 4px !important;
  }
`;
export const ButtonsList = styled.div`

    Button {
      padding: 23px 55px;
      span{
      display: flex !important;
      flex-direction: column !important;
      .MuiSvgIcon-root{
        font-size: 2.5rem;
      }
    }

    }

`;
