import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';


export const ContentModal = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 800px;

display: flex;
`;

export const GridFooterCard = styled.div`
  p{
    margin: 4px 0 4px !important;
  }
`;
export const ButtonsList = styled.div`

    Button {
      padding: 23px 55px;
      span{
      display: flex !important;
      flex-direction: column !important;
      .MuiSvgIcon-root{
        font-size: 2.5rem;
      }
    }

    }

`;
