import React, { useState, useCallback, useEffect } from "react";



// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, Grid, createStyles, Theme, DialogProps, Backdrop, CircularProgress, Button, FormControl, InputLabel, Select, Typography, MenuItem, IconButton } from "@material-ui/core";
import { useToast } from "../../hooks/toast";
import { useAuth } from '../../hooks/auth';
import api from "../../services/api";
import { IBranchModel, Tenant } from "../../models/Tenant/TenantDTO";
import { Close } from "@material-ui/icons";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divCenter: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    GridList: {
      margin: '20px 0',
    },
    formControl: {
      margin: '48px 0',
      minWidth: 266,
    },
    buttonClick: {
      color: theme.palette.primary.main,
      fontSize: '1.2rem',
      padding: '0',
      textTransform: 'none',
    },
    closeButton:{
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    }
  })

);




const DialogUnidade: React.FC = ({ ...rest }) => {
  //const { onClose } = rest;
  const classes = useStyles();
  const { tenant, updateTenant } = useAuth();
  const { addToast } = useToast();
  const [unidades, setUnidades] = useState<IBranchModel[]>();
  const [unidadeSel, setUnidadeSel] = useState<IBranchModel | undefined>();
  const [openService, setOpenService] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  useEffect(() => {
    if (tenant) {
      setUnidades(tenant.branchs);
      if (tenant.idBranch) {
        setUnidadeSel(tenant.branchs.find(e => e.id == tenant.idBranch));
      }
    }
  }, [tenant, tenant.name])

  const handleServiceClose = useCallback(() => {
    setOpenService(false);
  }, [])

  const handleServiceOpen = useCallback(() => {
    setOpenModal(true);
    setOpenService(true);
  }, []);


  const handleChangeService = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    console.log(event)
    console.log(unidades?.filter((item) => item.id == event.target.value)[0])
    const unidadeNova = unidades?.filter((item) => item.id == event.target.value)[0];
    setUnidadeSel(unidadeNova);
    const strTenant = localStorage.getItem("@HubfitPortal:tenant");
    if (strTenant) {
      const ObjTenant: Tenant = JSON.parse(strTenant);
      ObjTenant.idBranch = unidadeNova?.id;
      updateTenant(ObjTenant);
    }
    setOpenService(false);
    setOpenModal(false);
  }, [unidades, updateTenant]);

  const handleCloseModalBranch = useCallback(() => {
    setOpenModal(false);
  },[]);

  return (
    <>
      {tenant.branchs.length > 1 &&
        <Button className={classes.buttonClick} onClick={handleServiceOpen}>{unidadeSel?.name}</Button>
      }
      <Dialog  maxWidth="sm" fullWidth open={openModal} onClose={handleCloseModalBranch}   >

        <DialogTitle id="confirmation-dialog-title" onClick={handleCloseModalBranch}>
          Unidades
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModalBranch}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>

          <div >
            <Grid direction="column"
              justify="center"
              alignItems="center" >

              <Grid item xs={12} >
                <div className={classes.divCenter}>

                  <Typography variant="h4" color="primary">Selecione uma unidade</Typography>


                  <FormControl className={classes.formControl}>

                    <Select
                      labelId="unidade"
                      id="unidade"
                      open={openService}
                      onClose={handleServiceClose}
                      onOpen={handleServiceOpen}
                      value={unidadeSel?.id}
                      onChange={handleChangeService}
                    >
                      {unidades?.map((item) => (
                        <MenuItem key={item.id} value={item.id} >{item.name}</MenuItem>
                      ))}

                    </Select>
                  </FormControl>

                </div>


              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}



export default DialogUnidade;
