import React, { useEffect, useState, useCallback } from 'react';


import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';
import { makeStyles, createStyles, Theme, Grid, CircularProgress, Paper, Typography, Button } from '@material-ui/core';
import Content from '../../components/Content';

import Lottie from 'react-lottie';
import animationData from '../../assets/animations/success.json';
import animationDataError from '../../assets/animations/error.json';
import { Helmet } from 'react-helmet';
import InnerHTML from 'dangerously-set-html-content';


const useStyles = makeStyles((theme: Theme) =>



  createStyles({
    feedback: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
    },
    GridList: {
      margin: '28px 0 !important',
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",
    },
    Paper: {
      padding: '10px',
      marginBottom: '26px',
    },
    textMessage: {
      textAlign: 'center',
    },
    textMessageSub: {
      textAlign: 'center',
      color: theme.palette.text.disabled,
      marginBottom: '20px',
    },
    textMessageCustom: {
      textAlign: 'center',
      marginBottom: '20px',
    }
  })
);



type RouteParams = {
  id: string
}
type BookDetailProps = RouteComponentProps<RouteParams>;



const LandingPage: React.FC<BookDetailProps> = ({ match }) => {
  const classes = useStyles();
  const [loadingData, setLoadingData] = useState(false);
  const [htmlData, setHtmlData] = useState<any>();
  const [enviadoForm, setEnviadoForm] = useState<boolean>(false);
  const [agradecimento, setAgradecimento] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [enviadoFormOk, setEnviadoFormOk] = useState<boolean>(false);
  const [isStopped, setIsStopped] = useState<boolean>(true);
  const { tenant } = useAuth();
  const { addToast } = useToast();

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
  };
  const defaultOptionsError = {
    loop: false,
    autoplay: false,
    animationData: animationDataError,
  };
  useEffect(() => {
    const idLp = match.params.id;
    if (idLp && tenant) {
      setLoadingData(true);
      api.get('/landingpage', {
        params: {
          name: idLp,
          idt: tenant?.idTenant,
        }
      }
      ).then(retorno => {
        setIsStopped(false);
        setLoadingData(false);
        setHtmlData(retorno.data);
        setTimeout(() => {
          const elem = document.getElementById('conversion-form');
          var celular = document.getElementById('celular');
          var botao = document.getElementById('cf_submit') as HTMLInputElement;
          // console.log(document.getElementById('featured-content'));
          if (elem) {
            elem.onsubmit = function (event) {
              event.preventDefault();
              botao.value = "Enviando...";
              botao.disabled = true;
              botao.classList.add("disabled");
              setTimeout(function () {
                let model = {
                  name: (document.getElementById('name') as HTMLInputElement).value,
                  email: (document.getElementById('email') as HTMLInputElement).value,
                  celular: (document.getElementById('celular') as HTMLInputElement).value,
                  cpf: (document.getElementById('cpf') as HTMLInputElement).value,
                  idTenant: tenant?.idTenant,
                  identificador: idLp,
                }

                api.post('/landingpage/conversions', model).then(conversao => {
                  setEnviadoForm(true);
                  setEnviadoFormOk(!conversao.data.jaCadastrado);
                  botao.disabled = false;
                  if (conversao.data.erro) {
                    setEnviadoFormOk(false);
                    setMensagem(conversao.data.mensagem)
                  } else {
                    setMensagem("Encontramos outro cadastro com as mesmas informações.");
                  }
                  if (retorno.data.agradecimento) {
                    setAgradecimento(retorno.data.agradecimento.replace(/\r?\n/g, '<br/>'));
                  }
                }).catch(err => {
                  // console.log(err.response);
                  setEnviadoForm(true);
                  setEnviadoFormOk(false);
                });
                // lp.conversion(model).then(conversao => {
                //   geral.enviado = true;
                //   geral.ok = !conversao.jaCadastrado;
                //   botao.disabled = false;
                //   if (conversao.erro) {
                //     geral.ok = false;
                //     geral.mensagem = conversao.mensagem;
                //   } else {
                //     geral.mensagem = "Encontramos outro cadastro com as mesmas informações.";
                //   }
                //   if (retorno.agradecimento != null && retorno.agradecimento != "") {
                //     geral.agradecimento = retorno.agradecimento.replace(/\r?\n/g, '<br/>');
                //   }

                // }).catch(err => {
                //   console.log(err);
                //   geral.ok = false;
                // });
                return false;
              }, 3000);
            }
          }

        }, 500);
      }).catch(err => {

        addToast({
          type: "error",
          title: 'Erro',
          description: 'Não foi possível se conectar ao servidor',
        });
      })
    }
  }, [tenant?.idTenant]);

  const returnLp = useCallback(() => {
    window.location.reload();
  }, [])

  return (
    <>
      {htmlData && htmlData.scripts &&
        <InnerHTML html={htmlData?.scripts} />
      }
      <Helmet>
        <title>Cadastro - {tenant?.name}</title>
      </Helmet>
      {loadingData &&
        <Grid container direction="column"
          justify="center"
          alignItems="center" >
          <Grid item xs={12} className={classes.GridList}>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      }
      {!loadingData && enviadoFormOk == true && enviadoForm == true &&
        <div className={classes.mainRaised}>
          <Content>
            <Grid justify="center">
              <Grid item xs={12} sm={12} >
                <div>
                  <Lottie options={defaultOptions}
                    height={150}
                    width={150}
                    speed={0.6}
                    isStopped={isStopped}
                  />
                  <Grid container direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={10} md={8} lg={6} >
                      <Paper elevation={1} className={classes.Paper} >
                        <Grid container alignItems="center" justify="center">
                          <Grid item xs={12} className={classes.textMessage}>
                            <Typography gutterBottom variant="h5" >
                              Cadastro realizado
                            </Typography>
                          </Grid>
                          {/* <Grid item xs={12} className={classes.textMessageSub}>
                            <Typography gutterBottom variant="subtitle1" component="p" >
                              Enviamos em seu e-mail algumas informações!
                            </Typography>
                          </Grid> */}
                          {agradecimento &&
                            <Grid item xs={12} className={classes.textMessageCustom}>
                              {ReactHtmlParser(agradecimento)}
                            </Grid>
                          }
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      }

      {!loadingData && !enviadoFormOk && enviadoForm == true &&
        <div className={classes.mainRaised}>
          <Content>
            <Grid justify="center">
              <Grid item xs={12} sm={12} >
                <div>
                  <Lottie options={defaultOptionsError}
                    height={150}
                    width={150}
                    speed={0.6}
                    isStopped={isStopped}
                  />
                  <Grid container direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={10} md={8} lg={6} >
                      <Paper elevation={1} className={classes.Paper} >
                        <Grid container alignItems="center" justify="center">
                          <Grid item xs={12} className={classes.textMessage}>
                            <Typography gutterBottom variant="h5" >
                              Cadastro não realizado!
                            </Typography>
                          </Grid>
                          {mensagem &&
                            <Grid item xs={12} className={classes.textMessageCustom}>
                              {ReactHtmlParser(mensagem)}
                            </Grid>
                          }
                          <Grid item xs={12} className={classes.textMessageCustom}>
                            <Button variant="contained" onClick={returnLp}>Voltar</Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      }

      <div>
        {!loadingData && enviadoForm == false && htmlData && htmlData.html &&
          ReactHtmlParser(htmlData.html)
        }
      </div>



    </>
  );

}
export default withRouter(LandingPage);
