import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';

export const Content = styled.div`

   padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 800px;

display: flex;
`;

const appearFromLeft = keyframes`
    from{
        opacity: 0;
        transform: translateX(-50px);

    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
`;

export const CardTextHeader = styled.div`
  margin: 20px 0 20px;
  h2{
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
export const FooterCard = styled.div`

`;
export const AnimationContainer = styled.div`

    /* display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */

    animation: ${appearFromLeft} 1s;

`;
