import React from "react";
import {CardDiv} from './styles';



const Card= ({children,  ...rest }) => {
  return (
    <CardDiv {...rest}>
      {children}
    </CardDiv>
  );
}

export default Card;
