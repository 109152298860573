import React from 'react';

import {ContentDiv} from './styles';


const Content = ({children, ...rest}) => {
    return (
        <ContentDiv {...rest}>
            {children}
        </ContentDiv>
    )
}
export default Content;
