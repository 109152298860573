
import styled, {keyframes} from 'styled-components';


export const Content = styled.div`

padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
width: 100%;

@media (min-width: 1200px){
 max-width: 1140px;
}
 z-index: 2;
 position: relative;
 padding-top: 10vh;
 padding-bottom: 200px;
`;

export const CardTextHeader = styled.div`
  margin: 20px 0 20px;
  h2{
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const appearFromLeft = keyframes`
    from{
        opacity: 0;
        transform: translateX(-50px);

    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
`;
export const AnimationContainer = styled.div`


    animation: ${appearFromLeft} 1s;

`;
