import React from "react";
import{CardHeaderDivBody} from './styles';
import { useTheme, makeStyles, createStyles, Theme } from '@material-ui/core/styles';





const CardHeader= ({children,  ...rest }) => {
  const theme = useTheme();
  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    HeaderCard:{
      background: `linear-gradient(60deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    }
  }),
  );
   const classes = useStyles();




  return (
    <CardHeaderDivBody  {...rest}  className={classes.HeaderCard}>
      {children}
    </CardHeaderDivBody>
  );
}

export default CardHeader;

