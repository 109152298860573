import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import api from '../../../../services/api';
import { User, TypeUser } from '../../../../models/Tenant/UserDTO';
import { useAuth } from '../../../../hooks/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, CircularProgress, makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>



  createStyles({

    GridList: {
      margin: '50px 0 !important',
    },

  })
);


const PaymentPendingConfirmation: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    setLoading(true);
    const parms = queryString.parse(location.search);
    if (parms.o && parms.idt) {
      api.get('/payments/pending/debitconfirm', {
        params: {
          o: parms.o,
          idt: parms.idt,
        }
      }).then((response) => {
        setLoading(false);
        console.log(response.data);
        if (response.data.ok == true) {

          if (response.data.habilitaAgendamento) {
            sessionStorage.setItem('@HubfitPortal:habilitaAgendamento', 'true');
          }

          history.push('/payments/checkout/success');
        } else {
          setLoading(false);
          sessionStorage.setItem('@HubfitPortal:errorPayment', response.data.msg);
          history.push('/payments/checkout/success');
        }
      }).catch(err => {
        setLoading(false);
        console.log(err.response)
        sessionStorage.setItem('@HubfitPortal:errorPayment',err.response.data.Message);
        history.push('/payments/checkout/success');
      })
    }
  }, []);


  return (
    <>

      {loading &&
        <Grid container direction="column"
          justify="center"
          alignItems="center" >
          <Grid item xs={12} className={classes.GridList}>
            <CircularProgress size={40} />
          </Grid>
          Verificando pagamento, Aguarde...
        </Grid>
      }
    </>
  )

};
export default PaymentPendingConfirmation;
