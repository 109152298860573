import React, { useCallback, useEffect, useRef } from 'react';
import { makeStyles, Theme, createStyles, FormControl, Grid, InputLabel, Input, FormHelperText, Button, CircularProgress,Typography,Paper } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import animationData from '../../../assets/animations/success.json';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../../services/api';
import queryString from 'query-string';
import { Content, AnimationContainer, CardTextHeader } from './styles';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Card from "../../../components/Card/Card";
import CardHeaderWhite from '../../../components/Card/CardHeaderWhite';
import CardBody from '../../../components/Card/CardBody';
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import Lottie from 'react-lottie';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: '60px 0 0',
      width: '100%',
      position: 'relative',
    },
    cardPadding: {
      padding: '16px',
    },
    Paper: {
      padding: '20px',
      marginBottom: '26px',
    },

  })
);

interface SignUpFormData {
  password: string;
  password_confirmation: string;
}

interface IFormChangePassword {
  senha: string;
  idTenant: string;
  token: string;
}

const RecoveryPassword: React.FC = () => {
  const classes = useStyles();
  const { tenant } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [isStopped, setIsStopped] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [token, setToken] = React.useState<string>();
  const { addToast } = useToast();
  let location = useLocation();
  const passwordRef = useRef<HTMLInputElement | null>(null);

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
  };

  useEffect(() => {
    setTimeout(() => {
      passwordRef.current?.focus();
    }, 1000)
    const parms = queryString.parse(location.search);
    if (parms.ts) {
      setToken(parms.ts.toString());
    }
  }, [tenant])

  const onSubmitSignupPassword = useCallback((data: SignUpFormData) => {


    setLoading(true);

    if (data.password && token) {
      const formCreate: IFormChangePassword = {
        senha: data.password,
        idTenant: tenant.idTenant,
        token: token,
      }

      api.put('/login/changepasswordtoken', formCreate).then((response) => {

        setLoading(false);
        setSuccess(true);
        setIsStopped(false);
      }).catch((error) => {
        setLoading(false);

        addToast({
          type: "error",
          title: 'Ops...',
          description: "Não foi possível trocar a senha",
        });
      });
    }
  }, [token, tenant]);

  let RegisterSignUpPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Senha obrigatória'),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref('password'), undefined], 'Senha diferente da primeira',
    )
  })

  const formPasswordSignUp = useForm<SignUpFormData>({
    resolver: yupResolver(RegisterSignUpPasswordSchema)
  });

  return (
    <>
    {!success &&
      <Content>
        <Helmet>
          <title>Portal do aluno - {tenant?.name}</title>
        </Helmet>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={5}>
            <AnimationContainer>
              <Card>
                <CardHeaderWhite >
                  <img src={tenant?.url_Logo} alt={tenant?.name} />
                </CardHeaderWhite>
                <CardBody>
                  <CardTextHeader>
                    <h3>Digite a nova senha</h3>
                  </CardTextHeader>
                  <form onSubmit={formPasswordSignUp.handleSubmit(onSubmitSignupPassword)}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} >
                        <FormControl fullWidth error={!!formPasswordSignUp.errors.password?.message}>
                          <InputLabel htmlFor="password">Nova senha</InputLabel>
                          <Input name="password" id="password" type="password"
                            inputRef={(e) => {
                              formPasswordSignUp.register(e); passwordRef.current = e
                            }} autoComplete="off" />
                          <FormHelperText>{formPasswordSignUp.errors.password?.message}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>

                        <FormControl fullWidth error={!!formPasswordSignUp.errors.password_confirmation?.message}>
                          <InputLabel htmlFor="password_confirmation">Confirmar senha</InputLabel>
                          <Input name="password_confirmation" id="password_confirmation" type="password" inputRef={formPasswordSignUp.register} autoComplete="off" />
                          <FormHelperText>{formPasswordSignUp.errors.password_confirmation?.message}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <div className={classes.wrapper}>
                      <Button disabled={loading} type="submit" fullWidth variant="contained" color="primary" size="large">
                        Salvar
                </Button>
                      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                  </form>
                </CardBody>
              </Card>

            </AnimationContainer>

          </GridItem>
        </GridContainer>
      </Content>
}
     {!loading && success &&
        <Grid item xs={12} sm={12} >
          <div >
            <div>
              <Lottie options={defaultOptions}
                height={115}
                width={115}
                speed={0.6}
                isStopped={isStopped}
              />

            </div>
            <Grid container direction="row"
              justify="center"
              alignItems="center">
              <Grid className={classes.cardPadding}>
                <Paper elevation={1} className={classes.Paper} >
                  <Grid container alignItems="center">

                    <Grid item xs >

                      <Typography gutterBottom variant="h6" >
                        Senha alterada com sucesso.
                              </Typography>

                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
      }
    </>
  );

};

export default RecoveryPassword;
