import React, { useCallback, ChangeEvent, useState, useEffect } from 'react';
import axios from 'axios';
import classNames from "classnames";
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Button, Avatar, Grid, Badge, Card, CardContent, Divider, List, ListItem, ListItemText, Breadcrumbs, Link, Paper, Chip, Tooltip, CircularProgress } from "@material-ui/core";
import { Assignment, PersonPinCircle, MonetizationOn, AccountBox, PowerSettingsNew, Event, AddShoppingCart, CameraAlt, Fingerprint, FitnessCenter, Today, AlarmOn } from '@material-ui/icons';

import profile from "../../assets/img/christian.jpg";
import Header from '../../components/Header';

import { useToast } from '../../hooks/toast';
import { shade } from 'polished';
import DialogAvatar from '../../components/DialogAvatar/Index';
import Content from '../../components/Content';
import { green, orange, red, blue } from '@material-ui/core/colors';
import api from '../../services/api';
import { UserPerfil } from '../../models/Perfil';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import NumberFormat from 'react-number-format';
import { TypeUser } from '../../models/Tenant/UserDTO';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    titleName: {
      marginTop: '15px',
    },
    right: {
      marginLeft: "auto",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    main: {
      position: "relative",
      zIndex: 3,
      background: "rgba(255, 255, 255, 0.9)",
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",

    },
    imgFluid: {
      maxWidth: "100%",
      height: "auto"
    },
    imgRaised: {
      boxShadow:
        "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgRoundedCircle: {
      borderRadius: "50% !important"
    },
    profile: {
      textAlign: "center",
      // "& img": {
      //   maxWidth: "160px",
      //   width: "100%",
      //   margin: "0 auto",
      //   transform: "translate3d(0, -50%, 0)"
      // }
    },

    margin5: {
      margin: "5px"
    },
    margin: {
      margin: '8px 0',
      padding: '23px 55px',
      '& span': {
        display: 'flex !important',
        flexDirection: 'column !important',
        '& .MuiSvgIcon-root': {
          fontSize: '2.5rem',
        }
      }
    },
    large: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    avatar: {
      maxWidth: "160px",

      margin: "0 auto",
    },


    avatarDiv: {
      position: 'relative',
      alignSelf: 'center',
    },
    inputAvatar: {
      display: 'none',
    },
    labelAvatar: {
      position: 'absolute',
      width: '48px',
      height: '48px',
      background: '#e6e6e6',
      borderRadius: '50%',
      right: '-11',
      bottom: '-10',
      border: '0',
      transition: 'background-color 0.2s',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        width: '20px',
        height: '20px',
        color: '#312e38',
      },
      '&:hover': {
        background: shade(0.2, '#e6e6e6'),
      }
    },
    btnFinger: {
      zIndex: 1,
      right: '-59px',
      bottom: '-4px',
    },
    listPlans: {
      marginBottom: '15px',
    },
    cardPlan: {
      borderLeft: "3px solid" + green[500],
    },
    cardPlanSuspense: {
      borderLeft: "3px solid" + orange[500],
    },
    cardPlanFuture: {
      borderLeft: "3px solid" + blue[500],
    },
    cardPlanInative: {
      borderLeft: "3px solid" + red[500],
    },
    titlePlan: {
      margin: '0 !important',
      color: theme.palette.text.secondary,
      textAlign: 'left',
    },
    content: {
      flex: '1 0 auto',
    },
    planActive: {
      backgroundColor: green[500],
      color: '#fff',
    },
    planFuture: {
      backgroundColor: blue[500],
      color: '#fff',
    },
    planInative: {
      backgroundColor: red[500],
      color: '#fff',
    },
    planSuspense: {
      backgroundColor: orange[500],
      color: '#fff',
    },
    infoPerfil: {
      textAlign: 'left',
    },
    saldoDevedor: {
      backgroundColor: red[500],
      color: '#fff',
      '&:hover': {
        backgroundColor: red[800],
      }
    },
    textLeft: {
      textAlign: 'left',
      marginLeft: '15px',
    },
    GridList: {
      margin: '20px 0',
    }
  },


  ),
);

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const { user, tenant, signOut, updateUser } = useAuth();
  const [userPerfil, setUserPerfil] = useState<UserPerfil>();
  const [openModalAvatar, setOpenModalAvatar] = useState(false);
  const [loading, setLoading] = useState(false);
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    setLoading(true);
    api.get("users/perfil").then(response => {
      const userPerfil = response.data;
      setUserPerfil(userPerfil);
    }).catch(err => {
      if (err.response?.status == 401) {
        addToast({
          type: "error",
          title: 'Sessão encerrada',
          description: 'Sua sessão expirou',
        });
        signOut();

      } else {
        addToast({
          type: "error",
          title: 'Erro',
          description: 'Não foi possível conectar ao servidor',
        });
        signOut();
      }
    }).finally(() => {
      setLoading(false);
    })
  }, []);

  const handleAvatarChange = useCallback(() => {
    setOpenModalAvatar(true);

  }, [addToast, updateUser])

  const handleClickShop = useCallback(() => {
    history.push('/plans/list');
  }, [history]);
  const handleClickClasses = useCallback(() => {
    history.push('/classes/list');
  }, [history]);

  const handleClickAppointments = useCallback(() => {
    history.push('/appointments');
  }, [history]);

  const handleClickPayments = useCallback(() => {
    history.push('/payments/pending');
  }, [history]);
  const handleClickTraining = useCallback(() => {
    history.push('/training');
  }, [history])
  const handleCloseModalAvatar = useCallback(() => {
    setOpenModalAvatar(false);
  }, [])
  const handleClickSaldo = useCallback(() => {
    history.push('/payments/pending');
  }, [history])


  return (
    <>
      <Header removeBack avatar={true} tabIndex={0} />

      <div className={classNames(classes.mainRaised)}>
        <div>
          <Content>

            <Grid container
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <Breadcrumbs id="titleBreadcrums" aria-label="breadcrumb">
                  <Link color="inherit" href={`/${tenant.domain}`}>
                    Portal
  </Link>

                  <Typography variant="h6" color="primary"  >Perfil</Typography>
                </Breadcrumbs>
                <div className={classes.profile}>


                  <Grid spacing={3} container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start">


                    <Grid item xs={12} sm={4} >
                      <Card className={classes.root} variant="outlined">
                        <CardContent>
                          <Badge

                            overlap="circle"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            badgeContent={

                              tenant.permissions.changeAvatar &&
                              <label htmlFor="avatar" className={classes.labelAvatar} onClick={handleAvatarChange}>
                                <CameraAlt />

                              </label>

                            }
                          >

                            <Avatar alt={user.name} src={user.avatarURL} className={classNames(classes.large, classes.avatar)} />
                          </Badge>
                          <Typography color="primary" variant="h6" component="h2" className={classes.titleName}>
                            {user.name}
                          </Typography>

                          <Divider variant="fullWidth" />

                          <List>
                            <ListItem>
                              <ListItemText primary="E-mail" secondary={user.email} />
                            </ListItem>

                          </List>
                          <div className={classes.textLeft}>
                            {(userPerfil?.paymentPending != undefined && userPerfil?.paymentPending > 0) &&

                              <>

                                <Typography color="textSecondary" variant="subtitle1" >
                                  Saldo devedor
                         </Typography>
                                <Chip onClick={handleClickSaldo} className={classes.saldoDevedor} variant="default" label={<NumberFormat value={userPerfil?.paymentPending} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />} />
                              </>

                            }
                          </div>
                        </CardContent>
                        {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={8} className={classes.infoPerfil}>

                      {loading &&
                        <Grid container direction="column" className={classes.GridList}
                          justify="center"
                          alignItems="center" >
                          <Grid item xs={12} >
                            <CircularProgress size={26} />
                          </Grid>
                        </Grid>
                      }
                      {!loading &&
                        <>
                        {userPerfil?.type != TypeUser.Colaborador &&
                        <>
                          <Typography gutterBottom variant="h6" color="textSecondary"  >Planos</Typography>
                          <Grid className={classes.listPlans} container spacing={1} direction="row"
                            justify="flex-start"
                            alignItems="center" >

                            {userPerfil && (userPerfil?.plans.length > 0 || userPerfil?.credits.length > 0) ? (
                              <>
                                {userPerfil?.plans.map(plan => (
                                  <Grid key={plan.idPlanSale} item xs={12} md={6}  >
                                    <Card className={plan.status == "Ativo" ? (plan.suspended ? classes.cardPlanSuspense : classes.cardPlan) : plan.status == "Na fila" ? classes.cardPlanFuture : classes.cardPlanSuspense}  >
                                      <CardContent className={classes.content}>
                                        <Grid container spacing={1} direction="row"
                                          justify="flex-start"
                                          alignItems="center" >
                                          <Grid item xs={12} >
                                            <Typography color="primary" variant="h6" className={classes.titlePlan}>{plan.description}</Typography>
                                          </Grid>
                                          <Grid item xs={12} >
                                            <Grid container spacing={1} direction="row"
                                              justify="flex-start"
                                              alignItems="center" >
                                              <Grid item  >
                                                {plan.status == "Ativo" && plan.suspended == false &&
                                                  <Tooltip title="Plano ativo">
                                                    <Chip size="small" label={plan.status} className={classes.planActive} />
                                                  </Tooltip>
                                                }
                                                {plan.status == "Ativo" && plan.suspended == true &&
                                                  <Tooltip title="Plano suspenso">
                                                    <Chip size="small" label="Suspenso" className={classes.planSuspense} />
                                                  </Tooltip>
                                                }
                                                {plan.status == "Na fila" &&
                                                  <Tooltip title={`O plano irá iniciar dia ${format(parseISO(plan.start), 'dd/MM/yyyy', { locale: ptBR })}`}>
                                                    <Chip size="small" label={plan.status} className={classes.planFuture} />
                                                  </Tooltip>
                                                }
                                                {plan.status == "Sessões realizadas" &&
                                                  <Tooltip title="As sessões encerraram para este plano">
                                                    <Chip size="small" label={plan.status} className={classes.planFuture} />
                                                  </Tooltip>
                                                }
                                                {plan.status == "Suspenso" &&
                                                  <Tooltip title="Status">
                                                    <Chip size="small" label={plan.status} className={classes.planSuspense} />
                                                  </Tooltip>
                                                }
                                              </Grid>
                                              {plan.status == "Na fila" &&
                                                <Grid item  >
                                                  <Tooltip title="Data de início">
                                                    <Chip size="small" color="default" variant="outlined" label={format(parseISO(plan.start), 'dd/MM/yyyy', { locale: ptBR })} icon={<AlarmOn />} />
                                                  </Tooltip>
                                                </Grid>
                                              }
                                              <Grid item  >
                                                <Tooltip title="Data de vencimento">
                                                  <Chip size="small" color="default" variant="outlined" label={format(parseISO(plan.valid), 'dd/MM/yyyy', { locale: ptBR })} icon={<Today />} />
                                                </Tooltip>
                                              </Grid>
                                              <Grid item  >
                                                {plan.sectionsToDo > 0 &&
                                                  <Tooltip title="Sessões restantes para realizar">
                                                    <Chip size="small" color="default" variant="outlined" label={plan.sectionsToDo > 1 ? `${plan.sectionsToDo} sessões` : `${plan.sectionsToDo} sessão`} />
                                                  </Tooltip>
                                                }
                                              </Grid>
                                            </Grid>
                                          </Grid>


                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                ))
                                }
                                {userPerfil?.credits.map(plan => (
                                  <Grid item xs={12} md={6}  >
                                    <Card className={classes.cardPlan}  >
                                      <CardContent className={classes.content}>
                                        <Grid container spacing={1} direction="row"
                                          justify="flex-start"
                                          alignItems="center" >
                                          <Grid item xs={12} >
                                            <Typography color="primary" variant="h6" className={classes.titlePlan}>{plan.quantity > 1 ? `${plan.quantity} créditos` : `${plan.quantity} crédito`}</Typography>
                                          </Grid>
                                          <Grid item xs={12} >
                                            <Grid container spacing={1} direction="row"
                                              justify="flex-start"
                                              alignItems="center" >
                                              <Grid item  >
                                                <Tooltip title="Status">
                                                  <Chip size="small" label="Ativo" className={classes.planActive} />
                                                </Tooltip>
                                              </Grid>
                                              <Grid item  >
                                                <Tooltip title="Vencimento">
                                                  {plan.valid ?
                                                    <Chip size="small" color="default" variant="outlined" label={format(parseISO(plan.valid), 'dd/MM/yyyy', { locale: ptBR })} icon={<Today />} />
                                                    :
                                                    <Chip size="small" color="default" variant="outlined" label="Sem vencimento" />
                                                  }
                                                </Tooltip>
                                              </Grid>
                                            </Grid>
                                          </Grid>


                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>

                                ))
                                }
                              </>
                            ) : (
                                <Grid item xs={12} md={6}  >
                                  <Card className={classes.cardPlanInative}  >
                                    <CardContent className={classes.content}>
                                      <Grid container spacing={1} direction="row"
                                        justify="flex-start"
                                        alignItems="center" >
                                        <Grid item xs={12} >
                                          <Typography color="primary" variant="h6" className={classes.titlePlan}>Nenhum plano ativo</Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                          <Grid container spacing={1} direction="row"
                                            justify="flex-start"
                                            alignItems="center" >
                                            <Grid item  >
                                              <Tooltip title="Status">
                                                <Chip size="small" label="Inativo" className={classes.planInative} />
                                              </Tooltip>
                                            </Grid>

                                          </Grid>
                                        </Grid>


                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              )
                            }
                          </Grid>
</>
}
                          <Typography gutterBottom variant="h6" color="textSecondary"  >Informações</Typography>
                          <Paper variant="outlined">
                            <List>
                              <ListItem>
                                <ListItemText primary="Nome" secondary={userPerfil?.name} />
                              </ListItem>
                              <ListItem>
                                <ListItemText primary="CPF" secondary={userPerfil?.cpf} />
                              </ListItem>
                              <ListItem>
                                <ListItemText primary="Endereço" secondary={userPerfil?.address} />
                              </ListItem>
                              <ListItem>
                                <ListItemText primary="Cidade" secondary={userPerfil?.city} />
                              </ListItem>

                            </List>


                          </Paper>
                        </>
                      }
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      </div>
      <DialogAvatar onClose={handleCloseModalAvatar} open={openModalAvatar} />

    </>
  )

}



export default Dashboard;
