import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from './styles';
import classNames from "classnames";
import Lottie from 'react-lottie';
import animationData from '../../../../assets/animations/success.json';
import animationDataError from '../../../../assets/animations/error.json';


import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography, Divider } from '@material-ui/core';
import { ArrowBackIos, Today, PowerSettingsNew, ArrowForwardIos, Help, Error as ErrorIcon } from '@material-ui/icons';
import GridContainer from '../../../../components/Grid/GridContainer'
import GridItem from '../../../../components/Grid/GridItem'
import Header from '../../../../components/Header';
import { useAuth } from '../../../../hooks/auth';
import NumberFormat from 'react-number-format';
import { FormPaymentEnum } from '../../../../models/Payments/IPaymentSaleDTO';
import { IPaymentPending } from '../../../../models/Payments/IPaymentPendingDTO';
import { blue, orange } from '@material-ui/core/colors';
import ReactHtmlParser from 'react-html-parser';
import QRCode from "react-qr-code";
import { IPaymentVoucher } from "../index";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToast } from '../../../../hooks/toast';
import animationWaiting from '../../../../assets/animations/waitinggreen.json';
import api from '../../../../services/api';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",

    },
    right: {
      marginLeft: "auto",
    },
    detailPayment: {
      marginTop: "10px"
    },
    avatar: {
      maxWidth: "160px",

      margin: "0 auto",
    },
    name: {
      marginTop: "-80px"
    },
    imgFluid: {
      maxWidth: "100%",
      height: "auto"
    },
    imgRaised: {
      boxShadow:
        "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgRoundedCircle: {
      borderRadius: "50% !important"
    },
    Paper: {
      padding: '20px',
      marginBottom: '26px',
    },
    cardPadding: {
      padding: '16px',
    },
    dividerMargin: {
      margin: '20px 0',
    },
    wrapper: {
      marginTop: '30px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    titleMessage: {
      fontSize: '15px',
      marginBottom: '10px',
    },
    descriptionMessage: {
      display: 'flex',
      fontSize: '18px',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '19px',
    },
    descriptionHeloMessage: {
      display: 'flex',
      fontSize: '14px',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '30px',
    }
  }),
);
interface DescricaoItem {
  descricao: string;
}
interface IPayment {
  bandeira: string;
  numero: string;
  formaPagamento: FormPaymentEnum;
  parcelas: number;
  habilitaAgendamento: boolean;
  linkBoleto: string;
  qrcodePix: string;
  chavePix: string;
  idOrder: string;
}
interface IPlanItem {
  id: string;
  bloqueio: string;
  nomePlano: string;
  descricaoPlano: string;
  duracao: string;
  validadeCredito: string;
  idContrato: number;
  credito: boolean;
  idCredito: number;
  parcelas: number;
  valorTotal: number;
  adesaoObservacao: string;
  anuidadeValor: number;
  anuidadeMes: number;
  servicoExtraObservacao: string;
  adesaoDescricao: string;
  servicoExtraDescricao: string;
  creditoDescricao: string;
  produtoDescricao: string;
  creditoObservacao: string;
  produtoObservacao: string;
  adesaoValor: number;
  dcc: boolean;
  valorDcc: number;
  caracteristicas: Array<DescricaoItem>;
}

interface IResponsePayment {
  ok: boolean;
  msg: string;
  statusCode: number;
  urlAuth: string;
  prospectConvertido: boolean;
  idPessoa?: number;
  token: string;
  textoPosCompra: string;
  habilitaAgendamento: boolean;
}



const CheckoutSuccess: React.FC = () => {
  const classes = useStyles();
  let timerId = 0;
  const { tenant, signOut, user } = useAuth();
  const [plan, setPlan] = React.useState<IPlanItem | undefined>();
  const [voucher, setVoucher] = React.useState<IPaymentVoucher | undefined>();
  const [loading, setLoading] = React.useState(true);
  const [invoice, setInvoice] = React.useState<IPaymentPending | undefined>();
  const [responsePayment, setResponsePayment] = React.useState<IResponsePayment | undefined>();
  const [aguardandoPagamento, setAguardandoPagamento] = useState(false);
  const [pixConfirmado, setPixConfirmado] = useState(false);
  const [payment_details, setPayment_Details] = React.useState<IPayment | undefined>();
  const [isStopped, setIsStopped] = React.useState(true);
  const [new_register, setNew_register] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [habilitaAgendamento, setHabilitaAgendamento] = React.useState(false);
  const { addToast } = useToast();
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
  };
  const defaultOptionsError = {
    loop: false,
    autoplay: false,
    animationData: animationDataError,
  };
  const defaultOptionsWaiting = {
    loop: true,
    autoplay: false,
    animationData: animationWaiting,
  };



  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const history = useHistory();

  useEffect(() => {
    const planString = sessionStorage.getItem('@HubfitPortal:plan');
    const voucherString = sessionStorage.getItem('@HubfitPortal:voucher');
    const invoiceString = sessionStorage.getItem('@HubfitPortal:invoice');
    const payment_detailsString = sessionStorage.getItem('@HubfitPortal:payment_details');
    const newR = sessionStorage.getItem('@HubfitPortal:new_register');
    const habAgend = sessionStorage.getItem('@HubfitPortal:habilitaAgendamento');
    const responsePaymentString = sessionStorage.getItem('@HubfitPortal:responsePayment');
    const errorPayment = sessionStorage.getItem('@HubfitPortal:errorPayment');
    if (errorPayment) {
      if (payment_detailsString) {
        const payment_detailsParsed = JSON.parse(payment_detailsString);
        if (payment_detailsParsed) {
          setPayment_Details(payment_detailsParsed);
        }

      }
      setError(errorPayment);
      setLoading(false);
    } else {
      if (newR) {
        if (newR == "true") {
          setNew_register(true);
        }
      }
      if (habAgend) {
        if (habAgend == "true") {
          setHabilitaAgendamento(true);
        }
      }
      console.log(responsePaymentString)
      if (responsePaymentString) {
        setResponsePayment(JSON.parse(responsePaymentString));
      }

      if (planString && payment_detailsString) {
        const planParsed = JSON.parse(planString);
        if (voucherString) {
          const voucherParsed = JSON.parse(voucherString);
          if (voucherParsed) {
            setVoucher(voucherParsed);
          }
        }

        const payment_detailsParsed = JSON.parse(payment_detailsString);
        if (planParsed) {
          setPlan(planParsed);
        }

        if (payment_detailsParsed) {
          setPayment_Details(payment_detailsParsed);
          const paymentParsed = payment_detailsParsed as IPayment;
          if (paymentParsed.formaPagamento == FormPaymentEnum.Pix && paymentParsed.qrcodePix) {
            setAguardandoPagamento(true);
            LoopStatus(paymentParsed.idOrder);
          }
        }
      } else if (invoiceString && payment_detailsString) {
        const invoiceParsed = JSON.parse(invoiceString);
        const payment_detailsParsed = JSON.parse(payment_detailsString);
        if (invoiceParsed) {
          setInvoice(invoiceParsed);
        }
        if (payment_detailsParsed) {
          setPayment_Details(payment_detailsParsed);
          const paymentParsed = payment_detailsParsed as IPayment;
          if (paymentParsed.formaPagamento == FormPaymentEnum.Pix && paymentParsed.qrcodePix) {
            setAguardandoPagamento(true);
            LoopStatus(paymentParsed.idOrder);
          }
        }
      } else if (!!invoiceString) {
        const invoiceParsed = JSON.parse(invoiceString);
        if (invoiceParsed) {
          setInvoice(invoiceParsed);
        }
      } else {
        // alert('nao passou')
        history.push('/');
      }
      setLoading(false);

      sessionStorage.removeItem('@HubfitPortal:plan');
      sessionStorage.removeItem('@HubfitPortal:invoice');
      sessionStorage.removeItem('@HubfitPortal:payment_details');
      sessionStorage.removeItem('@HubfitPortal:action');
      sessionStorage.removeItem('@HubfitPortal:new_register');
      sessionStorage.removeItem('@HubfitPortal:habilitaAgendamento');
      sessionStorage.removeItem('@HubfitPortal:responsePayment');
      sessionStorage.removeItem('@HubfitPortal:voucher');

    }
    setTimeout(() => {
      setIsStopped(false);
    }, 1000);

  }, [])

  const getStatusTransacao = useCallback(async (idPedidoSistema: string) => {
    const response = await api.get('/payments/sales/checkoutpixstatus', {
      params: {
        idPedido: idPedidoSistema,
        idTenant: tenant.idTenant
      }
    });
    return response;
  }, [tenant.idTenant]);

  const LoopStatus = useCallback((idOrderT: string) => {
    //  if (loadClass) {       //  create a loop function
    console.log(idOrderT)
    const timer = (idO: string) => window.setTimeout(function () {   //  call a 3s setTimeout when the loop is called
      api.get('/payments/sales/checkoutpixstatus', {
        params: {
          idPedido: idOrderT,
          idTenant: tenant.idTenant
        }
      }).then((response) => {
       
        const retorno = response.data;
        console.log(retorno.statusCode);
        if (retorno.statusCode == 12) {
          //Ainda pendente
        } else if (retorno.statusCode == 2) {
          //Processado
          console.log("foii1")
          if (retorno.ok == true) {
            // if (retorno.retornoPaymentInfo) {
            //   sessionStorage.setItem('@HubfitTotem:paymentSuccess', JSON.stringify({
            //    // bandeira: retorno.retornoPaymentInfo.brand,
            //    // numero: retorno.retornoPaymentInfo.lastNumbers,
            //     formaPagamento: retorno.retornoPaymentInfo.formaPagamento == 11 ? FormPaymentEnum.Pix : FormPaymentEnum.CartaoCredito,
            //     parcelas: retorno.retornoPaymentInfo.parcelas,
            //     valor: retorno.retornoPaymentInfo.valor,
            //   }));
            // }
            console.log("foii")
            setPixConfirmado(true);
            // setTimeout(() => {
            //   history.push('/payments/checkout/success');
            // }, 1000);
          } else {
            // setDisabled(false);
            addToast({
              type: "error",
              title: 'Ops...',
              description: response.data.msg,
              time: 20000
            });
          }
          clearTimeout(timerId);


        } else if (retorno.statusCode == 13) {
          //Operação cancelada
          // setDisabled(false);
          addToast({
            type: "error",
            title: 'Operação cancelada',
            time: 7000
          });
          //  handleGoBack();
        }
      })


      LoopStatus(idOrderT);             //  ..  again which will trigger another
    }, 5000);
    timerId = timer(idOrderT);

    return () => {
      if (timerId && timerId > 0) {
        clearTimeout(timerId);
      }

    };

    // }
  }, [tenant.idTenant])



  const handleClickBack = useCallback(() => {
    history.goBack();
  }, [history]);
  const handleClickAgendar = useCallback(() => {
    history.push({
      pathname: '/appointments',
      state: {
        agendar: true,
      }
    })

  }, [history]);
  const handleClickAula = useCallback(() => {
    history.push({
      pathname: '/classes/list',
      state: {
        agendar: true,
      }
    })

  }, [history]);


  const codigocopiado = useCallback(() => {
    addToast({
      type: "success",
      title: 'Código PIX copiado para a área de transferência.',
    });
  }, [])

  const handleClickWizardNewRegister = useCallback(() => {
    history.push("/user/registration")
  }, [])

  return (
    <>
      <Header isBack avatar hideSubmenu={!user} />
      <div className={classes.mainRaised}>
        <div>
          <Content>

            <Grid justify="center">
              {error ?
                <Grid item xs={12} sm={12} >
                  <div >
                    <div>
                      <Lottie options={defaultOptionsError}
                        height={115}
                        width={115}
                        speed={0.6}
                        isStopped={isStopped}
                      />
                      <Grid container direction="row" className={classes.dividerMargin}
                        justify="center"
                        alignItems="center">
                        <Grid item xs={12} sm={10} md={6} lg={4} >
                          <Paper elevation={1} className={classes.Paper} >


                            <Typography gutterBottom color="textSecondary" className={classes.titleMessage} >
                              Não foi possível efetuar a cobrança.
                            </Typography>

                            <Typography component="p" className={classes.descriptionMessage}>
                              <ErrorIcon style={{ color: orange[500], marginRight: '10px' }} /> {error}
                            </Typography>
                            {payment_details?.formaPagamento == FormPaymentEnum.CartaoCredito &&
                              <>
                                <Divider variant="middle" />
                                <Typography color="textSecondary" variant="body2" className={classes.descriptionHeloMessage}  >
                                  <Help style={{ color: blue[500], marginRight: '10px' }} />
                                  <div>Caso você tenha digitado corretamente os dados do seu cartão e ele não possua problemas de limite ou bloqueio, verifique com seu emissor do cartão se não há algum bloqueio de segurança para o pagamento que está tentando realizar.<p>Após a liberação, tente novamente.</p></div>
                                </Typography>
                              </>
                            }
                          </Paper>
                          <Grid item xs={12} alignItems="center" direction="column" style={{ display: 'flex' }}>

                            <Button type="button" variant="outlined" color="primary" onClick={handleClickBack} startIcon={<ArrowBackIos />}>
                              Voltar
                            </Button>

                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
                :
                <>
                  {!loading &&

                    <Grid item xs={12} sm={12} >
                      <div >
                        <div>
                          {payment_details && payment_details.formaPagamento == FormPaymentEnum.Pix &&
                          (
                            pixConfirmado == false ?
                            <Lottie options={defaultOptionsWaiting}
                              height={115}
                              width={115}
                              speed={0.6}
                              isStopped={isStopped}
                            />
                            :
                            <Lottie options={defaultOptions}
                            height={115}
                            width={115}
                            speed={0.6}
                            isStopped={isStopped}
                          />
                          )
                          }
                          {payment_details && payment_details.formaPagamento != FormPaymentEnum.Pix &&
                            <Lottie options={defaultOptions}
                              height={115}
                              width={115}
                              speed={0.6}
                              isStopped={isStopped}
                            />
                          }

                        </div>
                        <Grid container direction="row"
                          justify="center"
                          alignItems="center">
                          <Grid className={classes.cardPadding}>
                            <Paper elevation={1} className={classes.Paper} >
                              <Grid container alignItems="center">
                                {!loading &&
                                  <Grid item xs >
                                    {invoice && invoice.Pago ?
                                      <Typography gutterBottom variant="h5" >
                                        Esta fatura já foi paga anteriormente.
                                      </Typography>
                                      :
                                      <Typography gutterBottom variant="h5" >
                                        {payment_details && payment_details.formaPagamento == FormPaymentEnum.Boleto &&
                                          <span>Boleto gerado com sucesso</span>

                                        }
                                        {payment_details && payment_details.formaPagamento == FormPaymentEnum.Pix && pixConfirmado == false &&
                                          <span>Aguardando pagamento do PIX</span>

                                        }
                                        {payment_details && payment_details.formaPagamento == FormPaymentEnum.Pix && pixConfirmado == true &&
                                          <span>PIX realizado com sucesso</span>

                                        }
                                        {payment_details && (payment_details.formaPagamento == FormPaymentEnum.CartaoCredito || payment_details.formaPagamento == FormPaymentEnum.CartaoDebito) &&
                                          <span>Pagamento realizado com sucesso</span>
                                        }
                                      </Typography>
                                    }


                                  </Grid>
                                }
                              </Grid>

                              {payment_details && !error &&
                                <>
                                  <Typography gutterBottom color="textSecondary" variant="body2">
                                    {payment_details && payment_details.formaPagamento == FormPaymentEnum.Boleto &&
                                      <span>Também enviamos um e-mail para você com este boleto. O pagamento será reconhecido 1 dia útil após o pagamento.</span>

                                    }
                                    {payment_details && payment_details.formaPagamento == FormPaymentEnum.Pix && pixConfirmado == false &&
                                      // <span>Agora é só clicar em Copiar o código Pix ou escaner o QRCode abaixo com o aplicativo do seu banco.</span>
                                      <span>Faça o pagamento do PIX através do QRCode ou do código "Copia e cola".</span>
                                    }
                                    {payment_details && (payment_details.formaPagamento == FormPaymentEnum.CartaoCredito || payment_details.formaPagamento == FormPaymentEnum.CartaoDebito) &&
                                      <span>Enviamos um e-mail para você com a confirmação e todos os detalhes deste pagamento.</span>
                                    }
                                  </Typography>
                                  {responsePayment?.textoPosCompra &&
                                    <Typography color="textPrimary" variant="body2">
                                      {ReactHtmlParser(responsePayment?.textoPosCompra)}
                                    </Typography>
                                  }
                                  <Divider variant="fullWidth" className={classes.dividerMargin} />
                                  {plan &&
                                    <>
                                      <Typography variant="body1" gutterBottom className={classes.detailPayment} >
                                        <b>Item da compra:</b> {plan?.nomePlano}
                                      </Typography>
                                      <Typography variant="body1" gutterBottom>
                                        {plan?.dcc ?
                                          <>
                                            <b>Valor:</b> <NumberFormat value={plan?.valorDcc - (voucher ? voucher.valorDescontoContratoDCC : 0) + (plan?.adesaoValor - (voucher ? voucher.valorDescontoAdesao : 0))} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />
                                            {payment_details.formaPagamento == FormPaymentEnum.CartaoCredito &&
                                              <> em {payment_details?.parcelas}x no cartão de crédito</>
                                            }
                                          </>
                                          :
                                          <>
                                            <b>Valor:</b> <NumberFormat value={plan?.valorTotal - (voucher ? voucher.valorDescontoContrato : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />
                                            {payment_details.formaPagamento == FormPaymentEnum.CartaoCredito &&
                                              <> em {payment_details?.parcelas}x no cartão de crédito</>
                                            }
                                          </>
                                        }

                                        {payment_details.formaPagamento == FormPaymentEnum.CartaoDebito &&
                                          <> à vista no cartão de débito</>
                                        }
                                        {payment_details.formaPagamento == FormPaymentEnum.Boleto &&
                                          <> em {payment_details?.parcelas}x no boleto bancário</>
                                        }
                                        {payment_details.formaPagamento == FormPaymentEnum.Pix &&
                                          <> via Pix</>
                                        }
                                      </Typography>
                                    </>
                                  }
                                  {invoice &&
                                    <>
                                      <Typography variant="body1" gutterBottom>
                                        <b>Valor:</b> <NumberFormat value={invoice?.ValorTotal} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />

                                        {payment_details.formaPagamento == FormPaymentEnum.CartaoCredito &&
                                          <> em {payment_details?.parcelas}x no cartão de crédito</>
                                        }
                                        {payment_details.formaPagamento == FormPaymentEnum.CartaoDebito &&
                                          <> à vista no cartão de débito</>
                                        }
                                        {payment_details.formaPagamento == FormPaymentEnum.Boleto &&
                                          <> em {payment_details?.parcelas}x no boleto bancário</>
                                        }
                                        {payment_details.formaPagamento == FormPaymentEnum.Pix &&
                                          <> via Pix</>
                                        }
                                      </Typography>
                                    </>
                                  }
                                  {payment_details.formaPagamento != FormPaymentEnum.Boleto && payment_details.formaPagamento != FormPaymentEnum.Pix &&
                                    <Typography variant="body1" gutterBottom className={classes.detailPayment} >
                                      <b>Cartão:</b> {payment_details?.numero} - {payment_details?.bandeira}
                                    </Typography>
                                  }

                                </>
                              }
                              {payment_details?.formaPagamento == FormPaymentEnum.Boleto && payment_details.linkBoleto &&
                                <Grid
                                  container
                                  direction="row"
                                  justify="center"
                                  alignItems="center"
                                  spacing={2}
                                  style={{ marginTop: '23px' }}
                                >
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>
                                    <Button type="button" variant="contained" color="primary" target="_blank" href={payment_details.linkBoleto} >
                                      Visualizar boleto
                                    </Button>

                                  </Grid>
                                </Grid>
                              }
                              {payment_details?.formaPagamento == FormPaymentEnum.Pix && payment_details.chavePix &&
                                <Grid
                                  container
                                  direction="row"
                                  justify="center"
                                  alignItems="center"
                                  spacing={2}
                                  style={{ marginTop: '23px' }}
                                >
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>
                                    Chave Pix: <b>{payment_details.chavePix}</b>
                                  </Grid>
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>
                                    <QRCode size={180} value={payment_details.qrcodePix} />

                                  </Grid>
                                </Grid>
                              }
                              {payment_details?.formaPagamento == FormPaymentEnum.Pix && payment_details.qrcodePix && pixConfirmado == false &&
                                <Grid
                                  container
                                  direction="row"
                                  justify="center"
                                  alignItems="center"
                                  spacing={2}
                                  style={{ marginTop: '23px' }}
                                >
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>

                                    <CopyToClipboard text={payment_details.qrcodePix}
                                      onCopy={() => codigocopiado()}>
                                      <Button type="button" variant="contained" color="primary" >
                                        Copiar código Pix
                                      </Button>

                                    </CopyToClipboard>
                                  </Grid>
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>
                                    <QRCode size={180} value={payment_details.qrcodePix} />

                                  </Grid>
                                </Grid>
                              }
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}
                                style={{ marginTop: '23px' }}
                              >

                                {habilitaAgendamento && tenant.permissions.appointments &&
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>

                                    <Button type="button" variant="contained" color="primary" onClick={handleClickAgendar} startIcon={<Today />}>
                                      Agendar
                                    </Button>

                                  </Grid>
                                }
                                {plan?.credito == true && tenant.permissions.classesCheckin == true &&
                                  <Grid item xs={12}  alignItems="center" direction="column" style={{ display: 'flex' }}>
                                    <Button type="button" variant="contained" color="primary" onClick={handleClickAula} startIcon={<Today />}>
                                      Agendar aula
                                    </Button>
                                  </Grid>
                                }
                                 {plan?.idContrato && tenant.permissions.classesCheckin == true && payment_details?.formaPagamento == FormPaymentEnum.Pix && pixConfirmado &&

                                  <Grid item xs={12}  alignItems="center" direction="column" style={{ display: 'flex' }}>
                                    <Button type="button" variant="contained" color="primary" onClick={handleClickAula} startIcon={<Today />}>
                                      Agendar aula
                                    </Button>
                                  </Grid>
                                }
                                {/* {new_register &&
                                  <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>

                                    <Button type="button" variant="contained" color="primary" onClick={handleClickWizardNewRegister} startIcon={<ArrowForwardIos />}>
                                      Continuar
                     </Button>

                                  </Grid>
                                } */}
                              </Grid>

                            </Paper>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  }

                </>
              }
            </Grid>
          </Content>
        </div>
      </div>
    </>
  )
};
export default CheckoutSuccess;
