import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import queryString from 'query-string';
import Cards from 'react-credit-cards';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import 'react-credit-cards/es/styles-compiled.css';
import Recaptcha from 'react-recaptcha';

import ReactHtmlParser from 'react-html-parser';

import { Content, AnimationContainer, Background } from './styles';
import { Input, Button, makeStyles, Theme, CircularProgress, Grid, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Typography, Paper, InputLabel, FormHelperText, Checkbox, Divider, TextField, Dialog, AppBar, Toolbar, IconButton, DialogContent, DialogContentText, MenuItem, Select, Tab, Tabs, useTheme, Box, BottomNavigation, BottomNavigationAction, NativeSelect, colors, Badge, Avatar, Container } from '@material-ui/core';
import InputMask from "react-input-mask";
import { green, blue, grey } from '@material-ui/core/colors';
import { useAuth } from '../../../hooks/auth';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import Card from '../../../components/Card/Card';
import NumberFormat from 'react-number-format';
import { GridFooterCard } from '../../Plans/List/styles';
import { IPaymentSaleDTO, FormPaymentEnum, CreditCardDTO, ICardSaveDTO, ITokenCardItem } from '../../../models/Payments/IPaymentSaleDTO';
import { CheckCircle, Close as CloseIcon, CreditCard, StayPrimaryLandscapeSharp, Lock  } from '@material-ui/icons';
import Header from '../../../components/Header';
import { IPlanItem } from '../../../models/Plans/IPlanDTO';
import { User, TypeUser } from '../../../models/Tenant/UserDTO';
import { IPaymentPending } from '../../../models/Payments/IPaymentPendingDTO';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Helmet } from 'react-helmet';
import Logo from '../../../assets/img/pix-banco-central-logo.svg'
import animationWaiting from '../../../assets/animations/waitinggreen.json';

import SwipeableViews from 'react-swipeable-views';
import DialogPlanDetails from '../../../components/DialogPlanDetails/Index';
import { grayscale, shade } from 'polished';
import Lottie from 'react-lottie';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import classNames from 'classnames';
import { Alert, AlertTitle } from '@material-ui/lab';

interface IParcelaItem {
  value: number;
  text: number
}



interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



const useStyles = makeStyles((theme: Theme) => ({

  rootTab: {
    flexGrow: 1,
  },
  cardPadding: {
    padding: '10px',
  },
  titleCard: {
    marginTop: '0px',
  },
  wrapper: {

    margin: '60px 0 0',
    width: '100%',
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  totalValue: {
    fontSize: 17,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  SpanCard: {
    color: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',

  },
  IconInfo: {
    color: blue[500],
  },
  animationTab: {
    height: '100%',
  },
  Paper: {
    padding: '22px',
    marginBottom: '26px',
  },

  valuePlan: {
    marginTop: '2px !important',

  },
  IconCheck: {
    fontSize: '3.5875rem',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  buttonClose: {
    marginRight: 0,
  },
  ControlTerms: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

  },
  labelControlTerms: {
    marginRight: '6px',
  },
  textObs: {
    color: theme.palette.text.secondary,
    fontSize: '13px',
  },
  buttonContract: {
    padding: '3px 1px',
    marginTop: '2px',
    color: theme.palette.primary.main,
  },
  paperContract: {
    padding: '40px',
  },
  backgroundContract: {
    backgroundColor: theme.palette.grey[200],
  },
  GridList: {
    margin: '20px 0',
  }
  ,
  labelAvatar: {
    position: 'absolute',
    width: '48px',
    height: '48px',
    background: '#e6e6e6',
    borderRadius: '50%',
    right: '-11',
    bottom: '-10',
    border: '0',
    transition: 'background-color 0.2s',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      width: '20px',
      height: '20px',
      color: '#312e38',
    },
    '&:hover': {
      background: shade(0.2, '#e6e6e6'),
    }
  },
  titleName: {
    marginTop: '15px',
    color: 'white',
  },
  small: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginTop:'-32px !important',
  },
  large: {
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  avatar: {
    maxWidth: "160px",

    margin: "0 auto",
  },


}));




interface IPaymentFormData {
  name: string,
  number: string,
  expiry: string,
  cvc: string,
  acceptsTerms: boolean,
  parcelas: number,
}
export interface IPaymentVoucher {
  code: string,
  id: number,
  isConvenio: boolean,
  valorDescontoProduto: number,
  valorDescontoContrato: number,
  valorDescontoAdesao: number,
  valorDescontoCredito: number,
  valorDescontoContratoDCC: number;
  valorDescontoServico: number,
  qtdParcelasDesconto?: number,

}
interface IPayment {
  bandeira: string;
  numero: string;
  formaPagamento: FormPaymentEnum;
  parcelas: number;
  habilitaAgendamento: boolean;
  linkBoleto: string;
  qrcodePix: string;
  chavePix: string;
}


const CardsAdd: React.FC = () => {

  let timerId = 0;
  const classes = useStyles();
  const theme = useTheme();
  const { updateUser, tenant, user } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [aguardandoPagamento, setAguardandoPagamento] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [openModal, setOpenMoldal] = React.useState(false);
  const [tokenCard, setTokenCard] = React.useState<ITokenCardItem | undefined>();
  const [token, setToken] = React.useState<string>();
  const refRecaptcha = useRef<Recaptcha | null>(null);
  const [isStopped, setIsStopped] = React.useState(true);
  const { addToast } = useToast();
  const history = useHistory();
  let location = useLocation();

  const defaultOptionsWaiting = {
    loop: true,
    autoplay: false,
    animationData: animationWaiting,
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,

  });

  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState();
  const [typeCard, setTypeCard] = useState<string>();
  const [openDetails, setOpenDetails] = React.useState(false);

  const numberRef = useRef<HTMLInputElement | null>(null);

  const recaptchaLoaded = useCallback(() => {
    console.log('recaptcha load')
  }, [])

  const recaptchaCallback = useCallback((response) => {
    sessionStorage.setItem('@HubfitPortal:recap', response);
    setDisabled(false);
    console.log(response)
  }, [])

  useEffect(() => {

    sessionStorage.removeItem('@HubfitPortal:errorPayment');
    sessionStorage.removeItem('@HubfitPortal:payment_details');
    sessionStorage.removeItem('@HubfitPortal:responsePayment');

    const domain = location.pathname.split('/')[1];
    console.log(location)


    if (domain == "cards") {

      //CADASTRO DE CARTÃO
      const parms = queryString.parse(location.search);

      if (parms.token && tenant) {
        setToken(parms.token as string);
        setLoadingData(true);
        api.get('/cards', {
          params: {
            token: parms.token,
            idTenant: tenant.idTenant
          }
        }).then((response) => {
          console.log(response.data)
           setTokenCard(response.data as ITokenCardItem);
          setLoadingData(false);
          //  sessionStorage.setItem('@HubfitPortal:action', "invoice_payment");
          //  sessionStorage.setItem('@HubfitPortal:invoice', JSON.stringify(response.data));

          const tokenParsed = response.data;
          console.log(response.data);


        }).catch(err => {
          console.log(err.response.data.Message)
          history.push("/login")
          addToast({
            type: "error",
            title: 'Link indisponível',
            description: err.response.data.Message,
            time: 15000
          });
        })
      }
      else {
        sessionStorage.removeItem('@HubfitPortal:plan');
        sessionStorage.removeItem('@HubfitPortal:action');
        sessionStorage.removeItem('@HubfitPortal:invoice');
        sessionStorage.removeItem('@HubfitPortal:responsePayment');
        history.push('/');
      }


    }
    setTimeout(() => {
      numberRef.current?.focus();
    }, 1000)

    return () => {
      if (timerId && timerId > 0) {
        clearTimeout(timerId);
      }

    };

  }, [tenant])



  const handleChangeCard = useCallback((e) => {
    setTypeCard(e.issuer);
  }, [setTypeCard]);




  let RegisterPasswordSchema = Yup.object().shape({
    name: Yup.string().required('Insira o nome impresso no cartão'),
    number: Yup.string().required('Insira o número do cartão de crédito'),
    expiry: Yup.string().required('Insira a validade do cartão'),
    cvc: Yup.string().required('Insira o código de segurança'),
    acceptsTerms: Yup.boolean().oneOf([true], "Você deve aceitar os termos do contrato"),

  });


  const { register, handleSubmit, control, errors, setValue } = useForm<IPaymentFormData>({
    resolver: yupResolver(RegisterPasswordSchema),

  });


  const onSubmit = useCallback(async (data: IPaymentFormData) => {
    console.log(tokenCard);
    if (tokenCard && token && !loading) {
      try {
        setLoading(true);
        setDisabled(true);
        setSuccess(false);

        const cardForm: CreditCardDTO = {
          numero: data.number,
          ano: Number.parseInt(data.expiry.split('/')[1]),
          mes: Number.parseInt(data.expiry.split('/')[0]),
          nomeCompleto: data.name,
          cvc: data.cvc,
          bandeira: typeCard ? typeCard : 'visa',
        };

        const tokenCardForm: ICardSaveDTO = {
          cartao: cardForm,
          idTenant: tenant.idTenant,
          recaptcha: sessionStorage.getItem('@HubfitPortal:recap'),
          id: tokenCard.id,
          idUnidade: tokenCard.idUnidade,
          token: token,
          tipoPessoa: tokenCard.tipoPessoa
        };



        const response = await api.post('/cards', tokenCardForm);
        console.log(response.data);
        setSuccess(true);
        sessionStorage.setItem('@HubfitPortal:saveCard',"true");
        setTimeout(() => {
          history.push('/payments/cards/success');
        }, 1000);

      } catch (err) {
        setDisabled(false);
        addToast({
          type: "error",
          title: 'Erro',
          description: err.response.data.Message,
          time: 15000
        });
        // addToast({
        //   type: "error",
        //   title: 'Erro',
        //   description: "Não foi possível salvar as informações",
        //   time: 20000
        // });
      } finally {
        setLoading(false);
        refRecaptcha.current?.reset();
        setDisabled(false);
      }
    }
  }, [tokenCard, history, typeCard]);


  return (
    <>
      <Header isBack avatar hideSubmenu={!user} />
      <Helmet>
        <title>Cadastro de cartão - {tenant?.name}</title>
      </Helmet>
      <Container maxWidth="md">
        <Grid  container direction="row-reverse" className={classes.GridList} >
          <Grid item xs={12} sm={5} md={5} lg={5} >

            {tokenCard && (
              <Grid container direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={12} md={10} lg={10} className={classes.cardPadding}>

                  <Card>
                    <CardHeader >
                      {tokenCard &&
                        <>
                            <Avatar alt={tokenCard.nome} src={tokenCard.urlImage} className={classNames(classes.small, classes.avatar)} />
                        
                          <Typography  variant="h6" component="h2" className={classes.titleName}>
                            {tokenCard.nome}
                          </Typography>
                        </>
                      }
                    </CardHeader>

                    <CardBody>
                     
                      <Typography align="center">Link para cadastro de cartão de crédito.</Typography>

                    </CardBody>
                   

                  </Card>
                </Grid>
              </Grid>

            )}

          </Grid>
          {loadingData ?
            <Grid container direction="column" className={classes.GridList}
              justify="center"
              alignItems="center" >
              <Grid item xs={12} >
                <CircularProgress size={40} />
              </Grid>
            </Grid>
            :
            <>
              <Grid item xs={12} sm={7} md={7} lg={7} >
                <AnimationContainer className={classes.animationTab}>
                  <Background>
                    <Grid container direction="row"
                      justify="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={8} lg={8} className={classes.cardPadding}>

                        
                        <form onSubmit={handleSubmit(onSubmit)}>

                          <Typography variant="h5" gutterBottom className={classes.titleCard}>
                            <span>Dados do cartão de crédito</span>
                          </Typography>
                          <Paper elevation={1} className={classes.Paper} >
                            <Grid container spacing={1} style={{marginBottom:'15px'}}>
                              <Grid item xs={12}>
                                <FormControl fullWidth error={!!errors.number?.message}>
                                  <InputMask
                                    mask="9999 9999 9999 99999"
                                    disabled={false}
                                    maskChar=""
                                    maskPlaceholder={null}
                                   // onFocus={(e) => setFocus(e.target.name)} 
                                    onChange={(e) => setNumber(e.target.value)}
                                  >
                                    {<TextField margin="normal" label="Número do cartão" name="number" id="number" autoComplete="off" inputRef={(e) => {
                                      register(e);
                                      numberRef.current = e;
                                    }}
                                      type="tel"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />}
                                  </InputMask>
                                  <FormHelperText>{errors.number?.message}</FormHelperText>
                                </FormControl>

                              </Grid>
                              <Grid item xs={12}>
                                <FormControl fullWidth error={!!errors.name?.message}>

                                  <TextField label="Nome e sobrenome" name="name" id="name" 
                                //  onFocus={(e) => setFocus(e.target.name)}
                                   onChange={(e) => setName(e.target.value)} inputRef={register} autoComplete="off" InputLabelProps={{
                                    shrink: true,
                                  }} />
                                  <FormHelperText>{errors.name?.message}</FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth error={!!errors.expiry?.message}>

                                  <InputMask
                                    mask="99/99"
                                    disabled={false}
                                    maskPlaceholder={null}
                                    maskChar=""
                                    placeholder="MM/AA"
                                    //onFocus={(e) => setFocus(e.target.name)} 
                                    onChange={(e) => setExpiry(e.target.value)}
                                  >
                                    { <TextField name="expiry" id="expiry" inputRef={register} autoComplete="off" InputLabelProps={{
                                      shrink: true,
                                    }} type="tel" label="Data de validade" placeholder="MM/AA" />}
                                  </InputMask>
                                  <FormHelperText>{errors.expiry?.message}</FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth error={!!errors.cvc?.message}>
                                  <InputMask
                                    mask="9999"
                                    disabled={false}
                                    maskChar=""
                                    maskPlaceholder={null}
                                    //onFocus={(e) => setFocus(e.target.name)} 
                                    onChange={(e) => setCvc(e.target.value)}
                                  >
                                    {<TextField type="tel" label="Cód. de segurança" name="cvc" id="cvc" inputRef={register} autoComplete="off" InputLabelProps={{
                                      shrink: true,
                                    }} />}
                                  </InputMask>
                                  <FormHelperText>{errors.cvc?.message}</FormHelperText>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Cards 

cvc={cvc}
expiry={expiry}
focused={focus}
name={name}
number={number}
locale={{ valid: 'valido até' }}
placeholders={{ name: 'NOME NO CARTÃO' }}
callback={handleChangeCard}


/>
                          </Paper>
                          <Content>
                            <Grid
                            style={{marginTop:'-21px', marginBottom:'5px'}}
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                            >
                              <Recaptcha
                                ref={refRecaptcha}
                                hl='pt-BR'
                                size='normal'
                                sitekey="6LfHOKIUAAAAAPwaZ0c_LxLmhZV4wckS4xGKr9Nr"
                                render="explicit"
                                onloadCallback={recaptchaLoaded}
                                verifyCallback={recaptchaCallback}
                              />
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                            >
                              {!success &&
                                <Button className={buttonClassname} disabled={disabled} type="submit" variant="contained" color="primary"  >

                                  {loading &&
                                    <>
                                      <span>Aguarde...</span><CircularProgress size={24} className={classes.buttonProgress} />
                                    </>
                                  }
                                  {!loading &&
                                    <span>Salvar cartão</span>
                                  }
                                </Button>
                              }
                              {success && !loading &&
                                <CheckCircle fontSize="large" htmlColor="#4caf50" className={classes.IconCheck} />
                              }

                            </Grid>
                          </Content>

                        </form>

                      </Grid>
                      
                    </Grid>
                   

                      <Alert  severity="success" iconMapping={{
    success: <Lock fontSize="inherit" />,
  }}>
                      <AlertTitle>Seus dados estão seguros</AlertTitle>
                    Os dados do cartão ficam armazenados diretamente na adquirente que processa os pagamentos
                  </Alert>
                   
                  </Background>
                </AnimationContainer>
              </Grid>

            </>
          }
        </Grid>
      </Container>

    </>
  )
};
export default CardsAdd;
