import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { format, isDate, isToday, parse, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import ptLocale from "date-fns/locale/pt-BR";
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';
import { List as ListComponent, makeStyles, createStyles, Theme, Grid, CircularProgress, Paper, Typography, Button, Container, Box, Chip, withStyles, ListItem, ListItemIcon, ListItemText, Dialog, Avatar, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, Input, InputLabel, List, ListItemAvatar, ListItemSecondaryAction, Radio, RadioGroup, TextField } from '@material-ui/core';
import Content from '../../../components/Content';

import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/success.json';
import animationDataError from '../../../assets/animations/error.json';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { ArrowBackIos, ArrowForwardIos, ArrowLeft, Check, MenuBook, QueryBuilder, Today, BlurCircular, Lens } from '@material-ui/icons';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { AnimationContainer, Background } from '../../Login/SignIn/styles';
import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import InputMask from "react-input-mask";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Recaptcha from 'react-recaptcha';
import { IUserLogin } from '../../../models/Perfil';
import { green } from '@material-ui/core/colors';
import { IAulaEscolhida, IAulaExperimental } from '../../../models/Appointment/IAppointmentDTO';
import LandingPageCalendarioCadastro from '../CalendarioCadastro';


const StyledChip = withStyles({

  label: {
    fontWeight: 500,
  },
})(Chip);

const useStyles = makeStyles((theme: Theme) =>



  createStyles({
    feedback: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
    },
    GridList: {
      margin: '28px 0 !important',
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",
    },
    Paper: {
      padding: '20px',
      marginBottom: '26px',
      marginTop: '32px',
    },
    textMessage: {
      textAlign: 'center',
    },
    textMessageSub: {
      textAlign: 'center',
      color: theme.palette.text.disabled,
      marginBottom: '20px',
    },
    textMessageCustom: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    titleHours: {
      margin: '18px 6px',

    },
    chipHour: {
      fontSize: '1rem',
    },
    divBack: {
      marginTop: '10px',
    },
    listHours: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    dayPicker: {

      // backgroundColor: theme.palette.background.paper,
      // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      // borderRadius: '4px',
      borderRadius: '50%',

      '& .DayPicker-Day--available:not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.main,

        color: '#fff',
      },
      '& .DayPicker-Day--available:not(.DayPicker-Day--disabled):hover:not(.DayPicker-Day--outside):hover': {
        background: theme.palette.primary.dark + ' !important',

      },
      '& .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.dark + ' !important',

        color: theme.palette.primary.contrastText,
      },
      '& .DayPicker-Day--today.DayPicker-Day--disabled': {
        background: '#ccc !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--disabled': {
        background: '#f3f3f3'
      },

    },
    dayPickerModal: {

      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',

      '& .DayPicker-Day--available:not(.DayPicker-Day--outside)': {
        background: 'rgb(130 130 130)',
        borderRadius: '10px',
        color: '#fff',
      },
      '& .DayPicker-Day--available:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover': {
        background: theme.palette.primary.main + ' !important',

      },
      '& .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.main + ' !important',
        borderRadius: '10px',
        color: theme.palette.primary.contrastText,
      },
      '& .DayPicker-Day--today.DayPicker-Day--disabled': {
        background: '#ccc !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--disabled': {
        background: 'transparent',
      },
      '& .DayPicker-Day--available.DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
        background: theme.palette.secondary.main + ' !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--selected:hover:not(.DayPicker-Day--outside):hover': {
        background: theme.palette.secondary.dark + ' !important',
      },

    },
    ChipService: {
      margin: '10px 5px',
    },
    itemService: {
      flex: 1,
    },
    itemServiceName: {
      color: theme.palette.text.disabled,
    },
    itemServiceHour: {
      fontSize: '17px',
    },
    itemServiceButton: {
      textTransform: 'none',
    },
    animationTab: {
      height: '100%',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    marginContent: {
      margin: '0px 25px',
    },
    titleAddress: {
      marginTop: '20px',
    }

  })
);


interface ISignUpFormData {
  name: string,
  email: string,
  birth: string,
  cpf: string,
  gender: string,
  mobile: string,
  idTenant: string,
  idUnidade: number,
  identificador:string,
  idServicoAgendamento: number | undefined,
  tipoServico: string | undefined,
  dataAgendamento: string| undefined,
  recaptcha: string | null,
  idUserLocalized: number | null,
  typeUserLocalized: number | null,
}
interface ICity {
  name: string;
  id: string;
}
var dataForms: ISignUpFormData;
interface SignUpLoginFormData {
  password: string;
  password_confirmation: string;

}
interface UserLocalized {
  id: number;
  type: number;
}
interface IHoursService {
  DataInicio: string;
  DataFim: string;
  Servico: string;
}
type RouteParams = {
  id: string;
}
type BookDetailProps = RouteComponentProps<RouteParams>;



const CadastroAgendamento: React.FC<BookDetailProps> = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const cpfRef = useRef<HTMLInputElement | null>(null);
  const { tenant, updateUser } = useAuth();

  const [userTemp, setUserTemp] = React.useState<IUserLogin>();


  return (
    <>
     
    
    
          <Container maxWidth="lg">
            <Paper elevation={3} className={classes.Paper} >

       
      <LandingPageCalendarioCadastro></LandingPageCalendarioCadastro>


            </Paper>
            <Grid container  direction="column"
              justify="center"
              alignItems="center" >
              <Grid item xs={12} sm={12}   >
                <Typography gutterBottom color="textPrimary" variant="h3">
                  {tenant.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}   >
                <Typography gutterBottom color="textPrimary" variant="h4">
                  {tenant.branchs.find(f => f.id == tenant.idBranch)?.phone}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}   >
                <Typography gutterBottom color="textPrimary" variant="subtitle1">
                  {tenant.branchs.find(f => f.id == tenant.idBranch)?.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}   >
                <Typography gutterBottom color="textPrimary" variant="subtitle2">
                  {tenant.branchs.find(f => f.id == tenant.idBranch)?.address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}   >
                <Typography gutterBottom color="textPrimary" variant="subtitle2">
                  {tenant.branchs.find(f => f.id == tenant.idBranch)?.city}
                </Typography>
              </Grid>
            </Grid>
          </Container>



    </>
  );

}
export default withRouter(CadastroAgendamento);
