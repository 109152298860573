import React, { useCallback } from 'react';
import clsx from 'clsx';
import { AppBar, Toolbar, IconButton, Button, Theme, makeStyles, createStyles, AppBarProps, Avatar, Hidden, Tabs, Tab, Grid, BottomNavigationAction, BottomNavigation, List, ListItem, ListItemIcon, ListItemText, Divider, SwipeableDrawer, Box } from '@material-ui/core';
import { ArrowBack, Menu, PowerSettingsNew, QueryBuilder, MonetizationOn, PersonPinCircle, ShoppingCart, FitnessCenter, Event, AccountCircle } from '@material-ui/icons';
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';


type IAppBarPros = AppBarProps & {
  linkPush?: string | undefined;
  isBack?: boolean;
  avatar: boolean;
  removeBack?: boolean;
  tabIndex?: number;
  hideSubmenu?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    right: {
      marginLeft: "auto",
    },
    divClock: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'inherit',
      '& p': {
        margin: '2px 0 0 3px',
      }
    },
    headerColor: {
      background: 'linear-gradient(to right, ' + theme.palette.primary.main + ' 0%, ' + theme.palette.primary.dark + ' 100%)',
    },
    headerColorBlank: {
      background: "#ffffff",
      color: theme.palette.text.primary,
    },
    signOut: {
      '& span': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column-reverse',
      }
    },
    spanName: {
      marginRight: '5px',
    },
    buttonAppbar: {
      color: '#909090',
      textTransform: 'inherit',
      fontWeight: 'inherit',
      margin: '0px 4px',
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logoCenter: {
      maxHeight: '47px',
      cursor: 'pointer',
    }
  })
);




const Header: React.FC<IAppBarPros> = ({ linkPush, isBack, avatar, tabIndex, removeBack, hideSubmenu, ...restProps }) => {
  const { user, tenant, signOut } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const [valueTab, setValueTab] = React.useState(tabIndex);


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, ['left']: open });
  };

  const list = () => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: false,
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {!hideSubmenu &&
        <List>
          {user &&
            <>
              <ListItem button onClick={() => handleClickMenu(0)}>
                <ListItemIcon><AccountCircle /></ListItemIcon>
                <ListItemText primary="Perfil" />
              </ListItem>
              {tenant.permissions.payments &&
                <ListItem button onClick={() => handleClickMenu(1)}>
                  <ListItemIcon><MonetizationOn /></ListItemIcon>
                  <ListItemText primary="Financeiro" />
                </ListItem>
              }
              {(tenant.permissions.newSales || tenant.permissions.renewSales) &&
                <ListItem button onClick={() => handleClickMenu(2)}>
                  <ListItemIcon><ShoppingCart /></ListItemIcon>
                  <ListItemText primary="Comprar" />
                </ListItem>
              }
              {tenant.permissions.training &&
                <ListItem button onClick={() => handleClickMenu(3)}>
                  <ListItemIcon><FitnessCenter /></ListItemIcon>
                  <ListItemText primary="Treinos" />
                </ListItem>
              }
              {tenant.permissions.appointments &&
                <ListItem button onClick={() => handleClickMenu(4)}>
                  <ListItemIcon><Event /></ListItemIcon>
                  <ListItemText primary="Agendamentos" />
                </ListItem>
              }

              {tenant.permissions.classesView &&
                <ListItem button onClick={() => handleClickMenu(5)}>
                  <ListItemIcon><PersonPinCircle /></ListItemIcon>
                  <ListItemText primary="Horários" />
                </ListItem>
              }
            </>
          }
        </List>
      }
    </div>
  );



  const handleClickReturn = useCallback(() => {
    if (isBack || !linkPush) {
      history.goBack();
    } else {
      history.push(linkPush);
    }
  }, [history, linkPush, isBack]);
  const handleLogout = useCallback(() => {
    signOut();
    history.push('/');
  }, [signOut, history]);

  const handleLoginLink = useCallback(() => {
    history.push('/');
  }, [history]);


  const handleClickAvatar = useCallback(() => {
    history.push('/');
  }, [history]);
  const handleClickMenu = useCallback((value) => {
    setValueTab(value);
    switch (value) {
      case 0:
        history.push('/');
        break;
      case 1:
        history.push('/payments/pending');
        break;
      case 2:
        history.push('/plans/list');
        break;
      case 3:
        history.push('/training');
        break;
      case 4:
        history.push('/appointments');
        break;
      case 5:
        history.push('/classes/list');
        break;
      default:
        history.push('/');
        break;
    }

  }, [history]);



  return (
    <>
      <AppBar className={tenant && tenant.appBarPrimaryColor ? classes.headerColor : classes.headerColorBlank} position="sticky" {...restProps}>

        <Toolbar >
          {/* {!removeBack &&
          <IconButton aria-label="Voltar" color="inherit" onClick={handleClickReturn}>
            <ArrowBack />
          </IconButton>
          } */}
          {!hideSubmenu && user &&
            <>
              {/* <Hidden smUp > */}
              <Box sx={{ display: {sm:'block', xs:'block', md:'none' } }} >
                <IconButton onClick={toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                  <Menu />
                </IconButton>
              </Box>
              <SwipeableDrawer
                open={state['left']}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                {list()}
              </SwipeableDrawer>
            </>
          }
          <img src={tenant?.url_Logo} alt={tenant?.name} className={classes.logoCenter} onClick={handleLoginLink} />
          {user &&
            <div className={classes.right}>

              {avatar &&
                <>
                  <Box sx={{ display: { xl: 'block', xs: 'none' } }} >
                    <span className={classes.spanName}>Olá {user.firstName}</span>
                  </Box>
                  <IconButton onClick={handleClickAvatar}>

                    <Avatar alt={user.name} src={user.avatarURL} />
                  </IconButton>
                </>
              }
              <Button className={classes.signOut} color="inherit" onClick={handleLogout}>
                Sair
                <PowerSettingsNew />
              </Button>
            </div>
          }
          {!user &&
            <div className={classes.right}>

              <Button className={classes.signOut} color="inherit" onClick={handleLoginLink}>
                Login
              </Button>
            </div>
          }
        </Toolbar>

      </AppBar>
      {!hideSubmenu &&

        //  <Hidden xsDown > 
        <Box sx={{ display: {sm:'none', xs:'none', md:'block' } }} >
          <Grid container
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item xs={12} >
              {user &&
                <BottomNavigation

                  value={valueTab}
                  onChange={(event, newValue) => {
                    handleClickMenu(newValue);
                  }}
                  showLabels

                >

                  <BottomNavigationAction label="Perfill" icon={<AccountCircle />} />
                  {tenant.permissions.payments &&
                    <BottomNavigationAction label="Financeiro" icon={<MonetizationOn />} />
                  }
                  {(tenant.permissions.newSales || tenant.permissions.renewSales) &&
                    <BottomNavigationAction label="Comprar" icon={<ShoppingCart />} />
                  }
                  {tenant.permissions.training &&
                    <BottomNavigationAction label="Treinos" icon={<FitnessCenter />} />
                  }
                  {tenant.permissions.appointments &&
                    <BottomNavigationAction label="Agendamentos" icon={<Event />} />
                  }
                  {tenant.permissions.classesView &&
                    <BottomNavigationAction label="Horários" icon={<PersonPinCircle />} />
                  }
                </BottomNavigation>
              }
            </Grid>
          </Grid>
          {/* </Hidden> */}
        </Box>
      }
    </>
  )
}
export default Header;
