import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from './styles';
import classNames from "classnames";
import Lottie from 'react-lottie';
import animationData from '../../../../assets/animations/success.json';
import animationDataError from '../../../../assets/animations/error.json';


import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography, Divider } from '@material-ui/core';
import { ArrowBackIos, Today, PowerSettingsNew, ArrowForwardIos, Help, Error as ErrorIcon } from '@material-ui/icons';
import GridContainer from '../../../../components/Grid/GridContainer'
import GridItem from '../../../../components/Grid/GridItem'
import Header from '../../../../components/Header';
import { useAuth } from '../../../../hooks/auth';
import NumberFormat from 'react-number-format';
import { FormPaymentEnum } from '../../../../models/Payments/IPaymentSaleDTO';
import { IPaymentPending } from '../../../../models/Payments/IPaymentPendingDTO';
import { blue, orange } from '@material-ui/core/colors';
import ReactHtmlParser from 'react-html-parser';
import QRCode from "react-qr-code";
import { IPaymentVoucher } from "../index";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToast } from '../../../../hooks/toast';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",

    },
    right: {
      marginLeft: "auto",
    },
    detailPayment: {
      marginTop: "10px"
    },
    avatar: {
      maxWidth: "160px",

      margin: "0 auto",
    },
    name: {
      marginTop: "-80px"
    },
    imgFluid: {
      maxWidth: "100%",
      height: "auto"
    },
    imgRaised: {
      boxShadow:
        "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgRoundedCircle: {
      borderRadius: "50% !important"
    },
    Paper: {
      padding: '20px',
      marginBottom: '26px',
    },
    cardPadding: {
      padding: '16px',
    },
    dividerMargin: {
      margin: '20px 0',
    },
    wrapper: {
      marginTop: '30px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    titleMessage: {
      fontSize: '15px',
      marginBottom: '10px',
    },
    descriptionMessage: {
      display: 'flex',
      fontSize: '18px',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '19px',
    },
    descriptionHeloMessage: {
      display: 'flex',
      fontSize: '14px',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '30px',
    }
  }),
);
interface DescricaoItem {
  descricao: string;
}
interface IPayment {
  bandeira: string;
  numero: string;
  formaPagamento: FormPaymentEnum;
  parcelas: number;
  habilitaAgendamento: boolean;
  linkBoleto: string;
  qrcodePix: string;
  chavePix: string;
}
interface IPlanItem {
  id: string;
  bloqueio: string;
  nomePlano: string;
  descricaoPlano: string;
  duracao: string;
  validadeCredito: string;
  idContrato: number;
  credito: boolean;
  idCredito: number;
  parcelas: number;
  valorTotal: number;
  adesaoObservacao: string;
  anuidadeValor: number;
  anuidadeMes: number;
  servicoExtraObservacao: string;
  adesaoDescricao: string;
  servicoExtraDescricao: string;
  creditoDescricao: string;
  produtoDescricao: string;
  creditoObservacao: string;
  produtoObservacao: string;
  adesaoValor: number;
  dcc: boolean;
  valorDcc: number;
  caracteristicas: Array<DescricaoItem>;
}

interface IResponsePayment {
  ok: boolean;
  msg: string;
  statusCode: number;
  urlAuth: string;
  prospectConvertido: boolean;
  idPessoa?: number;
  token: string;
  textoPosCompra: string;
  habilitaAgendamento: boolean;
}



const CardsSuccess: React.FC = () => {
  const classes = useStyles();
  let timerId = 0;
  const { tenant, signOut, user } = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [isStopped, setIsStopped] = React.useState(true);
  const [isSave, setIsSave] = React.useState(true);
  const { addToast } = useToast();
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
  };
  const defaultOptionsError = {
    loop: false,
    autoplay: false,
    animationData: animationDataError,
  };


  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const history = useHistory();

  useEffect(() => {
 
      setLoading(false);

      const saveCardString = sessionStorage.getItem('@HubfitPortal:saveCard');
      if (saveCardString) {
        if (saveCardString == "true") {
          setIsSave(true);
        }else{
          history.push('/');
        }
      }else{
        history.push('/');
      }
      sessionStorage.removeItem('@HubfitPortal:plan');
      sessionStorage.removeItem('@HubfitPortal:invoice');
      sessionStorage.removeItem('@HubfitPortal:payment_details');
      sessionStorage.removeItem('@HubfitPortal:action');
      sessionStorage.removeItem('@HubfitPortal:new_register');
      sessionStorage.removeItem('@HubfitPortal:habilitaAgendamento');
      sessionStorage.removeItem('@HubfitPortal:responsePayment');
      sessionStorage.removeItem('@HubfitPortal:voucher');
      sessionStorage.removeItem('@HubfitPortal:saveCard');
      
      
    setTimeout(() => {
      setIsStopped(false);
    }, 1000);

  }, [])




  return (
    <>
      <Header isBack avatar hideSubmenu={!user} />
      <div >
        <div>
          <Content>

            <Grid justify="center">
              
                  {!loading &&

                    <Grid item xs={12} sm={12} >
                      <div >
                        <div>
                          <Lottie options={defaultOptions}
                            height={115}
                            width={115}
                            speed={0.6}
                            isStopped={isStopped}
                          />

                        </div>
                        <Grid container direction="row"
                          justify="center"
                          alignItems="center">
                          <Grid className={classes.cardPadding}>
                            <Paper elevation={1} className={classes.Paper} >
                              <Grid container direction="row"
                justify="center"
                alignItems="center">
                                {!loading && 
                                 <>
                                      <Typography align='center' variant="h5" >
                                          Obrigado!
                                      </Typography>
                                     
                                          <span>Cartão salvo com sucesso</span>
                                    
                                          </>
                                }
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  }

                
            </Grid>
          </Content>
        </div>
      </div>
    </>
  )
};
export default CardsSuccess;
