import React, { useRef, useCallback, useEffect } from 'react';
import {
  Input, Button, CircularProgress, Grid, Dialog, Typography, IconButton,
  DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions, InputLabel, FormHelperText, FormControl, Avatar
} from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme, WithStyles, withStyles } from '@material-ui/core/styles';
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './style.css';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import logoImg from '../../../assets/logo.svg';

import getValidationErrors from '../../../utils/getValidationErrors';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import CardHeader from "../../../components/Card/CardHeader";
import axios from 'axios';

import { AnimationContainer, Content, CardTextHeader, FooterCard, ContentModal } from './styles';


//import Input from '../../components/Input';



import { green, blue } from '@material-ui/core/colors';

import { AccountCircle, ArrowLeft, PersonAdd, Lock, HelpOutline, Close as CloseIcon, ArrowForwardIos, Email } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import api from '../../../services/api';
import { rgb } from 'polished';
import { yupResolver } from '@hookform/resolvers';
import { User } from '../../../models/Tenant/UserDTO';
import Axios from 'axios';
import CardHeaderWhite from '../../../components/Card/CardHeaderWhite';
import SignInModal from '../SignInModal';
import { Helmet } from 'react-helmet';


interface FindCPFFormData {
  cpf: string;
}

interface SignInFormData {
  password: string;
}
interface SignUpFormData {
  password: string;
  password_confirmation: string;
  email: string;
}
interface IUserLogin {
  avatarUrl: string;
  email: string;
  nome: string;
  id: number;
  tipoPessoa: number;
  cadastroProspect: boolean;
  cadastro: number;
}
enum TipoPessoa {
  Colaborador = 0,
  Aluno = 1,
  Personal = 2,
  Prospect = 3,
  Banco = 4,
  Filial = 5,
  Empresa = 6
}
enum ChecarCadastroCPF {
  SemCadastro = 0,
  PossuiCadastro = 1,
  PossuiLogin = 2,
  PossuiFacebook = 3
}
interface IFormCreateLogin {
  id: number;
  email: string;
  senha: string;
  tipoPessoa: number;
  idTenant: string;
}
var dataFormsCpf: FindCPFFormData;
var dataFormsCreateLogin: IFormCreateLogin;
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles((theme: Theme) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: '60px 0 0',
    width: '100%',
    position: 'relative',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  SpanCard: {
    color: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',

  },
  IconInfo: {
    color: blue[500],
  },
  RingOff: {
    'outline-style': 'inherit',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleSignup: {
    margin: '10px 0',
  },
  rootAvatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  descUnidade: {
    color: theme.palette.text.hint,
  }


}));


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

export enum SegundoPasso {
  cadastroSenha,
  loginSenha,
}


const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

type RouteParams = {
  id?: string
}
type BookDetailProps = RouteComponentProps<RouteParams>;

const SignIn: React.FC<BookDetailProps> = ({ match }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { signIn, tenant, updateUser, updateTenant } = useAuth();
  const cpfRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [userTemp, setUserTemp] = React.useState<IUserLogin>();
  const [segundoPasso, setSegundoPasso] = React.useState<SegundoPasso>(SegundoPasso.loginSenha);
  const [cpfLogin, setCpfLogin] = React.useState("");

  const { addToast } = useToast();
  const history = useHistory();

  const cpfSignupRef = useRef<HTMLInputElement | null>(null);
  const emailSignupRef = useRef<HTMLInputElement | null>(null);
  const [unidade, setUnidade] = React.useState<any>();

  useEffect(() => {
    const idUnidade = match.params.id;



    if (idUnidade) {
      api.get('/tenant/branch', {
        params: {
          idBranch: idUnidade,
          idTenant: tenant?.idTenant,
        }
      }).then(data => {
        console.log(data.data);
        if (data.data) {
          if (tenant.idBranch != Number(idUnidade)) {
            tenant.idBranch = Number(idUnidade);
            tenant.name = data.data.nome;
            updateTenant(tenant);
          }
        }

        setUnidade(data.data);
      })
    }
    setTimeout(() => {
      cpfRef.current?.focus();
    }, 1000)
  }, [tenant, updateTenant])


  const handleClickReturn = useCallback(() => {
    if (tab > 0) {
      setTab(tab - 1);
    }
  }, [tab]);


  const handleCloseModal = () => {
    setOpenModal(false);
  };
  let RegisterSignInPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Digite a senha'),
  })
  let RegisterSignUpPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Senha obrigatória'),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref('password'), undefined], 'Senha diferente a primeira',
    )
  })
  let RegisterSignInCPFSchema = Yup.object().shape({
    cpf: Yup.string().required('Digite o CPF'),
  });

  const formCpf = useForm<FindCPFFormData>({
    resolver: yupResolver(RegisterSignInCPFSchema)
  });
  const formPassword = useForm<SignInFormData>({
    resolver: yupResolver(RegisterSignInPasswordSchema)
  });
  const formPasswordSignUp = useForm<SignUpFormData>({
    resolver: yupResolver(RegisterSignUpPasswordSchema)
  });
  const formCpfSignUp = useForm<FindCPFFormData>({
    resolver: yupResolver(RegisterSignInCPFSchema)
  });

  const handleSignUpClick = useCallback(() => {
    setOpenModal(true);
    setTimeout(() => {
      cpfSignupRef.current?.focus();
    }, 800)
  }, [])

  const onSubmitSignupPassword = useCallback((data: SignUpFormData) => {
    try {

      setLoading(true);
      if (userTemp) {
        const formCreate: IFormCreateLogin = {
          senha: data.password,
          idTenant: tenant.idTenant,
          email: data.email,
          tipoPessoa: userTemp.tipoPessoa,
          id: userTemp.id,
        }



        api.post('/login', formCreate).then((response) => {
          const usuario = response.data;

          const userStorage: User = {
            avatarURL: usuario.avatar_url,
            name: usuario.name,
            email: usuario.email,
            id: usuario.id,
            identifier: usuario.identifier,
            idTenant: usuario.idTenant,
            firstName: usuario.firstName,
            idBranch: usuario.idBranch,
            type: usuario.type,
          }

          updateUser(userStorage, usuario.token);
          history.push('/');
          setLoading(false);

        }).catch((error) => {
          setLoading(false);
          console.log(error.response.data)
          addToast({
            type: "error",
            title: 'Ops...',
            description: error.response.data,
          });
        });

      }
    } catch (err) {
      console.log(err)
    } finally {

    }
  }, [userTemp, tenant]);

  const handleSubmitFindCPF = useCallback(async (dataForm: FindCPFFormData) => {
    try {
      setLoading(true);
      //Buscar por CPF na API
      const { data } = await api.get("/users/cpf", {
        params: {
          cpf: dataForm.cpf,
          idTenant: tenant.idTenant,
        }
      });
      console.log(data)
      if (data.cadastro == 2) {
        setUserTemp(data);
        setSegundoPasso(SegundoPasso.loginSenha);
        setTab(1);
        setTimeout(() => {
          passwordRef.current?.focus();
        }, 800)
      } else if (data.cadastro == 1) {
        setUserTemp(data);
        setSegundoPasso(SegundoPasso.cadastroSenha);
        if (emailSignupRef.current) {
          emailSignupRef.current.value = data.email;
        }
        setTab(1);
        setTimeout(() => {
          passwordRef.current?.focus();
        }, 800)
      } else if (data.cadastro == 0) {
        addToast({
          type: "info",
          title: 'Novo cadastro',
          description: 'Não encontramos nenhum cadastro com seu CPF. Insira seus dados para criar um novo cadastro ou fale com a recepção.',
          time: 10000
        });
        history.push({
          pathname: '/signup',
          state: {
            cpf: dataForm.cpf,
          }
        })
      }
    } catch (err) {
      console.log(err)

      return;


    } finally {
      setLoading(false);
    }
  }, [segundoPasso])

  const handleSubmitSigIn = useCallback(async (data: SignInFormData) => {
    try {
      console.log(data)
      setLoading(true);

      if (!userTemp) {
        throw new Error("Cadastro não encontrado")
      }
      await signIn({
        email: userTemp.email,
        password: data.password,
      });
      const planString = sessionStorage.getItem('@HubfitPortal:plan');
      if (planString) {
        history.push('/payments/checkout')
      } else {
        history.push('/');
      }


    } catch (err) {
      console.log(err)
      setLoading(false);

      addToast({
        type: "error",
        title: 'Erro na autenticação',
        description: 'Erro no login, verifique suas credenciais.',
      });

    } finally {
      setLoading(false);
    }
  }, [history, signIn, addToast, userTemp])


  const handleSubmitFindCPFSignUp = useCallback(async (dataForm: FindCPFFormData) => {
    try {
      setLoading(true);
      //Buscar por CPF na API
      const { data } = await api.get("/users/cpf", {
        params: {
          cpf: dataForm.cpf,
          idTenant: tenant.idTenant,
        }
      });
      switch (data.cadastro) {
        case 0:
          //Sem cadastro, direcionar para tela de cadastro.
          history.push({
            pathname: '/signup',
            state: {
              cpf: dataForm.cpf,
            }
          })
          break;
        case 1:
          //Possui cadastro, pedir para fazer login.
          addToast({
            type: "info",
            title: 'Cadastro encontrado',
            description: 'Você já possui uma conta criada, digite seu CPF e sua senha na tela de login',
            time: 20000,
          });
          break;
        case 2:
          //Possui cadastro, pedir para fazer login.
          addToast({
            type: "info",
            title: 'Faça login',
            description: 'Você já possui uma conta criada, digite sua senha para fazer login',
            time: 20000,
          });
          setUserTemp(data);
          setSegundoPasso(SegundoPasso.loginSenha);
          setOpenModal(false);
          setCpfLogin(dataForm.cpf);
          setTimeout(() => {
            passwordRef.current?.focus();
          }, 800)
          setTab(1);
          break;

      }
      console.log(data);

    } catch (err) {
      console.log(err)
      return;
    } finally {
      setLoading(false);
    }
  }, [])


  return (
    <Content>
      <Helmet>
        <title>Portal do aluno - {tenant?.name}</title>
      </Helmet>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={5}>
          <AnimationContainer>
            <Card>
              <SignInModal />
            </Card>

          </AnimationContainer>

        </GridItem>
      </GridContainer>

      {unidade &&
        <Grid container direction="column"
          justify="center"
          alignItems="center" style={{ marginTop: 30 }} >
          <Grid item xs={12} sm={12}   >
            <Typography gutterBottom color="textPrimary" variant="h4">
              {unidade.nome}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}   >
            <Typography gutterBottom className={classes.descUnidade} variant="subtitle2">
              Razão social: {unidade.razao_social}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}   >
            <Typography gutterBottom className={classes.descUnidade} variant="subtitle2">
              CNPJ: {unidade.cnpj}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}   >
            <Typography gutterBottom className={classes.descUnidade} variant="subtitle2">
              Telefone: {unidade.telefone}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}   >
            <Typography gutterBottom className={classes.descUnidade} variant="subtitle2">
              E-mail: {unidade.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}   >
            <Typography gutterBottom className={classes.descUnidade} variant="subtitle2">
              Endereço: {unidade.endereco}, {unidade.numero}, {unidade.bairro}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}   >
            <Typography gutterBottom className={classes.descUnidade} variant="subtitle2">
              {unidade.cidade_uf}
            </Typography>
          </Grid>

        </Grid>
      }

    </Content>

  )
};
export default withRouter(SignIn);
