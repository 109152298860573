
export interface IFormCustom {
    Id: number;
    TextoInicial: string;
    TextoFinal: string;
    Identificador: string;
    Teste: boolean;
    Titulo: string;
    Perguntas:  IFormQuestions[];
}
export interface IFormQuestions {
    Id: number;
    IdPerguntaModelo: number;
    Pergunta: string;
    Obrigatoria: boolean;
    Resposta: string;
    Tipo: AnamnesePerguntaTipo;
    Opcoes: IFormOptions[];
}
export interface IFormOptions {
    Id: number;
    IdOpcaoModelo: number;
    Descricao: string;
    Selecionado: boolean;
}
export enum AnamnesePerguntaTipo{
    Texto,
    UnicaEscolha,
    MultiplaEscolha,
}