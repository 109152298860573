import styled from 'styled-components';

export const ContentDiv = styled.div`
     margin-right: auto;
  margin-left: auto;
  position: relative;
    padding-top: 2vh;
    padding-bottom: 55px;

`;
