import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Content } from './styles';
import classNames from "classnames";
import Lottie from 'react-lottie';
import animationData from '../../../../assets/animations/success.json';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography, Divider, CircularProgress } from '@material-ui/core';
import { ArrowBackIos, Today, PowerSettingsNew, ArrowForwardIos } from '@material-ui/icons';
import GridContainer from '../../../../components/Grid/GridContainer'
import GridItem from '../../../../components/Grid/GridItem'
import Header from '../../../../components/Header';
import { useAuth } from '../../../../hooks/auth';
import NumberFormat from 'react-number-format';
import { FormPaymentEnum } from '../../../../models/Payments/IPaymentSaleDTO';
import { IPaymentPending } from '../../../../models/Payments/IPaymentPendingDTO';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",

    },
    textCountdown: {
      color: theme.palette.text.secondary,
      fontSize: '23px',
    },
    GridList: {
      margin: '20px 0',
    }
  }),
);
interface DescricaoItem {
  descricao: string;
}
interface IPayment {
  bandeira: string;
  numero: string;
  formaPagamento: FormPaymentEnum;
  parcelas: number;
  habilitaAgendamento: boolean;
}
interface IPlanItem {
  id: string;
  bloqueio: string;
  nomePlano: string;
  descricaoPlano: string;
  duracao: string;
  validadeCredito: string;
  idContrato: number;
  credito: boolean;
  idCredito: number;
  parcelas: number;
  valorTotal: number;
  adesaoObservacao: string;
  anuidadeValor: number;
  anuidadeMes: number;
  servicoExtraObservacao: string;
  adesaoDescricao: string;
  servicoExtraDescricao: string;
  creditoDescricao: string;
  produtoDescricao: string;
  creditoObservacao: string;
  produtoObservacao: string;
  adesaoValor: number;
  dcc: boolean;
  valorDcc: number;
  caracteristicas: Array<DescricaoItem>;
}

const CheckoutRedirect: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const urlAuth = sessionStorage.getItem('@HubfitPortal:urlAuth');
    if (urlAuth) {

      const obj = document.getElementById("countdown");
      var timeleft = 6;
      if (obj) {
        console.log(timeleft)
        obj.innerHTML = timeleft + "";
      }
      var downloadTimer = setInterval(function () {
        timeleft -= 1;
        if (timeleft <= 0) {
          clearInterval(downloadTimer);

          const obj = document.getElementById("countdown");
          if (obj) {
            console.log("0")
            obj.innerHTML = "";
          }
          setLoading(true);
          sessionStorage.removeItem('@HubfitPortal:urlAuth');
          window.location.href = urlAuth;
        } else {
          const obj = document.getElementById("countdown");
          if (obj) {
            console.log(timeleft)
            obj.innerHTML = timeleft + "";
          }
        }

      }, 1000);
    }


  }, [])

  return (
    <>
      <Header isBack avatar={false} hideSubmenu />
      <div className={classes.mainRaised}>
        <div>
          <Content>
            <Grid justify="center">
              <Typography variant="h4">Redirecionando ao seu banco para autenticação</Typography>
              <div className={classes.textCountdown} id="countdown"></div>
            </Grid>
            {loading &&
              <Grid container direction="column" className={classes.GridList}
                justify="center"
                alignItems="center" >
                <Grid item xs={12} >
                  <CircularProgress size={40} />
                </Grid>
              </Grid>
            }
          </Content>
        </div>
      </div>
    </>
  )
};
export default CheckoutRedirect;
