import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
    * {
        margin:0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    body{
      color:'#585858';
      background: #f7f7f7;
        --webkit-font-smoothing: antialiased;

    }

    body, input, button{
        font-family: 'Roboto', sans-serif;
    }
    h1,h2, h3,h4,h5,h6, strong{
        font-weight:500;
    }
    button{
        cursor: pointer;
    }
  #titlePages{
    margin-top: 6px;
    margin-bottom: 2px;
  }
  #titleBreadcrums{
    margin-bottom: 17px;
  }
  .content{
    position: relative;
    padding-top: 2vh;
    padding-bottom: 200px;
  }
`;
