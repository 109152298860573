export interface IPaymentSaleDTO{
  idPacote: string;
  formaPagamento: FormPaymentEnum;
  parcelas: number;
  cartao?: CreditCardDTO;
  idTenant: string;
  recaptcha: string | null,
  codeVoucher: string | null,
  idConvenio?: number| null,
  idVoucher?: number| null,
  email?: string,
}
export interface CreditCardDTO{
  numero: string;
  nomeCompleto: string;
  mes: number;
  ano: number;
  cvc: string;
  bandeira: string;
}
export interface ICardSaveDTO{
  cartao?: CreditCardDTO;
  idTenant: string;
  recaptcha: string | null,
  idUnidade: number;
  token: string;
  id: number;
  tipoPessoa: number;
}
export interface ITokenCardItem {
  id: number;
  tipoPessoa: number;
  idUnidade: number;
  nome: string;
  urlImage: string;
}
export enum FormPaymentEnum {
  CartaoCredito = 2,
  CartaoDebito = 3,
  Boleto = 7,
  Pix = 11
}
