import styled, {keyframes} from 'styled-components';
import { shade } from 'polished';




export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;

`;
export const Schedule = styled.div`
  flex: 1;
  margin-right: 120px;

  h1{
    font-size: 36px;
  }
  p{
    margin-top: 8px;
    color: #ff9000;
    display: flex;
    align-items: center;
    font-weight: 500;
    span{
      display: flex;
      align-items: center;
    }
    span + span::before{
      content: '';
      width: 1px;
      height: 12px;
      background: #ff9000;
      margin: 0 8px;
    }
  }
`;
export const ContentModal = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 32px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 800px;

display: flex;
`;
export const NextAppointment = styled.div`
  margin-top: 64px;
  strong{
    color: #565656;
    font-size: 20px;
    font-weight: 400;
  }
  div{
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-radius: 10px;
    margin-top: 24px;
    position: relative;

    &::before{
      position: absolute;
      height: 80%;
      width: 1px;
      left: 0;
      top : 10%;
      content: '';
      background: #ff9000;
    }
    img{
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
    strong{
      margin-left:24px;
      color: #fff;
    }
    span{
      margin-left: auto;
      display: flex;
      align-items: center;
      color: #565656;
      svg{
        color: #ff9000;
        margin-right: 8px;
      }
    }
  }

`;
export const Section = styled.section`
  margin-top: 8px;

  > strong{
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
    margin-top: 17px;
  }
  >p{
    color:#565656;
  }
`;
export const Appointment = styled.div`
    display: flex;
    align-items: center;

    & + div{
      margin-top: 16px;
    }

    span{
      margin-left: auto;
      display: flex;
      align-items: center;

      width: 90px;
      svg{

        margin-right: 8px;
      }
    }
    div{
      flex: 1;

      display: flex;
      align-items: center;
      padding: 16px 24px;
      border-radius: 10px;
      margin-left: 24px;
      img{
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }
    strong{
      margin-left:24px;
      color: #fff;
      font-size: 20px;
    }
    }
`;
 export const Calendar = styled.div`




    .DayPicker-wrapper {
      padding-bottom: 0;
    }

    .DayPicker,
    .DayPicker-Month {
      /* width: 100%; */
    }

    .DayPicker-Month {
      border-collapse: separate;
      border-spacing: 8px;
      margin: 16px 5px;
    }

    .DayPicker-Day {
      height: 35px;
      padding: 0.7em;
      font-size: 0.85rem;
      cursor: pointer;
    }



    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background: ${shade(0.2, '#3e3b47')};
    }
    
    .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
      background: ${shade(0.1, '#3e3b47')} !important; 
    }


    .DayPicker-Day--today {
      font-weight: normal;
    }

    .DayPicker-Day--disabled {
      color: #666360 !important;
      background: #e9e8e8 ;
      cursor:not-allowed;
    }
    .DayPicker-Day {
        cursor: 'inherit'
      }


 `;
//  export const CalendarModal = styled.div`


// display: flex;
//     align-items: center;
//     flex-direction: column-reverse;

//  .DayPicker-wrapper {
//    padding-bottom: 0;
//  }

//  .DayPicker,
//  .DayPicker-Month {
//    /* width: 100%; */
//  }

//  .DayPicker-Month {
//    border-collapse: separate;
//    border-spacing: 8px;
//    margin: 16px 5px;
//  }

//  .DayPicker-Day {
//    height: 36px;
//    padding: 0.7em;
//  }



//  .DayPicker:not(.DayPicker--interactionDisabled)
//    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
//    background: ${shade(0.2, '#3e3b47')};
//    cursor:not-allowed;
//  }

//  .DayPicker-Day--today {
//    font-weight: normal;
//  }

//  .DayPicker-Day--disabled {
//    color: #666360 !important;
//    background: #e9e8e8 ;
//    cursor:not-allowed;
//  }


// `;
